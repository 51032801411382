

.SearchBar .MuiFormControl-root label {
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.alertsFilterContainerBox .alertslabel {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;

}

.alertsFilterContainerBox .searchComponent {
    margin-right: 30px;
}

.MuiTextField-root.input-box-search-bar {
    margin: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
}

.searchbarInputs .alertsSearchbarInputMarginReset {
    margin: 0px;
}