.inventory-am-table {
  margin-left: 20px;
  margin-right: 20px;
}

.inventoryContailer {
  margin-left: 18px;
  padding-right: 25px;
}

.inventoryContailer_Tabs {
  margin-left: 20px;
  padding-right: 25px;
}

.deployed-inputRow {
display: flex;
}

.deployed-pagination-list {
  margin-left: 5px;
  align-items: center;
  width: auto;
}

.deployed-inputRow>div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}

.parPageContainerOfDeployed {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
}

.inventory-tableRow {
  border-bottom: 1.1px solid #676984 ;
  font-weight: 700;
  font-size: 12px;
}

.fleetDataTableSite {
  cursor: pointer;
  padding-left: 20px;
}

.fleetDataTableType {
  padding-left: 30px;
}

.fleetDataTableRow {
  padding-left: 45px;
}

.elementData {
  padding: 5px;
}

.limitContainer {
  margin-left: 10px;
}

.cursorPointer {
  position: absolute;
  right: 12px;
}

.inventoryPagination-list{
width: 100%;
align-items: center;
margin-left: 5px;
}

.inventorypaperComponent {
  font-size: 12px;
}

.inventoryFleetRowRank .deployed-fleetRowDataDetails {
    font-size: 12.5px;
    font-weight: 500;
    border-bottom: 0px;
}

.inventoryFleetRowRank .deployedRowType {
  color: #6e6f71 ;
  font-weight: 500 ;
}

.cellWidthForColmns {
  width: 33%;
}
.inventoryDeployedAction {
  cursor: pointer;
  color: #2860B4;
  padding-right: 4px;
  width: 25px;
  height: 32px;
}
.Deployed-FleetDashboard .inventory-group-column-hide{
  display: none;
}
.tableHead .group{
  cursor: pointer;
}

.inventory-am-table .deployedContainer {
  margin: 0px 0px 10px 0px;
}

.deployedContainer .Deployed-FleetDashboard {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.inventoryFleetRowRank .deployed-config {
  cursor: pointer;
}

.inventoryFleetRowRank .deployed-config>span {
  color: #0000ee;
}
.deployed-fleetRowDataDetails.deployedRowType.inventory-kass-exp-date.grey{
  color: red;
}
.deployed-fleetRowDataDetails .group-change-icon,
.deployed-fleetRowDataDetails .upload-config-icon,
.deployed-fleetRowDataDetails .backup-config-now-icon {
  margin-left: 8px;
  width: 25px;
}

.change-group-actions button {
  background-color: #264c86;
  color: #fff;
  border: 1px solid #c3d6e7;
  font-size: 11px;
  width: 145px;
  padding: 7px 15px;
  border-radius: 5px;
  height: 36px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.change-group-actions button:hover {
  background-color: #264c86;
}

.mainAssignActiontitle .change-group-title {
  margin-left: 0px;
}

.assignDialog .change-group-container {
  width: 436px;
}

.mainAssignActiontitle .change-group-cancel-icon {
  margin-left: auto;
}

.changeDp-content .changeDp-content-div {
  display: block;
  align-items: center;
}

.changeDp-content-div .change-group-dropdown {
  margin-top: 5px;
  margin-left: 15px;
  width: 330px;
}

.selectedDeviceDetails .current-group-name {
  font-weight: 500;
  color: #5d647c;
  font-size: 14px;
}

.changeDp-content-div .change-group {
  margin-left: 15px;
  padding-top: 6px;
}
.inventoryDeployedAction.disable,
.inventoryDeployedAction.group-change-icon.disable,
.inventoryDeployedAction.upload-config-icon.disable,
.inventoryDeployedAction.delete-site-icon.disable,
.inventoryDeployedAction.backup-config-now-icon.disable {
  cursor: not-allowed;
  opacity:0.5;
}
.parPageContainerOfDeployed .inventory-deployed-download {
  margin-left: 20px;
}
.deployed-fleetRowDataDetails.display-flex{
  display: flex;
}
.inventory-deployed-tab-action {
  justify-content: center;
}
.inventoryDeployedAction.upload-config-icon svg,
.inventoryDeployedAction.delete-site-icon svg,
.inventoryDeployedAction.backup-config-now-icon svg {
  color: #1B51A7;
  width: 25px;
  height: 32px;
}
.inventoryDeployedAction.upload-config-icon svg path,
.inventoryDeployedAction.delete-site-icon svg path,
.inventoryDeployedAction.backup-config-now-icon svg path {
  stroke: #ffffff;
  stroke-width: 0.6;
}
.inventoryDeployedAction.backup-config-now-icon svg{
 color:#1B51A7 ;
}

.deployed-container {
  margin-bottom: 10px;
}

.deployed-left-align .deployed-history-dp-name {
  color: #8B8D97;
  letter-spacing: 1px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  padding-left: 10px;
  cursor: pointer;
}

.iconNowrap {
  display: flex;
}

.align-self-center {
  align-self: center;
}

.deployed-container .usage-tableBody .deployed-action {
  padding-left:10px;
  height: 25px;
  text-align: center;
  /* display: flex; */
}

.new-deployed-usage-tr .inter-font {
  font-family: 'Inter';
  font-weight: 500;
  padding: 5px 5px;
  font-size: 12px;
}

.MuiTextField-root.input-box-deployed {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}

.inventory-deployed-container {
  height: calc(100vh - 170px);
  overflow-y: scroll;
}
.inventory-site-name-with-action-inprogress-icon, .inventory-site-name-with-action-inprogress-icon .warningIcon{
  display: flex;
  align-items: center;
}
.inventory-site-name-with-action-inprogress-icon .warningIcon img{
  height: 20px;
  width: 20px;
}
.inventory-ellipsis {
  max-width: 15ch;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}


.font-size-12px {
  font-size: 12px;
}

.inventorySerachAndSwitch {
  display: flex;
}

.inventoryCheckBox {
  display: flex;
  margin-top: 20px;
}

.device-history-popup .device-history-content {
  margin-top: 20px;
}

.device-history-content .device-history-table {
  border: 1px solid #EBEBEB;
}

.login-tableRow .device-history-th {
  text-align: left;
}

.login-tableRow .dh-width-350px {
  width: 300px;
}

.login-tableRow .dh-fullname {
  width: 105px;
}

.deployed-table-headers .CF-cell {
  text-align: left;
}

/* Edit Actions Dialogue */

.inventory-edit-icon {
  color: #4873b8;
  cursor: pointer;
}

.config-deploy-inventory-popup .edit-actions-title {
  border-bottom: 1px solid #efefef;
  background: white;
  padding: 18px 10px 18px 15px;
}

.config-deploy-inventory-popup .edit-actions-content {
  padding: 0px;
}

.inventory-edit-actions-container .MuiDialog-container {
  width: 900px;
  margin-left: -100px;
}

.config-deploy-inventory-popup .edit-change-group-btn {
  position: absolute;
  bottom: 20px;
  right: 25px;
  border: 1px solid #264c86;
  background: #264c86;
  color: #fff;
  height: 35px;
  border-radius: 4px;
  width: 125px;
}

.config-deploy-inventory-popup .edit-change-group-btn:hover {
  background: #264c86
}

.inventory-edit-actions-container .edit-action-changeDp-content-div {
  margin-top: 0px;
  display: block;
  margin-left: -10px;
}

.menuScroll .edit-actions-data-body {
  margin-top: 5px;
  margin-left: 5px;
}

.menuScroll .downloadConfig-actions, .Form .applyConfig-actions {
  padding: 0px;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.downloadConfig-actions>button, .applyConfig-actions>button {
  letter-spacing: 0px;
}

.deploy-inventory-content .config-apply-config-target-width .user-entered-config-name {
  width: 210px;
}

.edit-actions-content .actions-device-data-body {
  max-height: 380px;
}

.menuScroll .edit-actions-overflow-hidden {
  overflow-x: hidden;
}

.edit-actions-confirm-btns {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  width: 63%;
  right: 20px;
}

.sub-tab-parent .edit-actions-subTitle {
  margin-top: 20px;
  color: rgba(0, 0, 0, 1);
  margin-right: 15px;
}

.EOS-version-div .EOS-version {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #676984;
}

.upgrade-license-btn-div button {
  height: 35px;
}

#actionType-disabled {
  cursor: auto;
}

#actionType-enabled .font-status-pop-up {
  color: rgba(69, 70, 78, 1);
  cursor: pointer;
}

#actionType-disabled .font-status-pop-up {
  color: #c4c4c4;
  cursor: auto;
}

.edit-actions-content .deployed-device-list {
  height: 380px;
  overflow-y: auto;
  margin-top: 5px;
}

.edit-actions-content .inventory-deployed-device-list {
  overflow-y: auto;
  margin-top: 5px;
}

.status-lists .selectedAction {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  color: #0E0548;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.status-lists .NotselectedAction {
  color: rgba(69, 70, 78, 1);
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.edit-actions-data-body .edit-actions-confirm-btns {
  display: block;
}

.edit-actions-confirm-btns .confirm-dialogue-new-divider {
  margin-bottom: 10px;
}

.edit-actions-confirm-btns .confirm-dialogue-new-btns {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed {
  box-shadow: 0px 0px 13px 0px #CBCBCB59;
  background-color: #1B51A7;
  color: #ffffff;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed:hover {
  background-color: #1B51A7;
}

.confirm-dialogue-new-btns .confirm-dialogue-cancel {
  color: #0E0548;
  height: 30px;
  width: 100px;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed-notAllow {
  background-color: rgb(232, 240, 253);
  color: rgb(38, 76, 134);
  border: 1px solid #676984;
  cursor: auto;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed,
.confirm-dialogue-new-btns .confirm-dialogue-cancel,
.confirm-dialogue-new-btns .confirm-dialogue-proceed-notAllow {
  height: 30px;
  width: 100px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: none;
}

.confirm-dialogue-new-btns .confirm-dialogue-proceed-notAllow:hover {
  background-color: rgb(232, 240, 253);
}

#content-left-text {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  width: 190px;
}

#managed-config-content-left-text span {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  width: 190px;
}

#content-right-text {
  color: rgba(43, 35, 94, 1);
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
}

.deploy-inventory-content .new-selected-config-details {
  line-height: 10px;
  margin-left: 190px;
  width: 400px;
}

.change-org-dp-dropdown .DPvesselDropdown .MuiButtonBase-root {
  width: 250px;
}

.EOS-version-div .org-change-remove-margintop, .selected-config-details .register-remove-margin-top {
  margin-top: 0px;
}

.EOS-version-div .org-change-10px-margintop, .apply-inventory-content .config-apply-config-target {
  margin-top: 10px;
}

.edit-actions-title .selected-site-device {
  margin-left: 182px;
}

.edit-actions-title .selected-site-device.starlink-admin-operations-popup-service-line-name {
  margin-left: 184px;
}

.edit_confirm_dialog_sub_title_text {
  font-size: 14px;
  white-space: pre-line;
}

.status-lists.manage-config {
  display: block;
}

.manage-config-options {
  padding-left: 10px;
}

.manage-hub-ip-config-options {
  padding-left: 20px;
}

.manage-config-options .selected-manage-config-type,
.selected-manage-config-hub-ip-type {
  font-weight: 500;
  color: rgba(69, 70, 78, 1);
}

.manage-config-options div,
.manage-hub-ip-config-options div {
  padding-bottom: 10px;
  cursor: pointer;
  color: rgba(69, 70, 78, 1)
}

.status-lists.manage-config .font-status-pop-up,
.manage-config-hub-ip-option {
  display: flex;
  align-items: center;
}

.status-lists.manage-config .font-status-pop-up > svg,
.manage-config-hub-ip-option > svg {
  margin-left: 5px;
}

.edit-actions-content .Mui-selected.status-lists,
.edit-actions-content .Mui-selected.status-lists:hover,
.edit-actions-content .Mui-selected.status-lists:focus {
  background-color: #F4F5FA;
}

.change-license-info-div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.change-license-info-div .helpIcon-reports, #helpIcon-reports {
  color: #2860b4;
}

.change-license-info-div .help-icon-actions {
  width: 20px;
  position: relative;
  top: 2px;
}

.deploy-inventory-content .change-license-info-msg {
  font-family: 'Roboto';
  font-style: normal;
  line-height: 20px;
  font-size: 13px;
  margin: 30px 20px 20px 0px;
}

.new-deployed-usage-tr .konnectStatus-site-cell {
  display: flex;
  align-items: center;
  gap: 3px;
}

.source-device-serving-hub-info {
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 37px;
}
.source-device-serving-hub-info .serving_hub-name{
  font-weight: 500;
}

.edit-actions-data-body .width-76-percent {
  width: 76%;
}

.usageReports-tableHead .login-tableRow .history-device-change {
  width: 28%;
}

.login-tableRow .align-left {
  text-align: left;
}

.loginHistory-tableRow .short-text-change {
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loginHistory-tableRow .short-text-username {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .status,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .status {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .status .text,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .status .text{
  max-width: 100px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .status .text,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .status .text,
.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .noData {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  background: #FFFFFF;
  text-transform: none;
}

.config-deploy-inventory-popup.menuScroll.device-eos-history-popup .noData {
  text-align: center;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content {
  margin-top: 0px;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tab-content {
  padding: 15px 0 0 0;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #8b8d97;
  border-bottom: 2px solid transparent;
}
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button span {
  width: 100%;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button,
.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader button:hover {
  padding: 12px 0 10px;
  margin-right: 15px;
  text-transform: None;
  cursor: pointer;
  border-radius: 0;
  /* min-width: unset; */
  min-height: 42px;
  height: 42px;
  background-color: transparent;
  outline: none;
  transition: none;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .tabsHeader .Mui-selected {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #2f4b82 !important;
  border-bottom: 2px solid #2f4b82;
}

.config-deploy-inventory-popup.menuScroll.device-history-popup .warehouse-device-history-content .noDataAvailableCss {
  font-family: "Inter";
  text-align: center;
}