.starlinkpagination-root .starlinkpagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding: 0px;
    font-size: 14px;
    font-family: 'Inter';
    margin-top: auto;
}

.starlinkpagination-toolbar .records-select {
    font-size: 14px;
    font-family: 'Inter';
    padding: 0px 8px 0px 7px;
    margin-right: 0px;
    color: #6E7079;
}

.starlinkpagination-toolbar .page-dropdown {
    background: #f5f5f5;
    max-width: 80px;
    height: 23px;
    color: #6E7079;
    padding: 0px;
    border-radius: 8px;
}

.starlinkpagination-toolbar .row-dropdown {
    background: #f5f5f5;
    width: 72px;
    height: 23px;
    color: #6E7079;
    padding: 0px;
    border-radius: 8px;
    cursor: pointer;
}


.starlinkpagination-toolbar .MuiBox-root .page-arrow {
    background-color: #F5F5F5;
    border-radius: 8px;
    width: 19px;
    height: 24px;
    margin-right: 5px;
    color: #6E7079;
}

.starlinkpagination-toolbar .starlinkpagination-actions {
    flex-shrink: 0;
    margin-left: 20;
}

.starlinkpagination-spacer {
    flex: 1 1 100%;
}

.starlinkpagination-displayPages {
    flex-shrink: 0;
    color: #6E7079;
}

.starlinkpagination-selectLabel {
    flex-shrink: 0;
    color: #8B8D97;
}
