.inventory-group-device-list-popup .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #8B8D97;
    background-color: #f5f5f5;
    height: 15px;
    border: 1px solid #EBEBEB;
}

.inventory-group-device-list-popup .dialog-title .close{
    width: 25px;
    cursor: pointer;
}

.inventory-group-device-list-popup .MuiDialogContent-root.dialog-content {
    height: max-content;
    padding: 20px 24px;
    max-width: 550px;
    min-width: 250px;
}

.inventory-group-device-list-popup .devices div {
    padding: 4px;
    font-size: 10px;
    background: #fff;
    border: 1px solid #bdbdbd;
    margin: 1px;
}