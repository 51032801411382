.changePasswordPage .changePasswordTitle {
    margin-left: 24px;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
}


.changePasswordPage .changePasswordContainer {
    display: block;
    margin: auto;
    padding: 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 12px;   
    margin-top: 30px; 
}

.changePasswordContainer .passWordFiled {
    width: 100%;
    margin-bottom: 20px;
}

.passWordFiled .MuiTextField-root {
    width: 100%;
}

.passWordFiled .fieldLabel {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #676984;
}

.changePasswordContainer .changePassButtons {
    /* margin-right: 10px; */
    width: 160px;
    font-size: 11px;
    text-transform: uppercase;
    background-color: #264C86 !important;
    border: 1.5px solid #264C86;
    color: #ffffff !important;
    cursor: pointer;
}

.onChangeCancel{
    width: 160px;
    margin-right: 10px !important;
    background: #FFFFFF !important;
    border: 1px solid #676984 !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 12px !important;
    text-align: center !important;
    letter-spacing: 0.035em !important;
    text-transform: uppercase !important;
    color: #676984 !important;
}

.invalidDetails {
    color: Red;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 400;
}

.passChangeSuccess {
    color: green;
    font-size: 12px;
    font-weight: 400;
}

.contactField {
    width: 100%;
    margin-bottom: 15px;
}

.contactField .MuiInputBase-adornedStart {
    border: none !important;
    border-radius: 8px !important;
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
}

.contactField .MuiInputBase-adornedStart input {
    border-left: 1px solid rgba(20, 99, 172, 0.24) !important;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
}
