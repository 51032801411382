.rc--sd_tab_list {
    overflow: hidden;
}

.rc--connect_icon {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
}

.rc--sd_tab--tooltip {
    display: flex;
    flex-direction: column;
}

.rc--sd_tab--tooltip .uppercase {
    text-transform: uppercase;
}

.link-status {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    padding-top: 15px;
    padding-left: 10px;
}

.rem-conn-all {
    font-weight: 500;
    font-size: 14px;
    line-height: 0.95em;
    color: #45464E;
    height: 25px;
}