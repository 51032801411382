.starlink--reports--usage_chart--container .highcharts-container, .starlink--reports--usage_chart--container .highcharts-root {
    width: 100% !important;
}

.MuiTextField-root.input-box-starlink-rp {
    width: 300px;
}

.starlink--base--flex--row .font-20 {
    font-size: 20px;
}

.Usage-ServiceLines .sl-cardtitle {
    color: #2e1a70;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    padding-top: 5px;
    letter-spacing: .175em;
    padding-left: 5px;
}

.cardHeader-serviceLines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 5px;
}

.cardHeader-serviceLines>span:nth-child(2) {
    margin-right: 5px;
}

#selectedClick-column {
    cursor: pointer;
    color: #4c3aff;
}


.star-usage-Dashboard-Container {
    margin: 15px 15px 15px 25px;
}

.star-usage-sub-tabs-container {
    display: flex;
    gap: 5px;
    padding-left: 16px;
    display: inline-block;
}

.star-usage-sub-tabs-container .str-usage-sub-tabs {
    text-transform: none;
    border: 1px solid rgba(36, 76, 132, 1);
    height: 32px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    white-space: nowrap; 
    display: inline-flex; 
}

#selectedTab-background {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #eff1f0;
}

.download-expand-icons-container {
    display: flex;
}

.download-expand-icons-container img {
    cursor: pointer;
}

.download-expand-icons-container .margin-left-6 {
    margin-left: 6px;
}

.star-usage-Dashboard-Container .star-usage-cards-container {
    padding-top: 5px;
}

.card-box-parent .star-usage-card-contents {
    justify-content: space-between;
}

.star-usage-card-contents .content_bottom_ns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.star-usage-card-contents .no-padding-margin {
    margin: 0;
    padding: 0;
}

.content_bottom_ns .right-content-value {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: rgba(69, 70, 78, 1);
}

.star-usage-filters-popup-conatiner .MuiPaper-root {
    width: 276px;
    padding: 10px;
}

.star-usage-bar-chart-icons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.hide-axis-line .highcharts-axis-line {
    display: none;
}

.star-usage-bar-chart-icons img {
    cursor: pointer;
}

.filter-dateRange-container{
    width: 1500px;
}