.group-actions-tab-create-group-popup.preferences-pop-up .MuiDialogContent-root {
    height: 275px;
    padding-bottom: 0px;
}

.group-actions-tab-create-group-popup.preferences-pop-up .MuiDialogContent-root>div {
    display: flex;
    align-items: center;
    height: 100%;
}

.preferences-pop-up .time-zone-selection>div {
    padding: 0px;
    width: 280px;
}

.preferences-pop-up .time-zone-selection>div>div>div {
    padding: 0px;
    border-radius: 8px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
}

.preferences-pop-up .time-zone-selection>div>div>div>div {
    max-height: 175px;
}