.EdgeEvent_date_picker,
.EdgeEvent_date_picker>span {
    max-width: 250px;
    height: 35px;
}


.EdgeEvent_date_picker>span:focus {
    border-bottom: 0px;
    outline: none;
    box-shadow: none;
}

.EdgeEvent_date_picker.e-daterangepicker {
    top: 70px;
}

.EdgeEvent_date_picker>span {
    font-family: Roboto;
    font-style: normal;
    color: #676984;
    font-size: 14px;
    font-weight: bold;
    font-weight: normal;
    border: 0.5px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 6px;
    padding-left: 10px;
}

.EdgeEvent_date_picker_calendar.e-daterangepicker {
    top: 70px;
}

.marg-L-12 .FiltersButtonContainer {
  top: 0;
}

.container-edgeEvent .margin-events{
  margin-bottom:10px;
}

.container-edgeEvent .align-filters {
  display: flex;
  align-items: center;
}

.align-filters .position-relative {
  position: relative;
}

.FiltersButtonContainer .EdgeEvent_moreFilters_button {
    /* color: #080000; */
    /* top: 10px; */
    height: 38px;
    /* background: #ffffff; */
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 0.035em;
    margin-top: 0px;
    margin-left: 25px;
}

.filter-row .edgeEvents-paginationComp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: -70px;
}

.filter-row .edgeEvents-download {
  margin: 10px 5px 0px auto;
}

.edgeEvents-search .events-searchBar {
  width: 200px;
}

.container-edgeEvent .globalEvents-FleetDashboard {
  max-height: calc(100vh - 170px);
}

.padding-edge-events-container {
  padding: 20px;
}

.alertsTable-tableBody .events-description-width {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.alertsTable-tableCell-source {
  text-transform: capitalize;
}

.alertReports-tableRow .width-20 {
  width: 20%;
}

@media only screen and (max-height: 400px){
  .filter-row+div>div {
    max-height: 130px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .filter-row+div>div {
    max-height: 160px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .filter-row+div>div {
    max-height: 205px;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .filter-row+div>div {
    max-height: 255px;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .filter-row+div>div {
    max-height: 305px;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .filter-row+div>div {
    max-height: 355px;
  }
}

  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .filter-row+div>div {
      max-height: 405px;
    }
  }

   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .filter-row+div>div {
      max-height: 435px;
    }
  }

  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .filter-row+div>div {
      max-height: 500px;
    }
  }

  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .filter-row+div>div {
      max-height: 535px;
    }
  }

  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .filter-row+div>div {
      max-height: 610px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .filter-row+div>div {
      max-height: 650px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:950px) and (max-height:999px){
    .filter-row+div>div {
      max-height: 700px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:999px) and (max-height:1050px){
    .filter-row+div>div {
      max-height: 750px;
      overflow-y: auto;
    }
  }

 @media only screen and (min-height:1050px) and (max-height:1080px){
    .filter-row+div>div {
      max-height: 800px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1080px) and (max-height:1140px){
    .filter-row+div>div {
      max-height: 830px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1140px) and (max-height:1200px){
    .filter-row+div>div {
      max-height: 890px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1200px) and (max-height:1300px){
    .filter-row+div>div {
      max-height: 950px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1300px) and (max-height:1400px){
    .filter-row+div>div {
      max-height: 1050px;
      overflow-y: auto;
    }
  }

  @media only screen and (min-height:1400px) and (max-height:1920px){
    .filter-row+div>div {
      max-height: 1150px;
      overflow-y: auto;
    }
  }

  .filter-row.marg-L-12 {
    margin-top: 15px;
  }
  
.container-edgeEvent .MuiTextField-root label, .container-edgeEvent .MuiFormControl-root label, .siteCharts__filters .MuiTextField-root label, .siteCharts__filters .MuiFormControl-root label {
  top: 14.67%;
  font-style: normal;
  font-weight: bold;
  font-size: 0.65em;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #676984;
}

.container-edgeEvent .MuiTextField-root legend, .container-edgeEvent .MuiFormControl-root legend, .siteCharts__filters .MuiTextField-root legend, .siteCharts__filters .MuiFormControl-root legend {
  font-size: 0.65em;
}

.font13 {
  font-size: 13px;
}

.MuiTextField-root.input-box-events {
  width: 100%;
  max-width: 300px;
}

.MuiTextField-root:focus {
  outline: none !important;
  border:1px solid red;
  box-shadow: 0 0 10px #719ECE;
}

.MuiOutlinedInput-input {
  padding: 0;
}

.edge-evt-scroll {
  height: calc(100vh - 185px);
  overflow-y: auto;
}

.eventsFilterPadding {
  padding-left: 16px;
}

.edgeEventsPadding {
  padding: 0px 16px 0px 16px;
}