.manage-config-inventory-popup {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.manage-config-inventory-popup {
  width: 59%;
}

.manage-config-inventory-popup {
  display: flex;
  margin-bottom: 5px;
}

.manage-config-inventory-popup {
  color: #9a9a9a;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.manage-config-inventory-popup {
  width: 40%;
  color: #45464e;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.manage-config-inventory-popup {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #676984;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage-config-inventory-popup {
  margin-top: 0px;
}

.config-deploy-inventory-popup {
  margin-bottom: 0px;
}

.manage-config-inventory-popup .select-config-from {
  margin-bottom: 0px;
}

.manage-config-inventory-popup {
  width: auto;
  background-color: #264c86;
}

.manage-config-inventory-popup {
  border: 1px solid #264c86;
  color: #264c86;
}

.manage-config-inventory-popup {
  height: 35px;
  text-transform: uppercase;
}

.manage-config-inventory-popup {
  cursor: not-allowed;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.disabledButton {
  opacity: 0.5; /* Darken the button when disabled */
  pointer-events: none; /* Prevent clicks on the button when disabled */
}

.mng-config-btn {
  background-color: #00008b;
  color: #fff;
}

.hub-config-hub-name, .hub-config-hub-ip {
  width: 300px;
  height: 37px;
}

.hub-config-hub-name .MuiInputBase-root, .hub-config-hub-ip .MuiInputBase-root {
  width: 100%;
}

.hub-ip-config-type-copy-or-configure, .hub-ip-action-add-or-replace {
  display: flex;
  align-items: center;
}

.manage-config-pop-up .hub-ip-config-type-tabs, .manage-config-pop-up .hub-ip-config-type-tabs div {
  height: 38px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  cursor: pointer;
}

.manage-config-pop-up .hub-ip-config-type-tabs .active-tab {
  font-weight: 600;
  color: #496191;
  border-bottom: 1px solid #2F4B82;
}

.manage-config-pop-up .hub-ip-config-type-tabs .not-active-tab {
  font-weight: 500;
  color: #8B8D97;
  border-bottom: 0px;
}

.manage-config-pop-up .config-configuration-form-deploy .hub-ip-config-source-target-header {
  font-size: 16px;
  font-weight: 600;
}

.hub-config-hub-ip .MuiFormHelperText-root {
  font-family: 'Inter';
  color: red;
}

.config-deploy-inventory-popup .traffic-policy-site div:nth-child(1) {
  height: auto !important;
}

.manage-config-pop-up .config-configuration-form-deploy .serving-hub {
  width: unset;
}

.manage-config-pop-up .menuScroll .MuiChip-label {
  width: 100%;
  height: 27px;
  padding-top: 5px;
}

.config-configuration-form-deploy .serving-hub-checkbox {
  padding: 0px;
}