.viewPasswordEditButton {
  margin-top: 15px !important;
  margin-left: auto !important;
  /* margin-right: 57% !important; */
}

.editButton {
  font-size: 10px !important;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: uppercase !important;
  color: #ffffff !important;
  background: #264c86 !important;
  border-radius: 5px;
  width: 83.72px;
  height: 36.58px;
}

.changeProfileContainer {
  /* display: block !important; */
  margin: 22px !important;
  padding: 10px !important;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box !important;
  border-radius: 12px;
  margin-top: 30px !important;
  width: auto;
}

.profileContainer{
  overflow-y: auto;
  max-height: calc(100vh - 10px)
}

.profileText {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em !important;
  color: #273050;
  opacity: 0.75;
}

.profileText1 {
  font-size: 16px !important;
  line-height: 19px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em !important;
  color: #273050;
  margin-top: 7px !important;
}

.textProfile {
  margin-left: 40px !important;
  margin-top: 20px !important;
}

.avatarProfile {
  margin-left: 30px !important;
  margin-top: 25px !important;
}

.chipsPolicy {
  padding: 1px !important;
  padding-top: 5px !important;
  display: inline-block !important;
  font-size: 12px !important;
  background: #fff !important;
  height: 25px !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-radius: 30px !important;
}

@media only screen and (max-width: 850px) {
  .lineWidth {
    width: 550px !important;
  }
}

@media only screen and (max-width: 700px) {
  .lineWidth {
    width: 400px !important;
  }
}

@media only screen and (max-width: 550px) {
  .lineWidth {
    width: 300px !important;
  }
}

@media only screen and (max-width: 450px) {
  .lineWidth {
    width: 200px !important;
  }
}

@media only screen and (max-width: 400px) {
  .profileText {
    font-weight: 500 !important;
    font-size: 10px !important;
    /* line-height: 14px !important; */
    display: flex;
    align-items: center;
    letter-spacing: 0.05em !important;
    color: #273050;
    opacity: 0.75;
  }
}

@media only screen and (max-width: 400px) {
  .profileText1 {
    font-weight: 500 !important;
    font-size: 10px !important;
    /* line-height: 14px !important; */
    display: flex;
    align-items: center;
    letter-spacing: 0.05em !important;
    color: #273050;
    opacity: 0.75;
  }
}

.marginTop20{
  margin-top: 20px;
}

.marginBottom20{
  margin-bottom: 20px;
}

.groupTableHeading{
  background-color:rgb(236 234 234);
  border:1px solid #cdcdcd;
}

.groupTableCell{
  border:1px solid #cdcdcd;
}

.viewUserDialog .viewUserDialogTitle{
  font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #45464E;
border-bottom: 1px solid #EBEBEB;
padding: 20px 24px 0px 24px;

}

.viewUserCloseIcon{
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.viewUserDialog .viewUserDialogContent{
  padding: 10px;
  min-width: 400px;
  width: 600px;
  /* max-height: 450px; */
  /* //style={{ padding: "0px", minWidth: "403px", width: "600px", maxHeight: "450px" }} */
}

.menuScroll ::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
.menuScroll ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 254, 254);
  border-radius: 18px !important;
  background: rgb(255, 255, 255);
}

/* Handle */
.menuScroll ::-webkit-scrollbar-thumb {
  background:#D9D9D9 !important;
  border-radius: 0px !important;
}

/* Handle on hover */
.menuScroll  ::-webkit-scrollbar-thumb:hover {
  background:#ffffff !important;
}
 .viewUserDialogContent .viewUserRole{
  /* marginTop: "10px", fontSize: "12px", lineHeight: "14px" color: "#676984"  */
  margin-top: 10px;
  font-size: 12px;
  font-family: Inter;
line-height: 14px;
color: #676984;

 }

 .viewUserTableContainer{
  border:1px solid #EBEBEB;
  max-height: 400px;
 }

 .viewUserRoleContainer{
  margin-bottom: 10px;
 }

 .viewUserTableCell{
  border: 1px solid #EBEBEB;
  font-size: 12px;
  font-family: Inter;
 }

 .viewUserTableRow{
  background: #ffffff;
 }

 .tableHeaderFleetVesselData .viewUsertableHeader{
  font-family: Roboto Mono;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  color:#8B8D97;
  background: #F5F5F5;
  border: 1px solid #EBEBEB;
 }

 .scrollFix {
   height: calc(100vh - 10px);
   overflow-y: auto;
 }

 .changeProfileContainer .profile-user-name-text {
   white-space: nowrap;
   max-width: 280px;
   text-overflow: ellipsis;
   overflow: hidden;
 }