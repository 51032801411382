.quota_management--usage_tooltip.MuiTooltip-tooltip {
    padding: 0.25rem;
    max-width: unset;
}

.quota_management--usage_tooltip .MuiTableCell-root {
    border: 1px solid #EBEBEB;
    padding: 0.25rem 0.5rem;
    border-collapse: collapse;
}

.quota_management--usage_tooltip .MuiPaper-root {
    background-color: #F5F5F5;
}

.quota_management--usage_tooltip--cell_exceeded {
    color: #ee3939;
}

.quota_management--usage_tooltip--cell_alerting {
    color: #daaf02;
}