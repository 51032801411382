.marginBottom20px{
    margin-bottom: 20px;
}
.marginBottom10px{
    margin-bottom: 12px;
}
.paddingTop16px{
    padding-top: 16px;
}
.customCheckBox{
    display: flex;
    padding:10px;
}
.gridflex{
    display: flex;
    gap: 0px;
    align-items: baseline;
}

.gridflex .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
    padding-right: 8px;
}

.white{
    background-color: #FFFFFF;
}

.tkt_more_filters_dialog .MuiPaper-root{
    width: 274px;
    height: 834px;
    background: #FFFFFF;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    border-radius: 8px;
    position: absolute;
    top: 30px;
}

.dialog_title{
    background: #f5f5f5;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 15px !important;
}

.dialog_title_notes{
    background: #264C86;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 21px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.edge_events_more_filters_dialog .dialog_title2{
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    color: #45464E;
    padding: 0px;
}

.dialog_title > div{
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #45464E;
    align-items: center;
}
.dialog_title > div > div {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.dialog_closeIcon{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.dialog_closeIcon-filters{
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    left: 233px;
}
.dialog_clearAllButton{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    color: #676984 !important;
}
.dialog_clearAllButton, .dialog_okButton{
    border-radius: 5px !important;    
    width: 123px;
    height: 37px;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    text-align: right !important;
    letter-spacing: 0.035em !important;
    text-transform: none !important;
}

.dialog_okButton {
    background: #264C86 !important;
    color: #fff !important;
}

.updateSiteAdressbtn .dialog_okButton {
    min-width: 132px !important;
}

.tickets-apply-btn .dialog_okButton{
    background: #1B51A7;
    color: #FFFFFF;
    text-transform: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    Line-height: 16.94px;
    align-items: center;
    width: 103px;
    height: 29px;
    left: 54px;
}

.tickets-apply-btn .dialog_okButton :hover {
    background: #1B51A7;
}
.tickets-apply-btn .ticketsClearFilterButton {
    text-transform: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
}
.tkt_more_filters_dialog_status{
    padding: 16px;
    border-radius: 10px;
    width:auto;
}
.tkt_more_filters_dialog_status_title, .tkt_more_filters_dialog_timeframe_title,
.tkt_more_filters_dialog_timeframe_SelectorBox, .timeframeDropdownMenuItem,
.tkt_more_filters_dialog_time_range_title, .tkt_more_filters_date_picker > span > input{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    align-items: center !important;
    color: #45454E !important;
    letter-spacing: 0.05rem;
}
.tkt_more_filters_dialog_status_title_two{
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 13px !important;
    line-height: 14px !important;
    align-items: center !important;
    color: #676984 !important;
}
.tkt_more_filters_dialog_status_title, .tkt_more_filters_dialog_timeframe_title,
.tkt_more_filters_dialog_time_range_title{
    font-weight: 600 !important;
}
.tkt_more_filters_dialog_timeframe_SelectorBox, .timeframeDropdownMenuItem,
.tkt_more_filters_date_picker > span > input{
    font-weight: normal !important;
}
.tkt_more_filters_dialog_status_selection{
    display: flex;
}
.tkt_more_filters_dialog_status_unselected{
    border: 1px solid rgba(20, 99, 172, 0.24);
    background: none;
    color: #676984;
}
.tkt_more_filters_dialog_status_selection div{
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}
.tkt_more_filters_dialog_status_open{
    margin-right: 20px;
}
.tkt_more_filters_dialog_status_selected{
    background: #2860B4;
    border: 1px solid rgba(20, 99, 172, 0.24);
    color: #FFFFFF; 
}
.gridflex .typo{
    font-family: 'Inter';
    font-size: 12px !important;
    color:#45464E;
    line-height: 14.52px;
    padding-bottom: 8px;
}
.typo2{
    font-size: 12px !important;
    padding: 3px 0px 0px 3px;
    text-transform: capitalize;
    color: #676984;
    font-weight: 500 !important;
}
.typo3{
    font-size: 11px !important;
    padding: 0px 3px;
    text-transform: capitalize;
    font-weight: 300 !important;
    color: #676984;
    white-space:nowrap;
}
.show_filter{
    top: 5px;
    height: 45px !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 12px !important;
    text-transform:capitalize;
    letter-spacing: 0.035em !important;
    margin-top: 3px !important;
    height: 45px !important;
    margin-right: 10px !important;
    border:1px solid rgba(20, 99, 172, 0.24);
}

.tkt_more_filters_dialog_timeframe_SelectorBox {
    width: 250px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box !important;
    border-radius: 8px !important;
}
.timeframeDropdownMenuItem{
    width: 250px;
    line-height: 18px;
    display: flex;
    align-items: center;
}
.tkt_more_filters_date_picker > span {
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    padding-left: 10px;
}
.tkt_more_filters_date_picker, 
.tkt_more_filters_date_picker > span{
    width: 100% !important;
    height: 48px !important;
}
.tkt_more_filters_date_picker > span:focus {
    border-bottom: 0px !important;
    outline: none !important;
    box-shadow: none !important; 
}
.tkt_more_filters_dialog_timeframe_SelectorBox > fieldset{
    border-width: 0px !important;
 }
 .tkt_more_filters_date_picker_calendar.e-daterangepicker{
    top: 70px !important;
 }

 #ticket-pop-up1 {
     margin-left: 412px;
 }

 #ticket-pop-up2 {
     margin-left: 316px;
 }

 .padding-10 {
    align-items: center;
 }

 .tkt_more_filters_dialog .pad-10 {
     padding: 0px;
 }

.tkt_more_filters_dialog .tickets-title {
     display: flex;
     align-items: center;
     padding: 0px;
 }

 .tkt_more_filters_dialog .tickets-apply-btn {
    display : flex;
    justify-content: left;
    border-top: 1px solid #ebebeb;
    padding: 20px 16px 20px 16px;
 }