.componentCatergory {
  width: 44px !important;
  height: 12px !important;
  text-transform: uppercase;
  background: #ffffff;
  font-size: 10px;
  font-weight: 500;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #273050;
  margin: -4px;
}

.componentStatus {
  width: 102px !important;
  height: 28px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.02em !important;
  white-space: nowrap !important;
  color: #273050 !important;
  background: #ffffff !important;
  border: 1px solid rgba(53, 83, 137, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
}

.Cancel-button {
  border: 1px solid #264c86 !important;
  color: #264c86 !important;
  width: -webkit-fill-available !important;
  height: 48px !important;
  border-radius: 4px !important;
  height: 38px !important;
}

/* .customTextArea .MuiInputBase-input {
  height: 60px !important;
} */
