.chartsEventTableContainer .chartsEventTableFlights {
    height: auto !important;
    overflow-y: auto;
    overflow-x: hidden;
}


.selected-row .highlight {
    background-color: lightblue !important;
}

.maps-charts-dialogue {
    inset: auto !important;
}