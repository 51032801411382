.dashboard_popop_dialog .MuiPaper-root{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}

.dashboard_popop_dialog .MuiDialogContent-root {
    overflow-y: visible;
    padding-left: 0px;
    min-width: 750px;
    max-height: 350px;
}

.dashboard_popop_dialog_sitelist .MuiPaper-root{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}

.dashboard_popop_dialog_sitelist  .MuiDialogContent-root {
    overflow-y: visible;
    min-width: 750px;
    max-height: 350px;
}

.dashboard_popop_dialog_sitelist .MuiDialogContent-root {
    overflow-y: visible;
    min-width: 400px;
    max-height: 350px;
}
.custom_confirm_dialog_title{
    font-weight: 500 !important;
    font-size: 18px !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button{
    /* color: #264C86 !important; */
}
.custom_confirm_dialog_no_button{
    color: #264C86 !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-family: Roboto !important;
    font-style: normal !important;
    line-height: 21px  !important;
    align-items: center  !important;
}
.custom_confirm_dialog_sub_title{
    font-size: 13px !important;
}
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-weight: 500 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: none !important;
    border: 1px solid #264C86 !important;
}
.custom_confirm_dialog_yes_button{
    background: #264C86 !important;
    color: #fff !important;
}

.width-750 {
    min-width: 750px;
}
.width-750 .MuiPaper-root .MuiButtonBase-root.Mui-expanded {
    min-height: 50px;
}

.dashboard_popop_dialog .pop-head,
.dashboard_popop_dialog_sitelist .pop-head {
    background: #FFFFFF; 
    border-bottom: 1px solid #EFEFEF;
    padding-top: 15px;
    padding-bottom: 5px;
}


.dashboard_popop_dialog .width-752px {
    width: 752px;
}

.interface-profile-popup .width-752px {
    width: 914px;
}

.interface-profile-popup .MuiDialog-paperWidthSm {
    position: fixed;
    top: 100px;
}

.dashboard_popop_dialog .internet-status-popup-noData,
.dashboard_popop_dialog_sitelist .internet-status-popup-noData {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
}

.width-750 .internet-status-popup-border {
    border: 1px solid #EFEFEF;
}

.site-name-header {
    color: black; 
    font-size: 14px;
}

.close-btn {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: none;
    color: black;
}

.dialog-content-parent {
    padding: 8px 16px 8px 0px;
    margin-bottom: 10px;
}

.new-interface-profile-popup .MuiDialog-paperWidthSm {
    max-width: 1088px !important;
}

.new-interface-profile-popup .width-752px {
   width: 1040px;
}