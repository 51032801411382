.scrollTooltip {
    max-width: 400px;
    max-height: 400px;
    overflow-x: auto;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 10px;
    font-weight: 500;
    font-size: 12px;
}

.jsonTooltip {
    background: darkgrey;
}

.jsonTooltip .scrollTooltip {
    max-width: 800px;
}