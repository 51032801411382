
.chatContainer .noData {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.chartReset {
  right: 30px;
  display: flex;
  margin-left: auto;
  z-index: 10000;
  border: none;
  border-radius: 10px;
  margin-top: 5px;
  box-shadow: inset 0 0 5px grey;
  cursor: pointer;
}

.histogramChart {
  height: 300px !important;
  margin-bottom: 20px;
}

.resetButtonContainer {
  position: relative;
  display: flex;
  padding-bottom: 5px;
}
/* 
.chatContainer {
  display: block;
}

 .Chart {
    font-family: sans-serif;
    text-align: center;
    height: 100%;
  }



.chartMapContainer {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.customTMapitle {
  display: flex;
  border-bottom: 0.5px solid grey;
}

.customizedMapChartTitle {
  font-size: 15px;
  color: #000000;
  padding: 5px;
  font-weight: 700;
}

.lineChartRates {
  margin-left: auto;
  padding: 10px;
  display: flex;
}

.rateTitle {
  color: #2cc1de;
  margin-left: 20px;
  margin-top: 3px;
}

.usageContainer {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}


.lineChartRates .modemButton {
  border: 1px solid black;
  background: white;
  margin-right: 5px ;
  font-size: 10px;
}

@media only screen and (max-width: 599px) {
  .lineChartRates{
    display: block;
  }
}  */