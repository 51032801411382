.registration_labelTextbox {
  height: 38.56px;
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}

.registration_UserNameInput {
  top: 10% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}

.registration_passwordRequirement {
  margin-left: 2em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.registration_loginMobileText {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.5rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
}

.MuiGrid-root.registration_userNameContainer {
  margin-top: 40px;
}

@media only screen and (max-width: 550px) {
  .registration_TextBoxMobileView {
    margin-left: 15px;
    width: 95.5%;
  }
}

@media only screen and (min-width: 600px) {
  .registration_TextBoxMobileView {
    margin-left: 25px;
    width: 89%;
  }
}

@media only screen and (max-width: 550px) {
  .registration_inputBoxMobileView {
    margin-right: 8% !important;
    margin-left: 14px !important;
  }
}

@media only screen and (min-width: 600px) {
  .registration_inputBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (min-width: 960px) {
  .registration_inputBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxPassword {
    margin-left: 13px !important
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxPassword {
    margin-left: 25px;
  }
}

.registerButtonContainer{
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.MuiButton-root.registerButton {
  color: #ffffff;
  background: #264C86;
}

.MuiButton-root.registerButton:hover {
  background: #254b83;
}

@media only screen and (max-width: 550px) {
  .registerButtonContainer {
    margin-left: 20px !important;
    margin-right: 5% !important;
  }
}

@media only screen and (min-width: 600px) {
  .registerButtonContainer {
    margin-left: 30px !important;
    margin-right: 15% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButtonContainer {
    margin-right: 15% !important;
    align-items: end;
    text-align: right;
  }
}

@media only screen and (max-width: 550px) {
  .registerButton {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .registerButton {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .registerButton {
    max-width: 160px;
  }
}

.contactNumber{
  margin-top: 15px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 550px) {
  .contactNumber {
    margin-left: 23px !important;
    width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .contactNumber {
    margin-left: 35px;
    width: 86%
  }
}

@media only screen and (max-width: 600px) {
  .registration_passwordRequirement {
    margin-left: 1.2em;
  }
}

.visibilityButton .MuiIconButton-root{
  padding: 0px !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
}

.MuiOutlinedInput-input{
  border: 0px !important;
  /* padding: 10px 14px; */
}