.golden-config-default-container .create-default-config-btn {
    height: 32px;
    background: #1B51A7;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 4px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: -7px;
    text-transform: none;
}

.golden-config-default-container .create-default-config-btn:hover {
    background: #1B51A7;
}

.default-config-component .TopUsersConatiner .backup-config-table-container  {
    max-height: fit-content;
}
.default-config-component .golden-config-default-container {
    padding-top: 5px;
}
.default-config-component .golden-config-default-container .default-config-search-btn{
    display: block;
}
.default-config-component .search-create-button{
    display: flex;
}
.default-config-component .create-default-config-btn{
    margin-top: -23px;
    margin-left: 30px;
}
.config-search-bar-text .default_search {
    width: 245px;
    margin-top: -9px;
    margin-bottom: 25px;
}

.config-left-align .config-active-status {
    width: 47px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    background: #E4F7EA;
    border-radius: 29px;
    font-size: 12px;
    color: #1B6F37;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
}

.config-left-align .config-inactive-status {
    color: #AAAAAA;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

/*  create default config */

.create-default-config-container {
    width: 100%;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.create-default-config-container .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #45464E;
    background-color: #f5f5f5;
    height: 15px;
    border: 1px solid #EBEBEB;
}

.create-default-config-title img {
    width: 25px;
    cursor: pointer;
}

.create-default-config-content .name-input-default-config {
    position: relative;
    display: flex;
    height: 40px;
    padding-top: -10px;
}

.create-default-config-content .name-margin-top {
    margin-top: 5px;
}

.name-input-default-config>span {
    width: 12%;
    margin-top: 15px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.name-input-default-config .config-radio-button {
    margin: 15px 25px 0px -4px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.name-input-default-config .config-select-product {
    margin-right: 15px;
}

.name-input-default-config .create-config {
    height: 265px;
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    margin-top: 15px;
    text-transform: none;
}

.create-default-config-content {
    height: 400px;
    padding: 20px 15px 0px 15px;
}

.create-default-config-content .config-create-actions {
    position: absolute;
    bottom: 0px;
    right: 0;
    padding-right: 12px
}

.config-create-actions .config-cancel-btn {
    color: #1B51A7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin-right: 20px;
    cursor: pointer;
}

.config-create-actions .config-create-btn {
    width: 127px;
    height: 32px;
    background: #1B51A7;
    border-radius: 4px;
    color: #fff;
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;
    border: 1px solid #676984;
    cursor: pointer;
}

.config-create-actions .config-create-btn:hover {
    background: #1B51A7;
}

.name-input-default-config .name-config-default {
    height: 30px;
    width: 388px;
    margin-top: 9px;
}

.create-config .dynamic-create-row-config {
    border: 1px solid #EBEBEB;
    width: 1015px;
    border-top: none;
}

.create-config .empty-dynamic-create-row-config {
    border: 1px solid #EBEBEB;
    border-top: none;
    width: 1015px;
}

.create-config .dynamic-create-row-config button, .create-config .empty-dynamic-create-row-config button {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

.config-left-align img {
    margin-left: 9px;
    cursor: pointer;
    width: 18px;
}

.config-left-align .notes-serachBar {
    width: 150px;
    padding: 5px;
    height: 32px;
    margin: -4px;
}

.create-default-config-container .MuiDialog-paperWidthMd {
    max-width: 1200px ;
}

.config-tableRow .create-select-product {
    background: #F5F5F5;
}

.default-config-autoComplete .select-product-td {
    border: 1px solid #EBEBEB;
    background: none;
}

.default-config-autoComplete td {
  padding: 7px;
}

.auto-config-textField {
    width: 163px;
    font-size: 12px;
    margin: auto;
}

.config-list-box-props {
    max-height: 150px;
    font-size: 12px;
}

.config-tableRow .config-tableCell {
    width: 0.3%;
}

.create-config-table .help-icon-create {
    position: absolute;
    margin-left: 100px;
    margin-top: 17px;
    color: #45464E;
}
.select-product-td .selected-config-details {
    width: 100%;
}
.select-product-td .selected-config-details > div{
    font-family: Roboto;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px;
    color: #9A9A9A;
    display: flex;
    width: 100%;
}
.select-product-td .selected-config-details .value {
    width: 65%;
    color: #676984;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.MuiTextField-root.input-box-def-conf {
    width: 300px;
}