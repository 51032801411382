.tkt_new_ticket_dialog .MuiPaper-root{
    width: 1600px;
    height: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(103, 105, 132, 0.25);
    border-radius: 8px;
}
.tkt_new_ticket_dialog_subject, .tkt_new_ticket_dialog_description, .tkt_new_ticket_dialog_assignee,
.tkt_new_ticket_dialog_event, .tkt_new_ticket_dialog_attachments, .tkt_new_ticket_dialog_attachments_preview{
    margin-top: 10px;
}
.tkt_new_ticket_dialog_description_title, .tkt_new_ticket_dialog_subject_title, 
.tkt_new_ticket_dialog_assignee_title, .tkt_new_ticket_dialog_attachments_title,
.tkt_new_ticket_dialog_event_title, .tkt_new_ticket_dialog_attachments_preview_title {
    margin-bottom: 5px;
    margin-top: 20px;
}
.tkt_new_ticket_dialog_subject_title,
.tkt_new_ticket_dialog_description_title,
.tkt_new_ticket_dialog_event_title,
.tkt_new_ticket_dialog_assignee_title,
.tkt_new_ticket_dialog_attachments_title,
.tkt_new_ticket_dialog_attachments_preview_title{
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #676984 !important;
    text-transform: uppercase;
}
.tkt_new_ticket_dialog_description_textfield,
.tkt_new_ticket_dialog_description_textfield > div, 
.tkt_new_ticket_dialog_description_textfield > div > textarea,
.tkt_new_ticket_dialog_attachments_drop_area{
    width: 100%;
    height: 80px !important;
}
.tkt_new_ticket_dialog_subject_textfield > div > fieldset,
.tkt_new_ticket_dialog_description_textfield > div > fieldset,
.tkt_new_ticket_dialog_assignee_name > fieldset,
.tkt_new_ticket_dialog_assigneeAutocomplete  fieldset{
    border: none !important;
}
.tkt_new_ticket_dialog_subject_textfield > div,
.tkt_new_ticket_dialog_description_textfield > div{
    padding: 0px !important;
}
.tkt_new_ticket_dialog_subject_textfield > div >textarea,
.tkt_new_ticket_dialog_description_textfield > div > textarea{
    padding: 10px;
}
.tkt_new_ticket_dialog_subject_textfield > div > textarea,
.tkt_new_ticket_dialog_description_textfield > div > textarea,
.tkt_new_ticket_dialog_eventDatePicker > span > input,
.tkt_new_ticket_dialog_assignee_name > input,
.tkt_new_ticket_dialog_assigneeAutocomplete input,
.tkt_new_ticket_dialog_assigneeAutocompleteListItems{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #676984 !important;
}
.tkt_new_ticket_dialog_subject_textfield > div > textarea,
.tkt_new_ticket_dialog_description_textfield > div > textarea,
.tkt_new_ticket_dialog_eventDatePicker > span,
.tkt_new_ticket_dialog_assignee_name,
.tkt_new_ticket_dialog_assigneeAutocomplete,
.tkt_new_ticket_dialog_attachments_preview_image{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}
.tkt_new_ticket_dialog_subject_textfield,
.tkt_new_ticket_dialog_subject_textfield > div,
.tkt_new_ticket_dialog_subject_textfield > div > textarea,
.tkt_new_ticket_dialog_eventDatePicker, 
.tkt_new_ticket_dialog_eventDatePicker > span,
.tkt_new_ticket_dialog_assignee_name,
.tkt_new_ticket_dialog_assigneeAutocomplete,
.tkt_new_ticket_dialog_attachments_preview_image {
    width: 100%;
    height: 50px !important;
}
.tkt_new_ticket_dialog_eventDatePicker > span{
    padding-left: 10px;
}
.tkt_new_ticket_dialog_eventDatePicker > span:focus {
    border-bottom: 0px !important;
    outline: none !important;
    box-shadow: none !important; 
}
.tkt_new_ticket_dialog_assigneeAutocomplete > div,
.tkt_new_ticket_dialog_assigneeAutocomplete > div > div,
.tkt_new_ticket_dialog_assigneeAutocomplete > div > div > div{
    height: 100%;
    /* width: 100%; */
}
.tkt_new_ticket_dialogContent{
    display:flex;
}
.tkt_new_ticket_dialogContent_part1, .tkt_new_ticket_dialogContent_part2{
    width: 50%;
}
.tkt_new_ticket_dialogContent_part1{
    padding-right: 12px;

}
.tkt_new_ticket_dialogContent_part2{
    padding-left: 12px;
}
.tkt_new_ticket_dialog_attachments_drop_area{
    padding: 0px !important;
    border-width: 0px !important;
    background: #EEF0F7 !important;
    border-radius: 8px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #676984 !important;
}
.tkt_new_ticket_dialog_attachments_drop_area > div{
    padding: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tkt_new_ticket_dialog_attachments_drop_area > div > div{
    padding-left: 10px;
}
.tkt_new_ticket_dialog_attachments_drop_area .support{
    font-size: 9px !important;
    line-height: 11px !important;
    color: #A7A9C7 !important;
}
.tkt_new_ticket_dialog_attachments_preview{
    width: 100%;
}
.tkt_new_ticket_dialog_attachments_preview_remove_all{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #F12222;
    display: block;
    float: right;
    text-transform: none;
    cursor: pointer;
}
.tkt_new_ticket_dialog_attachments_preview_image {
    display: flex;
    padding: 5px;
    overflow-x: auto;
}
.tkt_new_ticket_dialog_attachments_preview_base64_image{
    border-radius: 4px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.tkt_new_ticket_dialog_attachments_preview_deleteIcon{
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: -5px;
}
.tkt_new_ticket_dialog_attachments_preview_image div{
    position: relative;
    width: 50px;
    min-width: 50px;
    margin-right: 10px;
}
.tkt_new_ticket_dialog_attachments_drop_area:focus{
    outline-width: 0px;
}

.cur-point {
    cursor: pointer;
    margin-bottom: 10px;
}

.ticket-description-alert {
    font-size: 11px;
    color: red;
}

.ticket-dialogue-actions .send-ticket-enable {
    background: rgb(38, 76, 134);
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 35px;
    margin-left: 5px;
    border: 1px solid #264c86;
    font-weight: 500;
    font-size: 11px;
    padding: 0px 25px;
}

.ticket-dialogue-actions .send-ticket-enable:hover {
    background: rgb(38, 76, 134);
}

.ticket-dialogue-actions .send-ticket-disable {
    background-color: rgb(232, 240, 253);
    color: rgb(38, 76, 134);
    height: 35px;
    margin-left: 5px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    font-weight: 500;
    font-size: 11px;
    padding: 0px 25px;
    cursor: auto;
}

.ticketArrowIconPaddingReset .MuiIconButton-root {
    padding: 0px;
}

.ticketArrowIconPaddingReset .MuiAutocomplete-endAdornment {
    top: auto;
}

.ticketAssigneeRequired {
    position: absolute;
    bottom: 8px;
    left: 120px;
}

.ticketSelectWidth {
    width: 100%;
}

.creatTicketSelecters {
    width: 100%;
    height: 38px;
}

.creatTicketSelecters .MuiInputBase-root {
    padding: 7.5px 14px 7.5px 14px;
}

#createTicketEventDate {
    width: 100%;
    height: 38px;
}

#createTicketEventDate > span {
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 12px;
    height: 38px;
}

#createTicketEventDate > span > input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #676984;
}
