.content_filter-blocked-categories-heading{
    color: #45464E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.MuiGrid-root.content-filter-blocked-categoies-div {
    margin-top: 15px;
}

.MuiGrid-root.content-filter-blocked-seletced-categoies-div {
    height: 65px;
    overflow-y: scroll;
}

.content_filter-blocked-categories-checkbox-text{
    color:  #45464E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

.content_filter-blocked-categories-checkbox-select-all-text{
    color: #1B51A7;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.MuiChip-root.content-filter-selected-blocked-category-chip{
    background-color: #F4F5FA;
    margin: 5px;
}