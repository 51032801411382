.breadcrumbs {
    padding-left: 15px;
    box-shadow: inset 0 0 3px grey;
    width: 100%;
    height: 30px;
    position: relative;
    padding-top: 3px;
} 

.breadcrumbs_NewSummary {
    padding-left: 20px;
    width: 100%;
    height: 62px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1299;
} 

.pageName {
    font-size: 14px;
    font-weight: 500;
}

.currentPageName {
    font-size: 14px;
    font-weight: 500;
}
.MuiBreadcrumbs-li {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #264C86;
    opacity: 0.85;
}

.MuiBreadcrumbs-li .MuiTypography-root {
    color: #264C86;
}

.MuiBreadcrumbs-li .decortion {
    text-decoration: none;
}
.MuiBreadcrumbs-separator{
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.edgeStatus {
    position: absolute;
    right: 140px;
    top: 4px;
    font-weight: 500;
    margin-top: 2px;
}
.edgeStatus img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.edgeOnline {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #273050;
}

.edgeOffline {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #273050;
}

.commonLoader {
    position: absolute;
    right: 150px;
    top: 15px;
}

.new-summary-filter-chips {
    margin-top: 8px;
}

.new-summary-filter-chips .MuiChip-label {
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 600px) {
    .breadcrumbs{
        position: fixed;
        top: 48px;
        background-color: #ffffff;
        z-index:  10;
    }

    .breadcrumbs_NewSummary{
        position: fixed;
        top: 49px;
        background-color: #FFFFFF;
        border: 1px solid #EBEBEB;
    }
}

@media only screen and (max-width: 599px) {
    .SubHeader{
        position: relative;
        background: #F4F7F8;
        z-index:  1299;
        border-bottom: 1px solid #000000;
    }

    .breadcrumbs {
        position: fixed;
        z-index: 11;
        margin-top: 48px;
    }
}

.commonFilters {
    display: flex;
}

.commonLoader_NewSummary {
    position: absolute;
    right: 150px;
    top: 30px;
}

.commonLoader_Topology{
    position: absolute;
    right: 30px;
    top: 90px;
}

.help_NewSummary {
    position: absolute;
    right: 100px;
    top: 12px;
}

.ht-wt-kc-icon {
    height: 24px;
    width: 24px;
}

.sport-user-guide {
    position: fixed;
    right: 12px;
    cursor: pointer;
    color: #2860B4;
}

.commonFilters .period-commonLoader_NewSummary-right-317 {
    position: fixed;
    right: 326px;
    top: 80px;
}

.commonFilters .period-commonLoader_NewSummary-right-65 {
    position: fixed;
    right: 65px;
    top: 80px;
}