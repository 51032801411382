.starlink--dashboard--card_progress .MuiLinearProgress-colorPrimary {
    /* height: 0.5rem; */
    background-color: #D6DAFA;
    /* border-radius: 5px; */
}

.starlink--dashboard--card_progress .MuiLinearProgress-barColorPrimary {
    background-color: #2F4B82;
}

.MuiTypography-root.starlink--dashboard--card_title {
    color: #45464E;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.2rem;
}

.MuiTextField-root.input-box-starlink-dash {
    width: 100%;
    max-width: 300px
}

.margin-left-sl-15 {
    margin-left: 15px;
}

.starlinkAlertsTableDiv{
    height: 300px;
    overflow-y: scroll;
}

.starlinkAlertsTableWidth{
    table-layout: fixed;
    width: 100%;
}

.starlinkEllipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch; 
}

.alertIconColor{
    color: #d5d516;
}

.serviceLineLink{
    color: blue;
    cursor: pointer;
}

.starlink-d-flex{
    display: flex;
}

.starlink-ml-4{
    margin-left: 4px;
}

.starlinkGeo-font{
    font-size: 12px;
}