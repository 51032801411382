.componentCatergory {
  width: 80px !important;
  height: 24px !important;
  text-transform: uppercase;
  background: #ffffff;
  border: 1px solid rgba(20, 99, 172, 0.24);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.componentStatus {
  width: 102px !important;
  height: 28px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.02em !important;
  white-space: nowrap !important;
  color: rgba(39, 48, 80, 0.75) !important;
  background: #ffffff !important;
  border: 1px solid rgba(53, 83, 137, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  margin: auto !important;
}


.vesselCounts {
  z-index: 1000;
  position: fixed;
  width: 94%;
  float: right;
  display: flex;
}

.vesselCounts .vCounts {
  margin-top: 2px;
  padding-right: 10px;
}

.vesselConatiner {
  display: flex;
  margin-left: auto;
  margin-right: 30px;
  margin-top: 10px;
  border: 1px solid black;
  background: white;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}