.inventory-edit-actions-container .device-config-pop-config-list-menu {
    width: 20%;
}

.inventory-edit-actions-container .device-config-pop-config-list-menu-content {
    width: 80%;
    padding: 10px;
}

.inventory-edit-actions-container .device-config-pop-config-list-menu-content .MuiDialogContent-root:first-child {
    padding-top: 0px;
}

.create-default-config-title.edit-actions-title .device-configuration-popup .selected-site-device.device-configuration-popup-devicename{
    margin-left: 44px;
}

.popup-fixed-position .MuiDialog-paperFullWidth {
    position: fixed;
    top: 82px;
    left: 200px;
    width: 836px;
    max-height: calc(100vh - 130px);
}

#popup-fixed-position-200px .MuiDialog-paperFullWidth {
    left: 200px;
}

#popup-fixed-position-77px .MuiDialog-paperFullWidth {
    left: 77px;
}