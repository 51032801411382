
  .MuiGrid-root.create-content-filter-single-form-row {
    height:65px
  }
  
  .MuiGrid-root.create-content-filter-single-form-span-center{
    display:flex;
    justify-content: flex-start;
  }
  
  .MuiInputBase-input.create-content-filter-comment{
    height:100px
  }