.manage-config-log-status-td {
  cursor: pointer;
  color: #0000ee;
}

.manage-config-log-status-td {
  cursor: pointer;
}

.manage-config-log-head-name {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #45464e;
  padding: 0px 0px 8px 8px;
}

.manageConfiglogHeadName {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #45464e;
}

.manage-config-log-left-align {
  text-align: center;
}

.manage-config-log-head {
  margin-top: 60px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 1rem;
}

.manage-log-pagnition-top-10px {
  padding-top: 10px;
}

.manage-config-log-device-cell > div {
  text-overflow: ellipsis;
  font-size: 10px;
  background: #fff;
  display: flex;
}
.manage-config-log-status .tableHead th {
  text-align: left;
  padding-left: 15px;
}

.manage-config-log-status .tableBody td{
  text-align: left;
  padding: 15px;
}

.manage-config-log-status .tableBody td button{
  padding: 0;
}

.manage-config-log-status .tableBody td .pointer {
  cursor: pointer;
}

.logs-table-search-container {
  display: flex;
}

.logs-table-search-container .config-logs-download-loader-div {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-right: 10px;
}

.logs-table-search-container .config-logs-loader {
  position: relative;
}