.rc--links_table .MuiTableContainer-root{
    overflow: hidden;
}

.rc--links_table .MuiTableCell-root {
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
}

.rc--links_table .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
}

.rc--links_table .MuiTableCell-body {
    padding: 0.5em;
}

.rc--copy_button.MuiIconButton-root {
    margin-left: 0.5rem;
}

.rc--links_table .MuiTableCell-root.uppercase {
    text-transform: uppercase;
}

.rc--links_table--column--actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rc--links_table .MuiTableCell-body {
    word-break: break-all;
}

.rem-con-editable-row .rem-con-table-body {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 10px 5px 10px 5px;
    font-weight: 500;
    width: 80px;
}

.rem-con-table-body.width30 {
    width: 30px;
}

.rem-con-table-body.width120 {
    width: 120px;
    text-wrap: nowrap;
}

.rem-con-editable-row .rem-conn-data-width {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 75px;
}

.rm-conn-scroll {
    height: calc(100vh - 180px);
    overflow-y: auto;
}

.remcon-tableRow .rem-con-table-fleetDataTable {
    color: #8B8D97;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* width: 16%; */
    padding: 0px;
}

.rc-editable-date .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 35px;
}

.rc-editable-date .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px;
}

.remcon-tableRow .rc-type-width {
    width: 6%;
}

.remcon-tableRow .rc-expiryDate-width {
    width: 13%;
}

.remcon-tableRow .rc-actions-width {
    width: 14%;
}