.loginHistoryWidget-container {
    padding-bottom: 5px;
}

.loginHistoryWidget-container>p {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.topWidget-loginHistory-card {
    height: 86px;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
}

.topWidget-loginHistory-card .parent-card {
    width: 50%;
    height: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 38px;
}

.parent-card .tot_login, .parent-card .tot_logouts, .parent-card .fail_login, .parent-card .success_login {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.parent-card .login-top-widget {
    background: #FFF9E3;
}

.parent-card .tot_login {
    background: #FFF9E3;
}

.parent-card .tot_logouts {
    background: #FFE3FE;
}

.parent-card .fail_login {
    background: #FAE8E8;
}

.parent-card .success_login {
    background: #E2F8E4;
}

.parent-card>div:nth-child(1) img {
    margin: auto;
    width: 12px;
    height: 12px;
}

.parent-card>div:nth-child(2) {
    line-height: 0px;
}

.card-login-detaild>p:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #45464E;
}

.card-login-detaild>p:nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #6E7079;
    letter-spacing: 1px;
    width: 150%;
}

.card-login-detaild>p:nth-child(2) {
    cursor: pointer;
    color: #4c3aff;
}