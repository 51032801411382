.group-actions-tab-actions-popup .MuiDialogContent-root {
    width: 400px;
    height: auto;
}

.group-actions-tab-actions-popup .MuiDialogContent-root>div {
    display: block
}

.group-actions-tab-actions-popup .name-input-default-config>span {
    width: 40%;
}

.group-actions-tab-actions-popup .name-input-default-config>div {
    width: 60%;
}

.group-actions-tab-actions-popup .name-input-default-config .MuiAutocomplete-root {
    width: 100%;
}

.group-actions-tab-actions-popup .name-input-default-config>span,
.group-actions-tab-actions-popup .name-input-default-config .create-config,
.group-actions-tab-actions-popup .name-input-default-config .name-config-default,
.group-actions-tab-actions-popup .name-input-default-config .config-radio-button {
    margin-top: 0px;
}

.group-actions-tab-actions-popup .name-input-default-config {
    display: flex;
    align-items: center;
    margin-top: 15px;
    height: 30px;
}

.group-actions-tab-actions-popup .dialog-actions {
    margin-top: 20px;
}

.group-actions-tab-actions-popup .dialog-actions button {
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

.group-actions-tab-actions-popup .dialog-actions .apply {
    margin-left: 10px;
}

.group-actions-tab-actions-popup .auto-config-textField {
    margin: 0px;
}

.group-actions-tab-actions-popup .config-radio-button input {
    margin-left: 10px;
}

.group-actions-tab-actions-popup .group-name {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}