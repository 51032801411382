.ant-switch.MuiSwitch-root {
    width: 28px;
    height: 16px;
    padding: 0;
    display: 'flex';
}

.ant-switch:active .MuiSwitch-thumb {
    width: 15px;
}

.ant-switch:active .MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(9px);
    -moz-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
}

.ant-switch .MuiSwitch-switchBase {
    padding: 2px;
}

.ant-switch .MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    color: #fff;
}

.ant-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: #2F4B82;
}

.ant-switch .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
    -webkit-transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ant-switch .MuiSwitch-track {
    border-radius: 12px;
    opacity: 1;
    background-color: rgba(0, 0, 0, .25);
    box-sizing: border-box;
}