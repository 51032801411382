.createBtn {
    width: 150px;
    height: 38px;
}

.ParentContainer .UpdateBtn {
    margin-right: 30px;
    width: 100px;
    height: 38px;
    background: #2860B4 !important;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #C7DAEB;
}

.ParentContainer .syncBtn {
    margin-right: 30px;
    width: 100px;
    height: 38px;
    background: #2860B4 !important;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #C7DAEB;
}

.createParentContainer .CreateBtn, .createParentContainer .CreateBtn-cursor-not-allowed{
    margin-right: 30px;
    width: 100px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid #C7DAEB;
}
.createParentContainer .CreateBtn,.createParentContainer .CreateBtn:hover {
    background: #2860B4;
    color: #ffffff;
}
.createParentContainer .CreateBtn-cursor-not-allowed {
    cursor: not-allowed;
    color: #666666;
}
.ParentContainer>div:nth-child(1) {
    display: flex;
    gap: 10px;
}

.ParentContainer>div:nth-child(3) {
    display: flex;
    gap: 10px;
}

.autoCompleteDiv {
    width: 250px;
}

.radioButtonClick {
    margin-top: 3px;
    width: 150px;
    display: flex;
    gap: 2px;
}

.servingDevice {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2860b4;
    font-weight: 400;
}

.update-syncBtn {
    margin-left: 350px;
}

.createParentContainer>div:nth-child(1) {
    display: flex;
    gap: 10px;
}

.createParentContainer>div:nth-child(3) {
    display: flex;
    gap: 10px;
}
.ha-logs{
    margin-top: 10px;
    overflow: auto;
}
.ha-logs th{
    border-top: 1px solid rgba(224, 224, 224, 1);
    background-color: #f5f5f5;
}
.ha-logs .ha-logs-header-metadata {
    text-align: center;
}
.ha-logs th, .ha-logs td {
    border-left: 1px solid rgba(224, 224, 224, 1);
}
.ha-logs-table-div {
    background-color: #ffffff;
}
.ha-logs-filter-div{
    margin-top: 20px;
    display: flex;
}
.ha-logs-filter-div .MuiFormControl-root{
    margin-left: 0px;
    width: 20%;
}
.ha-logs-filter-div .empty {
    width: 5%;
}
.ha-logs-filter-div .empty, .ha-logs-filter-div .filter-button-div {
   margin-top: 8.9px;
}
.ha-logs-filter-div .filter-button-div button{
    background-color: #ffffff;
}
.ha-log-filters-pop-up {
    margin-left: 9.7%;
    display: flex;
    justify-content: flex-start;
    margin-top: 8.5%;
}
.ha-log-filters-pop-up .dialog_title2{
    padding: 10px 10px 0px 10px;
}
.ha-log-filters-pop-up ul, .ha-log-filters-pop-up .expand-summary{
    padding-top: 0px;
}
.ha-logs-filter-div .filter-button-div button{
    border: 1px solid rgba(20, 99, 172, 0.24);
    display: flex;
    justify-content: center;
}
.ha-logs-filter-div .filter-button-div button img {
    width: 16px;
    height: 16px;
    padding: 5px;
}
.cursor-not-allowed{
    cursor: not-allowed;
}

.audit-container-data .HA-pagination {
    margin-bottom: 0px;
}

.ha-main-container {
    padding: 15px 22px;
}