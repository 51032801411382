.orgDropSelection {
    width: 300px;
    position: fixed;
    max-width: 300px;
}

.overlay-topology {
    background: white !important;
}

.colorblackOverride {
    color: black !important;
}

.left10 {
    margin-left: 10px;
}

.overlay-topology button {
    min-width: 0px !important;
}

.topologyTopAppsMT {
    margin-top: 5px;
}

.topologyTopApps {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 8px;
    margin-top: 8px;
    gap: 2px; /* Space between images */
    flex-wrap: wrap; /* Wrap items if needed */
    align-items: center; /* Align vertically */
}

.topologyTopApps img {
    width: 15px;  /* Set fixed width */
    height: 15px; /* Set fixed height */
    object-fit: contain; /* Ensure the full image fits */
}


.mb5px {
    margin-bottom: 5px;
}

.boldText {
    font-weight: 400;
}

.mt5px {
    margin-top: 10px;
}

.analyticsIc {
    width: 29px;
    height: 29px;
}