.ReportPagesContainer .alertsContainerGlobal {
    height: calc(100vh - 170px);
    overflow-y: auto;
}

.Alerts-TabsContainer {
    /* margin: 0px 15px 0px 25px; */
}

.Alerts-NavigationBtns {
    width: 100%;
    height: 48px;
    display: flex;
    margin-top: 35px;
    background: white;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Alerts-navbarBtns {
    margin-left: 20px;
    padding-top: 15px;
}

.Alerts-NavigationBtns .usage-buttonCssFleet {
    padding-top: 10px;
    border-radius: 0px;
    text-transform: none;
    border-bottom:  2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .simAnalysis-buttonCssFleet {
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .dataUsage-buttonCssFleet {

    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .graphicalDataUsage-buttonCssFleet {
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #2860B4;
    color: #2F4B82;
}

.Alerts-NavigationBtns .inActiveUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.Alerts-NavigationBtns .inActiveSimAna-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.Alerts-NavigationBtns .inActiveDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    width: 100px;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}

.Alerts-NavigationBtns .inActiveGrapDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: none;
}
