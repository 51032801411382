body {
    overflow-y: hidden;
}

ul {
    list-style-type: none;
    padding: 2px;
  }

.simAnalysisHeader{
display: flex;
align-items: center;
width: 60%;
justify-content: space-around;
}

.deviceId{
    font-size: 14px;
}

.hide-overlap {
    z-index: 1;
}

.searchDevice{
    position: absolute;
    right: 12px;
    font-size: 12px;
}

.fontWeightBold{
    font-weight: bold !important;
}

.tableHeaderFleetVesselData .MuiTableCell-root.provider-padding{
    padding-left: 50px !important;
} 

.simStatusIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.marg5 {
    margin-left: 5px;
}

.tooltipBr {
    display: flex;
    flex-direction: column;
}

.fleetRowDataDetails .noLeftPadding {
    padding-left: 0px;
    padding-right: 30px;
}

.alertsTable-tableHead  .login-tableRow .align-left {
    text-align: left;
}

.alertsTable-tableBody .pl-15 {
    padding-left: 15px;
}

.fleetRowDataDetails .marg-right30 {
    margin-right: 30px;
}

.tableBody {
    z-index: 1;
  }
  
.tableHead {
    position: sticky;
    top: 0;
    z-index: 999;
}

.noRecords {
    text-align: center;
    padding: 1rem 0;
    font-size: 15px;
    font-weight: 400;
    font-style: italic;
    color: black;
}

._provider {
    color: red;
  }

.filterAlertCss .css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 0 0 10px;
}

.filterAlertCss .css-1lambp5 .MuiAutocomplete-inputRoot {
    padding: 0 0 0 10px;
}

.bodyScroll .Form {
    margin-top: -15px;
}

/* Cellular Chart */

.Form .reports-cellular-chart {
    margin-top: -10px;
    margin-bottom: 10px;
}

.reportsHeader .cellular-lte-usage {
    width: 100px;
    margin-left: 25px;
    color: #2860b4;
    font-size: 24px;
    font-weight: 700;
}

.simAnalysisScroll {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
}

.noWrap {
    text-wrap: nowrap;
}

.cellularTableHeight {
    height: 72vh;
    overflow-y: auto;
}

.cellularData-tabelRow .cellularTable-tableCell {
    font-size: 12px;
    color: #45464E;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 0;
    font-weight: 500;
}

.cellular-tableRow .cellular-fleetDataTable {
    color: #8B8D97;
    background: #F5F5F5;
    border: 1px solid #EBEBEB;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    -webkit-font-feature-settings: "smcp";
    font-feature-settings: "smcp";
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    width: 10%;
    padding: 0;
}

.providerAlign {
    text-align: left;
    margin-left: 5px;
}

.menuScroll .cellular-table {
    height: calc(100vh - 300px);
}

.Form .cellular-charts {
    height: calc(100vh - 300px);
}

.MuiTextField-root.input-box {
    margin: 8px; 
    width: 100%; 
    max-width: 300px; 
    margin-left: 10px;
}

.sim-justyfy-space-between .sim-anal-csv {
    align-self: center;
    margin-left: auto;
}

.Cellular-Form .cellular-charts .cellular-no-data {
    width: 99%;
    height: calc(100vh - 327px);
}

.cellularSearchbarPadding {
    padding-left: 16px;
}