.stateHistoryDialog{
    position: absolute;
    width: 724px;
    height: 540px;
    background: #FFFFFF;
    border-radius: 10px;    
}

.stateHistoryDialogTitleDiv{
    border-bottom: 1px solid #EBEBEB;
}

.stateHistoryDialogTitle{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #45464E;
}

.stateHistoryDialogContentDiv{
    margin-top: 10px;
}

.stateHistoryDialogCrossIcon{
    position: absolute;
    width: 14px;
    height: 14px;
    left:94%;
    cursor: pointer;
}