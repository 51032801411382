.iconColor {
  width: 30px;
  cursor: pointer;
}

.iconColorDisable {
  width: 30px;
  cursor: not-allowed !important;
}

.MuiDialog-paperWidthSm {
  height: max-content;
}

.paginationTable {
  margin-right: auto;
  margin-left: 10px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
