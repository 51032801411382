#konnectDownloadLinks { 
    margin-left: 15px;
    padding-right: 25px;
}
#konnectDownloadLinks .download-platform {
    margin-top: 15px;
}
#konnectDownloadLinks ul {
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    text-align: center;
    margin: 0;
    width: 100%;
}
#konnectDownloadLinks ul  li{
    width: 15.5%;
}
#konnectDownloadLinks a {
    display: block;
    width: 100%;
    padding-top: 1em;
    color: #026e00;
    background: #eaf5e9;
    text-decoration: none;   
}
#konnectDownloadLinks a:hover, #konnectDownloadLinks a:hover .versionNumber {
    color: #fff;
    background-color: #43853d;
    border-radius: 5px;
}
#konnectDownloadLinks a:hover .download-logo {
    fill: #fff;
}
#konnectDownloadLinks .download-logo {
    display: block;
    margin: 0 auto;
    fill: #43853d;
}
#konnectDownloadLinks .versionNumber {
    padding-bottom: 10px;
    color: #999;
    font-size: 0.7rem;
}

.margTop70 {
    margin-top: 70px;
}