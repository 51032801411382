.usage-details-pie-perSite .captive-pie-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 87%;
}

.charts-container-captive-dashboard .justify-content-tabs {
   justify-content: space-between;
   margin: 5px 15px 15px 0px;
}

.charts-container-captive-dashboard .usage-selectedTabs .usageActiveAppClass, .charts-container-captive-dashboard .usage-selectedTabs .usageInactiveAppClass {
    width: auto;
    padding: 0;
    margin-left: 15px;
}

.charts-container-captive-dashboard .usage-selectedTabs .usageInactiveAppClass {
    border-bottom: 2px solid #fff;
}

.usage-selectedTabs #width-45px {
    min-width: 45px;
}

#captive-addBoxShadow:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

#captive-removeBoxShadow:hover {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.captive-expired-text {
    color: #814b4d;
    padding: 3px 7px;
    background: #ffe5e5;
    border-radius: 25px;
    margin-left: 2px;
}

.login-tableRow .width-loggen-in {
    width: 5%;
}

.captive-line-chart-title {
    color: rgba(69, 70, 78, 1);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
    margin-right: 15px;
}

.captive-sites-bar-chart .highcharts-root {
    margin-top: 0px;
}

.chart-view-icons-enable {
    cursor: pointer;
}

.captive-bar-chart-pagination .MuiButtonBase-root {
    border-radius: 8px;
    width: 19px;
    height: 24px;
    margin-left: 5px;
    color: #6e7079;
}

.captive-bar-chart-pagination .MuiBox-root {
    margin-left: 0;
}

.captive-bar-chart-pagination .MuiButtonBase-root:hover {
    background-color: none;
}

.captive-plans-line-icon {
    color: rgba(228, 228, 228, 1);
    margin: 0px 10px;
}

.charts-container-captive-dashboard .usage-trend {
    margin-left: 0;
}

.charts-container-captive-dashboard .site-line-chart-title-text {
    margin-top: 15px;
    margin-bottom: 25px;
}

.captive-dashboard-NewSummary .captive-dashboard-filters-container {
    margin: 10px 10px 0px 20px;
}

.captive-filters-each-field {
    padding: 15px;
    border-bottom: 1px solid #EBEBEB;
}

.captive-filters-each-field .rc--filter_autoComplete-input {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 12px;
}

.unit-field-CD .MuiInputBase-root {
    width: 85px;
}

.login-tableRow .width-usage-sub-th {
    width: 8%;
    text-align: left;
}

.login-tableRow .width-topups-sub-th {
    width: 6%;
    text-align: left;
}

.login-tableRow .width-sessions-last-activity {
    width: 16%;
}

.captive-sessions-popup-container .sessions-table-popup, .sessions-table-popup .MuiDialog-paperWidthMd  {
    position: fixed;
    top: 50px;
    height: auto;
    overflow: hidden;
}

.config-container .captiveSessions-tableContainer {
    max-height: calc(100vh - 340px);
    overflow: auto;
    margin-bottom: 0px;
}

.siteCharts__filters-date-time-picker .date-range-captive-filters {
    width: 240px;
    margin-top: 10px;
}

.captive-dashboard-filters-container .captive-reports-filters-popup-conatiner .MuiPaper-root {
    position: fixed;
    top: 103px;
}

.login-tableRow .events-width-0 {
    width: 0%;
}

.Table-Cell-Opacity .device-type-padding-left-0 {
    padding-left: 0px;
}

.device-type-padding-left-0+.MuiButtonBase-root {
    padding-top: 0px;
    padding-bottom: 0px;
}

.Table-Row-Opacity .device-type-metadata {
    display: flex;
    align-items: center;
}

.font-size-10px {
    font-size: 10px;
}

.dash-filter-pop .clearApplyFilters .usage-applyFilterBtn-disable {
    color: #264c86;
    background-color: #e8f0fd;
    border: 1px solid #676984;
    cursor: auto;
    height: 30px;
    width: 100px;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    text-transform: none;
    margin-left: auto;
}

.card-widget .font-size-12px {
    font-size: 12px;
}

.login-tableRow .text-align-left {
    text-align: left;
}

.Table-Row-Opacity .whiteSpace-nowrap {
    white-space: nowrap;
}

.login-tableRow .os-width-7per {
    width: 7%;
}

.captive-dashboard-chips-container {
    width: calc(100vw - 340px);
    overflow-x: auto;
    padding-bottom: 5px;
}

.captive-dashboard-NewSummary #captive-dashboard-contents {
    height: calc(100vh - 205px);
    overflow-y: auto;
}

.charts-container-captive-dashboard .height-24px {
    height: 24px;
}

.charts-container-captive-dashboard .height-24px .margin-right-10 {
    margin-right: 15px;
    margin-left: 15px;
}

#captive-histogram-filter-btn {
    position: absolute;
    bottom: 25px;
    right: 25px;
    background-color: #244C84;
}

.Table-Row-Opacity .topUpQuotaList {
    max-width: 105px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}