.layers-title-close-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 15px;
    border-bottom: 1px solid #EBEBEB ;
}

.layers-content-container{
    padding-left: 15px;
}

.layers-content-divider {
    border: none !important;
    border-top: 1px solid white !important;
    margin: 15px 15px 0px 15px;
}

.layers-title-close-icon span {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    color: white;
    opacity: 0.7;
}

.layers-title-close-icon img {
    border: 1px solid white;
    background: white;
    padding: 2px;
    width: 9.33px;
    height: 8.78px;
    color: white;
    cursor: pointer;
    opacity: 0.7;
}

.each-item-source {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    margin-bottom: 10px;
    align-items: center;
    gap: 5px;
}

.each-content-title {
    color: white;
    opacity: 0.7;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px 5px 0px;
    display: flex;
}

.each-content-child {
    display: flex;
    gap: 3px;
    align-items: center;
}

.each-content-child .each-content-radio-btn {
    color: #1B51A7;
    width: 13.33px;
    cursor: pointer;
}

.each-content-child .location-eye-icons {
    width: 20px;
    height: 20px;
    color: white;
    opacity: 0.7;
    cursor: pointer;
}

.each-content-child span {
    color: white;
    opacity: 0.7;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
}

.each-item-source .MuiAutocomplete-inputRoot {
    height: 35px;
    border: 1px solid #eae4e4;
}

.each-item-source .MuiInputLabel-outlined {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    color: #BFBFBF;
}

.heat-maps-title-div {
    display: flex;
    align-items: center;
    gap: 3px;
}

.heat-maps-title-div .location-eye-icons {
    width: 20px;
    height: 20px;
    color: white;
    opacity: 0.7;
    padding-top: 5px;
    cursor: pointer;
}

.layers-content-container .title-location-maps {
    margin-bottom: 8px;
}

.each-item-source .hide-starlink-dd {
    visibility: hidden;
}

#combo-box-demo-map {
    color: white;
    opacity: 0.7;
}

.each-item-source .MuiAutocomplete-popupIndicator {
    color: white;
}

.marginTop10 {
    margin-top: 10px;
}

#maps-layers-new-container::-webkit-scrollbar {
    width: 8px;
}

#maps-layers-new-container::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    border-radius: 50px !important;
}

#maps-layers-new-container::-webkit-scrollbar-thumb:hover {
    background-color: #fff !important;
}

#maps-layers-new-container::-webkit-scrollbar-track {
    background-color: #333;
}