.threat-dashboard-NewSummary .padding-l-r {
    padding-left: 12px;
    padding-right: 7px;
  }

  .threat-dashboard-NewSummary.MuiGrid-root {
    /* margin-left: 88px; */
    width: 100%;
    overflow-y: hidden;
    max-height: calc(100vh - 79px);
  }

  .threat-dashboard-NewSummary {
    width: inherit;
  }

  .siteNameThreat {
    font-weight: 400;
    font-size: 12px;
    padding-right: 4px;
    font-family: Inter;
    line-height: 14.52px;
    color: #6E7079;
  }

  .cardHeaderTile_NewSummary .siteNameInThreatCard {
    margin-left: auto;
  }
  

  .usage-details-pie-perSite.wan-type-pie.threat-severity-pie {
    margin-left: 60px;
  }

  .elipseThreat {
    white-space: nowrap;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseAllThreats {
    white-space: nowrap;
    max-width: 104px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseTop10Threats {
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseThreatKontrol {
    white-space: nowrap;
    max-width: 112px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .elipseThreatKontrolclasses {
    white-space: nowrap;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .elipseThreatKontrolclass {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .threatKontrolIconsAlign {
    display:flex;
  }

  .tableBody .threatKontrolOperations {
    display: flex;
    align-items: center;
  }

  .tableBody .threatTextAlignCenter {
    text-align: center;
  }

  .threatchipGap {
    gap: 10px;
  }

  .new-summary-usage-tr .addThreatPadding {
    padding-left: 50px;
  }

  .widthReduce {
    width: 5% !important;
  }

  .ThreatChips {
    margin-left: 20px;
    margin-top: 10px;
  }

  .topThreatsFilter {
    display: flex;
    margin-left: 21px;
  }

  .new-summary-Threat-container {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }

#threatsKontrolButton {
  height: 32px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #FFFFFF;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  gap: 8px;
  background-color: #244C84;
}

.threatKontrolDialogbox .MuiPaper-root {
  width: calc(115% - 194px);
  max-height: calc(90% - 70px);
  top: 60px;
  position:fixed;
  max-width: 1059px;
}

.threatKontrolDeletebox .MuiPaper-root {
  width: 400px;
  height: 200px;
}

.displayFlex {
  display: flex;
  align-items: center;
}

.displayFlex .suppressCheckPaddingReset {
  padding: 0px;
}
.includeSupressionCheckbox .MuiCheckbox-root.Mui-checked {
  color: #244C84;
}
.threatDeleteButtonDiv {
  display: flex;
  gap: 27px;
  align-items: center;
}

#threatKontrolProceedButton {
  width: 165px;
  height: 32px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #FFFFFF;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  gap: 8px;
  background-color: #244C84;
}

#threatKontrolBackButton {
  width: 165px;
  height: 32px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #244C84;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  grid-gap: 8px;
  gap: 8px;
  background-color: #FFFFFF;
}

.threatKontrolMargin {
  margin: 21.5px;
}

.threatKontrolNameMargin {
  margin: 21.5px 21.5px 0px 21.5px;
}

.threatKontrolHeading {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #45464E;
}

.threatKontrolClose {
  margin-left: auto;
  cursor: pointer;
  color: #45464E;
}

.threatKontrolCloseIcon {
  height: 14px;
  width: 14px;
}

.threatKontrolDivider {
  border: 1px solid #EBEBEB;
}

.enableSupression {
  height: 26px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: #45464E;
}

.threatKontrolMargin .threatKontrolSubheading {
  margin-bottom: 12px;
  height: 26px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #45464E;
}

.threadKontrolhr {
  border: 1px solid #EBEBEB;
  width: 94%;
}

.threatEmailSelect .MuiInputLabel-root {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #45464E;
}

#threatKontrolAddAction {
  width: 117px;
  height: 32px;
  color: #2F4B82;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  text-transform: none;
  padding: 0px;
  align-items: center;
  background-color: white;
  border: 1px solid #244C84;
  border-radius: 4px;
}

#addActionIcon {
  color: #2F4B82;
  background-color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.threatKontrolMargin .MuiCheckbox-root {
  padding: 0px;
}

.threatKontrolMargin .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

#threatKontrolSaveButton {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #FFFFFF;
  background: #1B51A7;
  width: 64px;
  height: 29px;
  text-transform: none;
  padding: 0px;
}

#threatKontrolgreySaveButton {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  background: #ebebeb;
  color: #1B51A7;
  width: 64px;
  height: 29px;
  text-transform: none;
  padding: 0px;
}

#threatKontrolCancelButton {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #FFFFFF;
  background: #1B51A7;
  width: 64px;
  height: 29px;
  margin-left: auto;
  text-transform: none;
  align-items: center;
  margin-right: 24px;
}

.threatKontrolNameSelector .MuiInputBase-root {
  width: 500px;
  height: 40px;
}
.threatNameSelector .MuiInputBase-root {
  width: 210px;
  height: 40px;
}

.severitySelector .MuiInputBase-root {
  width: 136px;
  height: 40px;
}

.classSelector .MuiInputBase-root {
  width: 190px;
  height: 40px;
}

.measSelector .MuiInputBase-root {
  width: 139px;
  height: 40px;
}

.countSelector .MuiInputBase-root {
  width: 92px;
  height: 40px;
}

.actionSelector .MuiInputBase-root {
  width: 135px;
  height: 40px;
}

.editthreatKontrolNameSelector .MuiInputBase-root {
  width: 100px;
  height: 40px;
}
.editthreatNameSelector .MuiInputBase-root {
  width: 221px;
  height: 40px;
}

.editseveritySelector .MuiInputBase-root {
  width: 130px;
  height: 40px;
}

.editclassSelector .MuiInputBase-root {
  width: 200px;
  height: 40px;
}

.editmeasSelector .MuiInputBase-root {
  width: 136px;
  height: 40px;
}

.editcountSelector .MuiInputBase-root {
  width: 103.5px;
  height: 40px;
}

.editactionSelector .MuiInputBase-root {
  width: 153px;
  height: 40px;
}

.threatSourceInfo {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 16px;
}
.threatCardsOpenIconMargin {
  margin-right: 30px;
}

.threatkontrolEmailGap {
  height: 12px;
}

.threatkontrolRuleCheckbox {
  height: 18px;
  display: flex;
  align-items: center;
}
.threatAction .threatActionremoveIcon {
  width: 20px;
  height: 20px;
  color: #A6A6A6;
  cursor: pointer;
}
.threatkontrolSelectorGap {
  display: flex;
  gap: 16px;
}

.threatAction {
  display: flex;
  align-items: center;
  gap: 14.5px;
}

.threatAction .MuiInputLabel-animated {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #45464E;
}
#severity {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #45464E;
}

.threatKontrolMargin .threatKontrolCheckbocName {
  display: inline;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.threatKontrolMargin .MuiCheckbox-root.Mui-checked {
  color: #244C84;
}

.threatKontrolContainer {
  display: flex;
}
.threatKontrolContainer .marg-left-auto {
  margin-left: auto;
}