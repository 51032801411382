.login-history-bar-chart-comp>div {
    height: 300px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    background: #fff;
    padding-top: 5px;
}

.login-history-bar-chart-comp .alignTopbarItemsCenter {
    height: 64px;
}

.login-history-bar-chart-comp>p {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.login-history-bar-chart-comp .login-legends {
    width: 45%;
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.login-history-bar-chart-comp .resetZoom-usage {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
}

.login-history-bar-chart-comp .login-resetZoom {
    height: 40px;
}