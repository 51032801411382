
.desc:hover{
    background-color: #f1f1f1;
}

.errorspan{
    font-size: 15px;
    font-style: italic;
    padding: 5px 10px;
    color: red;
}

.font14{
    font-size: 14px !important;
}

.noteDialog{
    border-radius: '13px' ; 
    margin-top: "-100px";
    width:'400px';
}

.flex{
    display: flex;
}

.justify-center{
    justify-content: center;
}

.notePadding{
    padding: 0px 20px 20px 20px;
    color: #273050;
}

.width-80{
    width: 80%;
}

.noteGrid{
    padding: 2rem 52px;
    height: calc(100vh - 185px);
    overflow-y: auto;
}

.notesHistory {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    border-radius: 10px;
    width: 30%;
}

.textUpperCase{
    text-transform: uppercase;
}

.fontBold{
    font-weight: bold;
}

.notesHistoryForm{
    color: #2860B4;
    font-size: 14px !important;
}

.sectionStyle{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-bottom: 2px solid #2860b4;
    transition: 0.2s all ease-in-out;
    height: 30px;
    width: 70%;
    cursor: pointer;
}

.subjectstyle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: white;
    margin: 5px 10px;
    border: 5px;
}

.noteGrid .no-notes-div-per-site {
    height: 45px;
    border: 1px solid rgb(199, 218, 235);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
}