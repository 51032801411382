.tkt_search, .tkt_creator, .tkt_assignee, .tkt_more_filters,.tkt_new_ticket{
    padding: 8px 0px 8px 0px;
}

.verticalLineImageParentDiv img{
    padding-left: 12px; /*same as  padding-left of .tkt_creator*/
}
.tkt_search .searchBox,
.tkt_creator .creatorSelectBox,
.tkt_assignee .assigneeSelectorBox,
.tkt_new_ticket .tkt_new_ticket_button {
    width: 90%;
}
.tkt_more_filters .tkt_moreFilters_button{
    width: 100%;
}
.ticket-create-button{
    max-width: 159.95px;
    width: 100%;
    height: 37px;

    background-color: #264C86 !important;
    border-radius: 5px!important;
    color: #ffffff!important;
    text-transform: uppercase!important;

    font-style: normal!important;
    font-weight: bold!important;
    font-size: 10px!important;
    line-height: 12px!important;

    text-align: right!important;
    letter-spacing: 0.035em!important;
    margin-right: 25px !important;
}
.searchText, .creatorText, .assigneeText{
    font-family: Roboto;
    font-style: normal  !important;
    font-weight: normal  !important;
    font-size: 12px  !important;
    line-height: 15px  !important;
    color: #676984;
    padding-bottom: 10px;
}
.searchBox, .creatorSelectBox, .assigneeSelectorBox{
    /* width: 254px; */
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CFD0DD;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-family: Roboto;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #AAABC4 !important;
}
.searchBox > fieldset, .creatorSelectBox > fieldset,
.assigneeSelectorBox > fieldset, .creatorMenuItem  fieldset,
.assigneeMenuItem  fieldset {
    border-width: 0px !important;
 }
.verticalLineImageParentDiv{
    align-items: center;
}
.verticalLineImageParentDiv img{
    margin:auto;
}
.moreFilters{
    width: 144px;
    height: 48px;
    background: #AAABC4;
    border-radius: 4px;
}
.creatorMenuItem, .assigneeMenuItem{
    /* width: 254px; */
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.creatorMenuItem:focus,  .creatorMenuItem:hover, .creatorMenuItem:visited, .creatorMenuItem:active,
.assigneeMenuItem:focus,  .assigneeMenuItem:hover, .assigneeMenuItem:visited, .assigneeMenuItem:active
{
    background-color: initial !important;
    border-bottom: 0px !important;
}
.creatorMenuItem  fieldset, .assigneeMenuItem  fieldset  {
    border: 1px solid #CFD0DD !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #AAABC4 !important;
}
.creatorMenuItem, .assigneeMenuItem {
    background: initial !important;
}
.creatorMenuItem span, .assigneeMenuItem span {
    background-color: initial !important;
    border-bottom: 0px !important;
}
.creatorMenuItem > div > input,
.assigneeMenuItem > div > input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #AAABC4;
}
.tkt_moreFilters_button{
    color: #080000 !important; 
    /* width: 144px !important;  */
    top: 5px;
    height: 37px !important;
    background: #ffffff !important;
    border-radius: 5px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 12px !important;
    text-transform: uppercase !important;
    letter-spacing: 0.035em !important;
}
.tkt_new_ticket_button{
    /* width: 210px !important;  */
    height: 40px !important; 
    background: #676984 !important; 
    border-radius: 4px !important; 
    color: #ffffff !important; 
    font-family: Roboto !important;
    font-style: normal !important; 
    font-weight: bold !important; 
    font-size: 14px !important; 
    line-height: 18px !important; 
    text-transform: none !important; 
}
.ticketsPaginationDiv{
    display: flex;
    justify-content: flex-end;
    height: 50px;
    padding: 15px 20px 0px 10px;
}
.ticketsPaginationDiv > div {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
    align-items: center;
    display: flex;
    justify-content: center;
}
.ticketsPaginationDiv + div {
    margin-top: 0px !important;
}
.ticketsTable{
    width: 100% !important;
    border-collapse: separate !important;
    border-spacing: 0 1em !important;
}
.ticketsTableTheadTd{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #CFD0DD !important;
}
.ticketsTableTheadTd span{
    display: flex;
}
.ticketsTableTheadTd .sortingImages{
    margin-left: 10px;
    cursor: pointer;
}
.ticketsTableTheadTd .sortingActive{
    visibility: hidden;
}
.ticketsTableTheadTd .ascendingOrderIcon{
    display: block;
}
.ticketsTableTbodyTr{
    background: #EEF0F7;
    border: 1px solid #EEF0F7 !important;
    border-radius: 4px;
}
.ticketsTableTbodyTd{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-align: left !important;
    padding: 16px !important;
    align-items: center !important;
    color: #676984 !important;
    border: 0px !important;
}
.markAsClosed{
    /* width: 96px !important; */
    height: 32px !important;
    background: #FFFFFF !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 13px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #273050 !important;
    text-transform: capitalize !important;
    letter-spacing: 0.02em !important;
    border: 1px solid rgba(53, 83, 137, 0.35) !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    min-width: 100px !important;
}
.tkt_comments{
    margin-right: 10px;
}
.tkt_arrow{
    float: right;
}
.creatorMenuItem, 
.assigneeMenuItem{
    cursor: initial !important;
}
.creatorMenuItem > div,
.assigneeMenuItem > div{
    width: 100% !important;
}
.creatorMenuItem > div >img, 
.assigneeMenuItem > div >img {
    float: right;
    cursor: pointer;
}
.creatorMenuItem_showAllUsers,
.assigneeMenuItem_showAllUsers{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    text-decoration-line: underline !important;
    color: #676984 !important;
    cursor: pointer !important
}
.creatorMenuItem_actions, 
.assigneeMenuItem_actions {
    display: flex;
}
.creatorMenuItem_clearAllButton,
.creatorMenuItem_okButton,
.assigneeMenuItem_clearAllButton,
.assigneeMenuItem_okButton {
	width: 103px !important;
    height: 36px !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
	text-transform: none !important;
    cursor: pointer;
}
.creatorMenuItem_clearAllButton,
.assigneeMenuItem_clearAllButton {
    border: 1px solid rgba(103, 105, 132, 0.2) !important;
    box-sizing: border-box !important;
	color: #676984 !important;
    margin-right: 16px !important;
}
.creatorMenuItem_okButton,
.assigneeMenuItem_okButton {
    background: #264C86 !important;
    color: #FFFFFF !important;
}
.tkt_show_more_text{
    text-decoration: underline;
}
.ticketsPaginationDiv .prevPage{
    margin-left:10px;
    margin-right:15px;
    transform: rotate(180deg);
}
.ticketsPaginationDiv .prevPageDisabled{
    margin-left:10px;
    margin-right:15px;
}
.ticketsPaginationDiv .nextPageDisabled{
    transform: rotate(180deg);
}

.typo3 {
    padding-left: 7px;
}

@media only screen and (max-height: 400px){
  .FleetDashboard-tickets>div {
    max-height: 150px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .FleetDashboard-tickets>div {
    max-height: 195px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .FleetDashboard-tickets>div {
    max-height: 245px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .FleetDashboard-tickets>div {
    max-height: 270px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .FleetDashboard-tickets>div {
    max-height: 310px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .FleetDashboard-tickets>div {
    max-height: 335px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .FleetDashboard-tickets>div {
      max-height: 440px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .FleetDashboard-tickets>div {
      max-height: 422px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .FleetDashboard-tickets>div {
      max-height: 530px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .FleetDashboard-tickets>div {
      max-height: 580px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .FleetDashboard-tickets>div {
      max-height: 640px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .FleetDashboard-tickets>div {
      max-height: 690px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .FleetDashboard-tickets>div {
      max-height: 740px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .FleetDashboard-tickets>div {
      max-height: 790px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .FleetDashboard-tickets>div {
      max-height: 840px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .FleetDashboard-tickets>div {
      max-height: 870px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .FleetDashboard-tickets>div {
      max-height: 930px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .FleetDashboard-tickets>div {
      max-height: 990px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .FleetDashboard-tickets>div {
      max-height: 1090px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .FleetDashboard-tickets>div {
      max-height: 1600px;
      overflow-y: auto;
    }
  } 

    .wrappingContent {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .font-12 {
      font-size: 12px;
    }

    .markAsClosed.marg-left-20 {
      margin-left: 20px;
    }

    .tickets-main-container {
      width: 100%;
      padding-top: 109px;
    }

    .tickets-main-container .tickets-page-frontView {
      height: calc(100vh - 125px);
      overflow-y: auto;
    }

.MuiTextField-root.input-box-ticket-tbl {
  width: 100%;
  max-width: 300px;
}

.usage-tableBody .loginHistory-tableRow .ticketClick{
  cursor: pointer;
  color: #0000f1;
}

.tickets-csv-btn {
  display: flex;
  padding: 0 10px;
  margin-top: 20px;
  margin-right: 25px;
}

.ticketsTopbar {
  background: #FFFFFF;
  justify-content: space-between;
}

.ticketsTopbar .tkt-searchbar-filter-div {
  margin-left: 15px;
  display: flex;
  max-width: 438px;
}

.ticketFilterButton {
  padding-left: 16px;
}

.createTicketButton {
  text-align: right;
}

.usage-tableBody .ticketsTableRowsPadding td {
  padding: 5px;
  text-align: center;
}