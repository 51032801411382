.LoginHistoryPage-Container {
    padding-right: 10px;
    margin-left: -10px;
    height: 600px;
    width: 100%;
    padding-top: 7px;
}

.LoginHistoryPage-Data {
    overflow-x: hidden;
    padding-right: 10px;
}

.loginHistory-filters-comp {
    margin-top: 0px;
    margin-bottom: 10px;
}

.rc--filter_menu_container_login .MuiPaper-root {
    width: 275px !important;
}

.accountsActivity-Filter {
    display: flex;
    gap: 10px;
  }