.close-btn-ht-wd {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.rule-name-text-field .MuiInputBase-root .MuiInputBase-input{
    height: 10px;
}

.rule_name_error_text {
    position: relative;
    top: 25px;
}