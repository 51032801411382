.MuiDialogContent-root.apply-content-filter-dialog-content {
    padding: 20px;
    border: none;
}

.apply-cf-dropdown{
    margin-right:5px
}

.apply-traffic-policy-dialog-actions{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.traffic-policy-content{
    max-height: 325px;
    overflow-y: scroll;
}

.remove-traffic-policy-icon{
    display: flex;
    justify-content:end;
    align-items:center
}

.traffic-policy-row{
    padding: 5px;
}

.traffic-policy-container{
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
}