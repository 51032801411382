.heading{
    font-size: 20px;
    font-style: "Roborto";
    font-weight: bold;
    margin: 1rem 0;
    text-align: center;

}

.dailySelectBox{
    width: 70%;
    background-color: #ffff;
}

.selBackground{
    background-color: white;
}

.dailyReportsDataHeader{
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Form .innerBox{
    padding: 70px 0px 0px 100px;
}

.dailyReportsTableContainer{
    border-radius: 15px;  
    border-left: 1px solid rgba(20, 99, 172, 0.24);
}

.ml-10{
margin-left: 10px;
}

.dailyReportsTableContainer .dailyReportsTableCell{
font-family:  "Roboto" ;
font-weight: 500;
font-size: 10px;
color: #fff;
background-color: #264C86;
text-transform: uppercase;
padding: 10px;
}

.dataUsageHeader{
    height: 46px;
  }

  .iccidTable{
    font-size: 14px;
    padding: 8px;
    font-weight: bold;
  }

  .iccidTable .vessName {
    color: #264C86;
  }

.tableDiv{
    padding: 10px;
}

.MuiTableCell-root.dailyFleetRowRank {
    font-family: Roboto ;
    font-style: normal ;
    font-weight: 500 ;
    font-size: 12px ;
    line-height: 14px ;
    text-transform: capitalize ;
    letter-spacing: 0 ;
    opacity: 0.75;
    position: relative;
    padding: 10px ;
}

.tableBody {
    z-index: 1;
  }
  
  .tableHead { 
    position: sticky; 
    top: 0;
    z-index: 999;
  }

  @media only screen and (max-height: 650px) {
    .dailyDataUsageScroll {
        max-height: 410px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 650px) and (max-height: 690px) {
    .dailyDataUsageScroll {
        max-height: 420px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 690px) and (max-height: 710px) {
    .dailyDataUsageScroll {
        max-height: 460px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 710px) and (max-height: 740px) {
    .dailyDataUsageScroll {
        max-height: 478px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 740px) and (max-height: 760px) {
    .dailyDataUsageScroll {
        max-height: 505px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 760px) and (max-height: 790px) {
    .dailyDataUsageScroll {
        max-height: 525px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 790px) and (max-height: 820px) {
    .dailyDataUsageScroll {
        max-height: 555px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 820px) and (max-height: 849px) {
    .dailyDataUsageScroll {
        max-height: 590px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 849px) and (max-height: 870px) {
    .dailyDataUsageScroll {
        max-height: 610px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 870px) and (max-height: 899px) {
    .dailyDataUsageScroll {
        max-height: 640px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:899px) and (max-height:950px) {
    .dailyDataUsageScroll {
        max-height: 670px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:950px) and (max-height:970px) {
    .dailyDataUsageScroll {
        max-height: 720px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:970px) and (max-height:999px) {
    .dailyDataUsageScroll {
        max-height: 740px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:999px) and (max-height:1020px) {
    .dailyDataUsageScroll {
        max-height: 770px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1020px) and (max-height:1050px) {
    .dailyDataUsageScroll {
        max-height: 790px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1050px) and (max-height:1080px) {
    .dailyDataUsageScroll {
        max-height: 820px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1080px) and (max-height:1100px) {
    .dailyDataUsageScroll {
        max-height: 850px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1100px) and (max-height:1140px) {
    .dailyDataUsageScroll {
        max-height: 870px;
        overflow-y: auto;
    }
}
@media only screen and (min-height:1140px) and (max-height:1160px) {
    .dailyDataUsageScroll {
        max-height: 910px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1160px) and (max-height:1200px) {
    .dailyDataUsageScroll {
        max-height: 930px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1200px) and (max-height:1230px) {
    .dailyDataUsageScroll {
        max-height: 970px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1230px) and (max-height:1260px) {
    .dailyDataUsageScroll {
        max-height: 995px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1260px) and (max-height:1300px) {
    .dailyDataUsageScroll {
        max-height: 1020px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1300px) and (max-height:1330px) {
    .dailyDataUsageScroll {
        max-height: 1070px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1330px) and (max-height:1360px) {
    .dailyDataUsageScroll {
        max-height: 1100px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1360px) and (max-height:1400px) {
    .dailyDataUsageScroll {
        max-height: 1130px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1400px) and (max-height:1920px) {
    .dailyDataUsageScroll {
        max-height: 1165px;
        overflow-y: auto;
    }
}