
.srvc_mgmt_table .tableThead, .srvc_mgmt_table .tableTbodyTd{
    font-weight: 500 !important;
    font-style: normal !important;
    /* font-weight: bold !important; */
    font-size: 12px !important;
    line-height: 14px !important;
    /* text-align: left !important; */
    /* padding: 14px 14px !important; */
    color: #273050 !important;
    opacity: 0.75;
}
.srvc_mgmt_table .tableThead{
    color: #CFD0DD !important;
    border-bottom: 0.5px solid #676984 !important;
}
.srvc_mgmt_table .tableTbodyTd{
    align-items: center !important;
    color: #676984 !important;
}
.srvc_mgmt_table .uppercaseText{
    text-transform: uppercase;
}
.srvc_mgmt_table td[rowspan] {
    /* vertical-align: top; */
    text-align: left;
}
.srvc_mgmt_table .borderBottom{
    border-bottom: 0.5px solid #CFD0DD !important; 
}
.srvc_mgmt_table .removeBorderBottom{
    border-bottom: 0px !important; 
}
.srvc_mgmt_table .plan-type {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    color: #676984;
}
.srvc_mgmt_table .plan-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    color: #676984;
}
.srvc_mgmt_paginationDiv{
    background-color: rgb(250, 249, 252);
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    padding: 14px 20px 0px 0px;
}
.srvc_mgmt_paginationDiv > div{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
    align-items: center;
    display: flex;
    justify-content: center;
}
.srvc_mgmt_paginationDiv + div{
    margin-top: 0px !important;
}
.srvc_mgmt_paginationDiv .prevPage{
    margin-left:10px;
    margin-right:15px;
    transform: rotate(180deg);
}
.srvc_mgmt_paginationDiv .prevPageDisabled{
    margin-left:10px;
    margin-right:15px;
}
.srvc_mgmt_paginationDiv .nextPageDisabled{
    transform: rotate(180deg);
}
.cursorPointer{
    cursor: pointer !important;
}
.srvc_mgmt_table .vessel-name {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;
    color: #273050 !important;
    /* opacity: 0.75 !important; */
}

.tableHeaderFleetVesselData > .tableHeaderBg{
    background-color: #2860b4;
}

.alignCenter {
    text-align: center;
}

@media only screen and (max-height: 400px){
  .serviceManagement-table>div {
    max-height: 195px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .serviceManagement-table>div {
    max-height: 255px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .serviceManagement-table>div {
    max-height: 305px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .serviceManagement-table>div {
    max-height: 355px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .serviceManagement-table>div {
    max-height: 405px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .serviceManagement-table>div {
    max-height: 455px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .serviceManagement-table>div {
      max-height: 500px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .serviceManagement-table>div {
      max-height: 540px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .serviceManagement-table>div {
      max-height: 590px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .serviceManagement-table>div {
      max-height: 640px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .serviceManagement-table>div {
      max-height: 700px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .serviceManagement-table>div {
      max-height: 750px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .serviceManagement-table>div {
      max-height: 800px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .serviceManagement-table>div {
      max-height: 850px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .serviceManagement-table>div {
      max-height: 900px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .serviceManagement-table>div {
      max-height: 930px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .serviceManagement-table>div {
      max-height: 990px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .serviceManagement-table>div {
      max-height: 1050px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .serviceManagement-table>div {
      max-height: 1140px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .serviceManagement-table>div {
      max-height: 1600px;
      overflow-y: auto;
    }
  }