.alertslabel {
    margin-right: 20px;
    font-size: 12px;
}

.alertStatelabel {
    margin-left: 20px;
    font-size: 12px;
}

.searchBar {
    width: 240px;
}

.alertsFilterContainer .alertsFilterContainerBox {
    display: flex;
    align-items: center;
}

.manual-backup-config-container .alertsFilterContainer .alertsFilterContainerBox {
    display: flex;
    align-items: center;
}

a .alertButton {
    height: 34px;
    background: #2860C8;
}

.AlertsContainer .alertsCount {
    margin:10px 0px;
}

.AlertsContainer{
    padding: 15px 21px;
}

.firingSpan {
    color: #F35656;
}

.tab-conatiner .AlertsContainer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.alert_dashboard_pagination {
    width: 100%;
}

.alert-config-scroll {
    height: calc(100vh - 205px);
    overflow-y: auto;
}