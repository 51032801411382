.Reports-NavigationBtns {
    width: 100%;
    height: 50px;
    display: flex;
    margin-top: 95px;
    /* margin-left: 90px; */
    background: white;
    justify-content: space-between;
}

.Reports-navbarBtns {
    padding-left: 21px;
    padding-top: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.inventory-navbarBtns {
    padding-left: 15px;
    padding-top: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.sw-navbarBtns {
    padding-left: 15px;
    padding-top: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
}

.Reports-NavigationBtns .usage-buttonCssFleet {
    padding-top: 15px;
    border-radius: 0px;
    text-transform: none;
    border-bottom:  2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 4px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .simAnalysis-buttonCssFleet {
    padding-top: 13px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 4px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .dataUsage-buttonCssFleet {
    padding-top: 13px;
    border-radius: 0px;
    text-transform: none;
    border-bottom: 2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 4px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .graphicalDataUsage-buttonCssFleet {
    padding-top: 14px;
    border-radius: 0px;
    text-transform: uppercase;
    border-bottom: 2px solid #1976d2;
    color: #1976d2;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 14px;
    border-radius: 0px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveSimAna-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    width: 100px;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: uppercase;
}

.Reports-NavigationBtns .inActiveGrapDataUsage-buttonCssFleet {
    border-bottom: #ffffff;
    color: #8B8D97;
    padding-top: 16px;
    border-radius: 0px;
    text-transform: uppercase;
}

.ReportPagesContainer {
    background-color: white;
    height:100vh;
}

.ReportPagesContainer>div{
    width: 100%;
}

.ReportPagesContainer>div>div {
    width: 100%;
    padding: 10px 0px 0px 0px;
}

.ReportPagesContainer .firewall-scroll .firewall-scroll {
    padding: 0px;
}

.accountManagementPagination {
    margin-top: 0px;
}

.Reports-TabsContainer {
    margin: 0px 22px;
}

.fw-reports-tabsContainer {
    margin: 0px 14px;
}

.usage-commonLoader {
    position: absolute;
    right: 130px;
    top: 30px;
}

.cellular-sub-tabs {
    display: flex;
    justify-content: center;
    margin: 0px 0px 20px 0px;
}

.cellular-sub-tabs .table-active-tab {
    background: white;
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 1px;
    color: #0E0548;
    background-color: #fff;
    font-weight: 500;
    text-transform: none;
    border-bottom: 2px solid #2860b4;
}

.cellular-sub-tabs .chart-active-tab {
    border-bottom: #fff;
    color: #8b8d97;
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 1px;
    font-weight: 500;
    text-transform: none;
}

.Reports-NavigationBtns .navigation-buttons-reports-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation-buttons-reports-div .helpIcon-reports {
    padding-right: 11px;
    padding-top: 16px;
    cursor: pointer;
    color: #2860B4;
}

.gen-rep-height {
    height: calc(100vh - 170px);
    overflow-y: scroll;
}