.histoInterval .histoIntervalInternal {
    height: 10px;
}

.histoInterval {
    height: 20px;
}

.histoInterval .MuiOutlinedInput-root {
    height: 20px;
}