.group-actions-tab-create-group-popup .MuiDialogContent-root {
    width: 430px;
    height: auto;
    overflow-y: hidden;
}

.group-actions-tab-create-group-popup .MuiDialogContent-root>div {
    /* display: block */
}

.group-actions-tab-create-group-popup .name-input-default-config {
    height: fit-content;
}

.group-actions-tab-create-group-popup .name-input-default-config>span,
.group-actions-tab-create-group-popup .name-input-default-config .create-config,
.group-actions-tab-create-group-popup .name-input-default-config .name-config-default {
    margin-top: 0px;
}

.group-actions-tab-create-group-popup .name-input-default-config>span {
    width: 35%;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.group-actions-tab-create-group-popup .name-input-default-config,
.group-actions-tab-create-group-popup .create-config-table {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.group-actions-tab-create-group-popup .name-input-default-config .create-config {
    max-height: 265px;
    height: auto;
}

.group-actions-tab-create-group-popup .dialog-actions {
    margin-top: 20px;
}

.group-actions-tab-create-group-popup .dialog-actions button {
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

.group-actions-tab-create-group-popup .dialog-actions .create {
    margin-left: 10px;
}

.group-actions-tab-create-group-popup .dialog-actions .create.disable {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.group-actions-tab-create-group-popup .auto-config-textField {
    width: auto;
    margin: 0px;
}

.group-actions-tab-create-group-popup .group_name,
/* .group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root>div>div, */
.group-actions-tab-create-group-popup .select_organization .Form,
.group-actions-tab-create-group-popup .select-organisation-dropdown {
    height: 37px;
    width: 280px;
}

.group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root>div>div {
    padding: 0px;
    min-height: 37px;
    width: 280px;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
}

.group-actions-tab-create-group-popup .select_devices fieldset {
    border: 0px !important;
}

.group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root>div>div>input {
    padding: 7px 14px 7px 14px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
}

.group-actions-tab-create-group-popup .select_devices .MuiAutocomplete-root .search-or-select>div>input {
    padding-left: 10px;
}

.group-actions-tab-create-group-popup .group_name input {
    padding-top: 6px;
    padding-bottom: 6px;
}

.group-actions-tab-create-group-popup .select-organisation-dropdown {
    padding: 6px 14px 6px 14px;
    border-radius: 8px;
}

.group-actions-tab-create-group-popup+#dpDropdownMenu-ou .MuiPaper-root {
    width: 280px;
}