html, body, #root, .App {
  height: 100%;
  width: 100%;
  background-color: white;
}


body {
  margin: 0 !important;
  font-family:  Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif";
}

.header2 {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 25.1px !important;
  color: #676984 !important;
  text-align: left !important;
}

.paginationDiv{
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  /* padding: 14px 20px 0px 0px; */
}
.paginationDiv > div{
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #676984;
  align-items: center;
  display: flex;
  justify-content: center;
}

.prevPage{
  margin-left:10px;
  margin-right:15px;
  transform: rotate(180deg);
}
.prevPageDisabled{
  margin-left:10px;
  margin-right:15px;
}
.nextPageDisabled{
  transform: rotate(180deg);
}

.MuiTableRow-head .font-wt-900 {
  font-weight: 900;
}

.searchbarBox .MuiTextField-root {
  width: 330px;
  max-width: 100%;
}

.topbarWidth .searchbarMaxWidth {
  max-width: 346px;
} 

.topbarWidth .searchbarMaxWidth1 {
  max-width: 330px;
}

.topbarWidth .filterButtonMaxWidth {
  max-width: 92px;
}

.topbarWidth .selectButtonMaxWidth {
  max-width: 200px;
}

.searchbarBox .searchbarInputs {
  padding: 8px;
  height: 32px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8B8D97;
}

.searchbarInputs .MuiOutlinedInput-input {
  padding: 0px;
}

.searchbarIconsPosition {
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.topbarPaddingReset .alignTopbarItemsCenter .MuiGrid-item {
  padding:0px 0px 0px 16px;
}

.topbarMarginReset .MuiGrid-container {
  margin-top: 0px;
  margin-bottom: 0px;
}

.topbarMarginReset .MuiGrid-container .topbarPaddingReset {
  padding: 0px;
}

#filterButton {
  height: 32px;
  width: 76px;
  padding: 8px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #2F4B82;
  border: 1px solid #244C84;
  border-radius: 4px;
  text-transform: none;
  gap: 8px;
  background-color: white;
}

#filterIcon {
  height: 16px;
  width: 16px;
  margin: 0px;
}

#filterButton .MuiButton-startIcon {
  margin: 0px;
}

.sitesTableMargin {
  margin: 0px 10px 0px 10px;
}

.alignTopbarItemsCenter {
  display: flex;
  align-items: center;
  height: 64px;
}

.config-container .reports-login-history .topbarMarginPaddingReset {
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.config-container .trends-usage-table-container .tablePaddingReset {
  padding-top: 0px;
}

.backup-config-container .inventoryCMContainer {
  width: 99%;
  padding: 5px 10px 0px 5px;
  background-color: #fff;
  margin: 0px 0px 0px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.inventoryCMContainer .inventoryCMHeading {
  display: flex;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.topbarWidth .dwn-margin-auto {
  margin-left: auto;
  display: flex;
  justify-content: end;
}