.custom_confirm_dialog .MuiPaper-root{
    width: 403px;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(103 105 132 / 25%);
    border-radius: 8px;
}
.custom_confirm_dialog_title{
    font-weight: 500 !important;
    font-size: 18px !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button{
    /* color: #264C86 !important; */
}
.custom_confirm_dialog_no_button{
    color: #264C86 !important;
}
.custom_confirm_dialog_title,
.custom_confirm_dialog_sub_title,
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-family: Roboto !important;
    font-style: normal !important;
    line-height: 21px  !important;
    align-items: center  !important;
}
.custom_confirm_dialog_sub_title{
    font-size: 13px !important;
    white-space: pre-line;
}
.custom_confirm_dialog_no_button,
.custom_confirm_dialog_yes_button{
    font-weight: 500 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: none !important;
    border: 1px solid #264C86 !important;
}
.custom_confirm_dialog_yes_button{
    background: #264C86 !important;
    color: #fff !important;
}

.custom_confirm_dialog .confirm-pad {
    padding: 10px 10px 10px 23px;
}