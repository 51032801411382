.card-container {
    padding: 8px;
}

.card-widget:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

#removeBoxShadow:hover {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

#addBoxShadow:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.card-widget {
    width: 100%;
    /* height: 65px; */
    border-radius: 10px;
    box-Shadow: none;
}

.flex_grow{
    flex-grow: 1;
}

.new_summary_grid_item{
    display: flex;
    flex-direction: column;
}

.card-box-parent .card-widget_NewSummary {
    /* width: 100%; */
    /* min-height: 118px; */
    border-radius: 10px;
    box-Shadow: none;
    padding: 16px;
    background-color: #F9F9F9;
}

.green-Background {
    background: #75DB92 !important;
}

.cards {
    justify-content: space-between;
    background-color:transparent;
    margin-bottom:5px;
    width: 100%;
    margin-left: -20px;
    padding:16px 0px;
}

.cards_NewSummary {
    justify-content: space-between;
    background-color:white;;
    width: 100%;
    margin-left: -20px;
    padding:16px 0px;
}
.cardContents {
    display: flex;
}

.cardContents {
    width: 100%;
    padding: 5px;
}

.cardActive {
    display: flex;
}


.cardContents_NewSummary {
    display: flex;
    padding-bottom: 11px;
}

.card-widget .cardTitle {
    padding-left: 5px;
    color: #2E1A70;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    padding-top: 5px;
    letter-spacing: 0.175em;
    display: inline-block;
}

.card-widget_NewSummary .cardTitle_NewSummary {
    color: #45464E;
    display: inline-block;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.new_summary_uptime{
    display: flex;
    align-items: center;
    justify-content: center;
}

.new_summary_uptime .new_summary_uptime_value{
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #45464E;
}

.new_summary_uptime .new_summary_uptime_percent{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 8px;
    color: #8B8D97;
}

.new_summary_uptime .new_summary_uptime_typo{
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #45464E;
}

.cardHeader .Availability {
    float: right;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 500;
}

.cardHeaderTile_NewSummary{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.cardHeaderImage_NewSummary{
    height: 28px;
    width: 28px;
    padding-right: 8px;
}

.cardLeftContent_NewSummary{
    display: flex;
    align-items: flex-end;
}

.card-widget  .cardContents {
    padding: 4px;
    justify-content: space-evenly;
    text-align: center;
}

.card-widget  .cardContents.cardNew {
    padding: 5px;
    justify-content: left;
    text-align: left;
}

.card-widget  .contentSubTitle {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
}

.card-widget .content {
    font-size: 25px;
    font-weight: 500;
    line-height: 26px;
}

.card-widget_NewSummary .content_NewSummary {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    color: #45464E;
}

.content_bottom_ns {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #8B8D97;
    margin-left: 10px !important;
}

.excepCardContent {
    padding-top: 8px;
}

.topWidgetInactiveCursor {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
}


@media (min-width: 1280px) {
    .SubHeader .cards .MuiGrid-grid-lg-2 {
        /* max-width: 20% !important; */
        flex-basis: 20% !important;
    }
}

.cardOnlineStatatus_ns{
    font-weight: 400;
    font-size: 12px;
    padding-right: 4px;
    font-family: Inter;
    line-height: 14.52px;
    color: #6E7079;
}

.cardOnlineStatatusWrapper_ns{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.cardOnlineStatatusData_ns{
    /* style={{ fontWeight: '700', fontSize:"12px" }} */
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #45464E;

}

.singleVesselCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Alerts Top Widget */

.flex_grow .alerts-heading-count-div {
    display: flex;
    justify-content: space-between;
}

.cardHeaderTile_NewSummary .alerts-heading-container {
    display: flex;
    align-items: center;
}

.alerts-heading-count-div .alerts-count-widget {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
}

.Alerts-topWidget-Card>span {
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    margin-left: 30px;
}

.flex_grow .Alerts-topWidget-details {
    display: flex;
    justify-content: space-around;
}

.quota_admin_icons {
    padding-left: 7px;
    border-radius: 50%;
    margin-right: 6px;
}

.quota_admin_icons>img {
    height: 21px;
    width: 14px;
    padding-top: 3px;
}

.cursorAuto {
    cursor: auto;
}

.card-widget .advance-dashboard-card-css {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 4px;
    gap: 5px;
}

@media only screen and (max-width: 1260px){
    .card-widget .advance-dashboard-card-css {
      font-size: 8px;
    }
  }

@media only screen and (min-width: 1260px) and (max-width: 1350px){
  .card-widget .advance-dashboard-card-css {
    font-size: 9px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1430px) {
    .card-widget .advance-dashboard-card-css {
        font-size: 10px;
    }
}

@media only screen and (min-width: 1430px) and (max-width: 1520px) {
    .card-widget .advance-dashboard-card-css {
        font-size: 11px;
    }
}

.card-box-parent .new-sum-cards-box-shadow {
    box-shadow: none;
    background-color: #F9F9F9;
}

.cardBorderTwoPXRed {
    border: 2px solid #d62728;
}

.cardBorderTwoPXOrange {
    border: 2px solid #ff7f0e;
}

.cardBorderTwoPXYellow {
    border: 2px solid #ffbf00;
}

.cardBorderTwoPXBlue {
    border: 2px solid #1f77b4;
}

