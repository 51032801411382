.NewSideMenu{

}
 .MuiDrawer-paper {
    margin-top: 49px;
}
.MuiButtonBase-root .MuiListItemIcon-root{
    margin-right: inherit ;
}
.SideNavImg{
    height: 24px;
    width: 24px;
    margin-right: 10px;
}
.sideNavText .MuiListItemText-primary{
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.SideNavDrawer .drawerOpenIcon{
    height: 23px;
    width: 23px;
    position: fixed;
    top:9%;
    left:80px;
    z-index: 1300;
    transform: translate(-50%);
    background: #ffffff;
    box-shadow: 0px 2px 10px #b6b3b3;
    border-radius: 50%;
}

.iconDivNewSideNav {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    height: calc(100vh - 150px);
}

.drawerTopBorder{
    border-top: 1px solid #EBEBEB;
}

.iconDivNewSideNav .listItemSideMenu{
    padding: 0px;
    width: 100%;
}

.navListItems {
    padding: 0px;
    height: 44px;
    justify-content: center;
}

.navIconMargin {
    margin-left: 20px;
    margin-right: 12px;
}

.iconColor-select {
    filter: invert(7%) sepia(96%) saturate(7341%) hue-rotate(209deg) brightness(53%) contrast(135%);
    font-size: "14 px  Semibold";
}

.iconColor-unselect {
    filter: invert(46%) sepia(7%) saturate(448%) hue-rotate(192deg) brightness(92%) contrast(87%);
    font-size: "14 px  Medium";
}

.SideNavDrawer .sidenavPaddingTop {
    padding-top: 16px;
}

.SideNavDrawer .knowledgeCenterPadding {
    padding: 0px;
}
.Hub-new-menu-icon {
    height: 24px;
    width: 24px;
    margin-right: 12px;
}

/* padding:"0px 16px", width:"100%", */