.config-create-content-filter-popup {
  color: #9a9a9a;
  font-style: normal;
  font-size: 14px;
  overflow-y: hidden;
}

.add-custom-domain-text {
  font-size: 14px;
  margin-top: 10px;
}

.create-content-filter-title-name {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #45464e;
}

.create-content-filter-close-btn {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.form-content-filter {
  display: flex;
}

.content-filter-config-details {
  flex: 1;
  padding: 10px;
}

.content-filter-nav-bar {
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.content-filter-nav-bar ul {
  list-style-type: none;
  padding: 0;
}

.content-filter-nav-bar li {
  display: flex;
  height: 20px;
  padding: 16px 0px 16px 24px;
  align-items: center;
  align-self: stretch;
}

.content-filter-nav-bar li:hover {
  background-color: rgba(244, 245, 250, 1);
}

.content-filter-nav-item {
  padding: 10px;
  cursor: pointer;
}

.content-filter-nav-item:hover {
  background: #f0f0f0;
}

.content-filter-nav-item.selected {
  background-color: #F4F5FA;
  font-weight: bold;
}


.MuiDialogContent-root.create-content-filter-dialog-content {
    height: 480px;
    padding: 0px;
    overflow-y: hidden;
}

.MuiGrid-root.create-content-filter-single-form-row {
  height:65px
}

.MuiGrid-root.create-content-filter-single-form-span-center{
  display:flex;
  justify-content: flex-start;
}

.MuiInputBase-input.create-content-filter-comment{
  height:100px
  
}
.MuiDialog-root.config-create-content-filter-popup .MuiDialog-container .MuiPaper-root {
  height: 600px;
  overflow: hidden;
}

.MuiButtonBase-root.content_filter-footer-create-button{
  display: flex;
  padding: 7px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #1B51A7;
}