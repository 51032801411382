.container {
    border: 1px solid black;
    padding: 0.5rem;
    margin: 0.5rem;
}

.metric_alert_configuration--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.create_alert--container .metric_alert_configuration--container {
    margin: 1rem 4rem;
    border-radius: 12px;
}

.metric_alert_configuration--header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #EBEBEB;
}

.metric_alert_configuration--header:hover {
    cursor: pointer;
}

.metric_alert_configuration--header--meta {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.metric_alert_configuration--header--meta--icon {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    color: #2F4B82;
    background: #F4F5FA;
    border-radius: 8px;
}

.metric_alert_configuration--header--meta--title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.metric_alert_configuration--header--meta--title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
}

.metric_alert_configuration--header--meta--subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}

.metric_alert_configuration--header--action_dots {
    display: flex;
    align-items: center;
}

.metric_alert_configuration--body {
    display: flex;
    flex-direction: column;
}

.metric_alert_configuration--body--section {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #EBEBEB;
}

.metric_alert_configuration--body--section.last_section {
    border: none;
}

.metric_alert_configuration--body--section .section_title {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    line-height: 1.1rem;

    color: #45464E;
}

.metric_alert_configuration--body--section .section_input_label {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 0.9rem;

    color: #8B8D97;
}

.metric_alert_configuration--body--section .section_input_label--space_bottom {
    display: block;
    margin-bottom: 0.5rem;
}

.metric_alert_configuration--body--section--grey_box {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;

    background: rgba(244, 245, 250, 0.7);
    border-radius: 8px;
}

.select_duration {
    display: flex;
    background:#FFFFFF;
    border-radius: 4px;
    padding: 0.2rem 1rem;
}

.select_duration .MuiInput-root::before, .custom_select .MuiInput-root::before, .custom_text_field .MuiInput-root::before {
    border-bottom: unset;
}

/* .select_duration .MuiInput-root {
    padding: 0.15rem 0.75rem;
} */

.select_duration .MuiTextField-root .MuiInput-root {
    max-width: 45px;
}

.custom_text_field .MuiTextField-root .MuiInput-root {
    max-width: 65px;
}

.mid_separator {
    border-right: 3px solid #EBEBEB;
    margin: 0.25rem 0.5rem
}

.custom_select {
    display: flex;
    background:#FFFFFF;
    border-radius: 4px;
    padding: 0.2rem 0.25rem;
}

.custom_text_field {
    display: flex;
    background:#FFFFFF;
    border-radius: 4px;
    padding: 0.2rem 0.25rem;
}

.error_handling_subsection {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* Footer */

.metric_alert_configuration--footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-top: 1px solid #EBEBEB;
}

.save_config_button.MuiButton-contained,
.save_config_button.MuiButton-contained:hover {
    background: #2F4B82;
}

/* More Action */

#metric-configuration-more-options .MuiMenuItem-root:hover {
    background: #F4F5FA;
    border-radius: 8px;
}

.metric-configuration-more-options-paper.MuiMenu-paper {
    padding: 0rem 0.5rem;
    width: 10rem;
}