.manual-backup-config-container {
    padding: 0px 5px 0px 5px;
    margin-right: 50px;
}

.manual-backup-config-container .golden-config-default-container {
    padding: 0px 10px 0px 5px;
    margin: 25px 15px 0px 15px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.manual-backup-config-container .golden-config-default-container>div {
    display: block;
}
.manual-backup-config-container .golden-config-default-container div:first-child {
    margin-bottom: 0px;
}

.manual-backup-config-container .title {
    margin: 4px 0 0 0;
}

.manual-backup-config-container .TopUsersConatiner .backup-config-table-container {
    max-height: fit-content;
}

.manual-backup-config-container .golden-config-default-container .TopUsersConatiner {
    margin-top: -10px;
}

.manual-backup-table .manual-backup-status::first-letter {
    text-transform: uppercase;
}

.manual-backup-table .short-text-action-initiated-by {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.config-search-bar-text .config-backup-search-bar {
    margin-top: -23px;
    height: 32px;
}

.config-tableRow .config-left-align {
    text-align: left;
    background: #F5F5F5;
    height: 40px;
    font-family: 'Inter';
    font-style: normal;
}

.config-left-align .default-config-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.usage-tableBody .config-backup-tabe-row td {
    text-align: left;
    padding-left: 20px;
    font-weight: 400;
}

.config-left-align .backup-config-headers {
    white-space: nowrap;
}

.config-pagination .MuiToolbar-root {
    min-height: 40px;
}

.config-backup-search-bar .config-search-icon {
    color: #8B8D97;
}

.config-backup-tabe-row .selected-config {
    cursor: pointer;
    color: #0000ee;
}
.manual-backup-table .actions img {
    cursor: pointer;
    width: auto;
}

.MuiTextField-root.input-box-mn-bkp {
    margin: 8px;
    width: 100%;
    max-width: 245px;
    margin-left: 0px;
    height: 32px;
}