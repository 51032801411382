.Form .usage-cardmain {
    display: flex;
}

.Reprts-CardContainer {
    width: 100%;
    height: 115px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.total-logoDiv {
    display: flex;
    justify-content: space-between;
    height: 50%;
    padding-left: 10px;
}

.reports-totalDetails {
    line-height: 55px;
}

.reports-totalDetails>div:nth-child(1) {
    position: relative;
    height: 21px;
    left: 0px;
    top: 11px;
    color: #6E7079;;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: 1px;
}

.reports-totalDetails>div:nth-child(2) {
    position: relative;
    width: 160px;
    height: 21px;
    left: 0px;
    top: 10px;
    color: #45464E;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    font-family: 'Roboto Mono';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reports-propsLogo {
    position: relative;
    width: 28px;
    height: 28px;
    right: 16.25px;
    top: 17px;
    background: rgba(172, 85, 241, 0.08);
    border-radius: 50%;
    display: flex;
   justify-content: center;
}

.reports-propsLogo-total {
    background: #FFF9E3;
}

.reports-propsLogo-site {  
    background: #F1F4FE;
}

.reports-propsLogo-user {
    background: #F1F9FE;
}

.reports-propsLogo-app {
    background: #FFF3E8;
}

.reports-propsLogo-blocked {
    background: #fef3f3;
}

.reports-propsLogo-total>img, .reports-propsLogo-site>img, .reports-propsLogo-user>img, .reports-propsLogo-app>img, .reports-propsLogo-blocked>img {
    margin: auto;
    width: 13px;
    height: 13px;
}

.commonGBClass {
    position: relative;
    width: 13px;
    height: 13px;
    color: #6E7079;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
}

.reports-up_down-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50%;
    padding-left: 10px;
    margin-top: 15px;
    padding-right: 10px;
}

.upArrow-details {
    display: flex;
}

.upArrow-details>div:nth-child(2) {
    font-style: normal;
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #45464E;
    margin-left: 3px;
    letter-spacing: 1px;
    width: fit-content;
}

.statusTitle_up {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #45464E;
}

.topBlockedUsage {
    margin: 14px 12px 10px 12px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #45464E;
}

.topBlockedUsage>div {
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #6E7079;
    margin-bottom: 2px;
}

.reports-totalDetails .activity-cardFilter {
    color: #0f09ff;
    cursor: pointer;
}

@media only screen and (max-width: 1260px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 6px;
    }
}

@media only screen and (min-width: 1260px) and (max-width: 1310px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 7px;
    }
}

@media only screen and (min-width: 1310px) and (max-width: 1385px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 8px;
    }
}

@media only screen and (min-width: 1385px) and (max-width: 1470px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 9px;
    }
}

@media only screen and (min-width: 1470px) and (max-width: 1550px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 10px;
    }
}

@media only screen and (min-width: 1550px) and (max-width: 1650px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 11px;
    }
}

@media only screen and (min-width: 1650px) and (max-width: 1750px) {
    .upArrow-details>div:nth-child(2) {
        font-size: 12px;
    }
}