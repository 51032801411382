.login-input-border .MuiInput-underline:before .MuiInput-underline:before {
  /* border: 1px solid transparent !important; */
  /* background: #ffffff !important; */
  border-bottom: 0px;
}

.labelTextbox {
  height: 38.56px;
  /* top: 18%; */
  bottom: 76.77%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #676984 !important;
}

.UserNameInput {
  top: 10% !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  color: #676984 !important;
}

.inputRootContainer {
  /* margin-left: "30px"; */
  max-width: 620px !important;
  width: 100% !important;
}

.inputRootContainerForget {
  justify-content: flex-end;
  display: flex;
}

.inputRootSubContainer {
  margin-left: 30px;
  margin-top: 6px;
}

.RemeberMeContainer {
  flex-wrap: nowrap !important;
}

.checkboxRemeberME {
  width: 30px;
}

.loginButton.MuiButton-root:hover {
  background: #264c86 !important;
}

@media only screen and (max-width: 550px) {
  .loginButton {
    width: 92%;
    max-width: 350px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 600px) {
  .loginButton {
    width: 160px;
  }
}

.loginButton {
  /* width: 160px; */
  font-size: 11px;
  text-transform: uppercase;
  background-color: #264c86 !important;
  border: 1.5px solid #264c86;
  color: #ffffff !important;
  /* cursor: pointer; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.errorMessageAlert {
  margin-left: 35px !important;
  margin-top: 10px !important;
  color: #273050;
  background: rgba(192, 29, 29, 0.05);
  border: 1px solid #e96262;
  width: 80%;
  max-width: 592px;
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 10px;
}

.alertMessageImage {
  margin-left: 10px;
  margin-right: 10px;
}

.loginMobileText {
  position: absolute;
  top: 80px;
  left: 20px;
  color: #ffffff;
  font-size: 22.4421px;
  line-height: 26px;
}

@media only screen and (max-width: 550px) {
  .TextBoxMobileView {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 600px) {
  .TextBoxMobileView {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 550px) {
  .inputBoxMobileView {
    margin-right: 8%;
  }
}

@media only screen and (min-width: 600px) {
  .inputBoxMobileView {
    margin-right: 22%;
  }
}

.poweredbyIcon {
  padding-left: 3px;
  padding-top: 4px;
  width: 15px;
  height: 15px;
}

.poweredByCover {
  margin-left: 5px;
  margin-right: 5px;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.resendOtp {
  padding-top: 10px;
  justify-content: revert !important;
  margin-left: 12px;
}

.resendOtp button {
  margin-left: 9px;
  margin-top: 10px;
}

.otpVerifyButton {
  margin-right: 10px !important;
}

.verrifyOtpButtonContainer {
  margin-left: 20px;
  margin-top: 40px;
}

.otpContainer {
  margin-top: 10px;
}

.inputBoxMobileView .TextBoxMobileView .otPtextField {
  width: 40%;
  margin-left: 8px;
  margin-top: 15px;
}

.otpSuccessMessage {
  margin-left: 33px;
  color: green;
  font-weight: 400;
  margin-top:10px;
}

.otpDefaultMessage {
  margin-left: 33px;
  font-weight: 400;
  margin-top:10px;
  font-size: 12px;
}

.invalidOTP {
  color: red;
  margin-left: 33px;
}

.otpMessageContainer {
  display: grid;
  margin-bottom: 10px;
}

.userName {
  font-weight: 500;
  font-size: 13px;
}

.errorEmailAccess {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 100;
}