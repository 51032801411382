.am-table .contentFilterContainer {
    margin-top: 10px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}

.config-container .reports-login-history .content-filter-table-container {
    display: block;
}

.reports-login-history .table-header-cf {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    color: #615469;
    margin: -10px 0px 10px 7px;
}

.login-tableCell .table-cell-span {
    display: flex;
    align-items: center;
}

.content-filter-table-container .CF-sites {
    margin-top: 0px;
}

.TopUsersConatiner .CF-usage {
    margin-bottom: 8px;
}

.login-tableRow .CF-cell {
    padding-left: 15px;
}

.tableBody .new-deployed-usage-tr .CF-cell {
    padding-left: 15px;
}

/* Top Widget */

.Form .content-filter-widget-card {
    width: 100%;
    height: 115px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.content-filter-widget-card .top-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 15px;
    height: 55%;
}

.details>p:nth-child(1) {
    font-family: "Roboto Mono";
    color: #6e7079;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
}

.details>p:nth-child(2) {
    width: 225px;
    font-size: 16px;
    font-family: "Roboto Mono";
    font-weight: 600;
    color: #0000ff;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
}

.hit-details {
    padding: 0px 15px 0px 15px;
    line-height: 5px;
}

.hit-details>p:nth-child(1) {
    font-family: "Roboto Mono";
    color: #6e7079;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
}

.hit-details>p:nth-child(2) {
    font-family: "Roboto Mono";
    font-size: 14px;
    font-weight: 700;
    color: #45464e;
}

.top-details .logo-div {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.top-details .logo-div>img {
    width: 13px;
    height: 13px;
    margin-top: 7px;
}

.content-filter-widget-card .top-details .fqdn>img {
    width: 15px;
    height: 15px;
    margin-top: 6px;
    margin-left: 4px
}

.Form .top-overview {
    font-size: 14px;
    color: #45464e;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    margin: 15px 0px 10px 0px;
    letter-spacing: 0.5px;
}

.top-details .cf-widget-name {
    margin-right: -10px
}

/* pie chart */

.pie-container-cf {
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.Form .pie-summary {
    font-size: 14px;
    color: #45464e;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    margin: 25px 0px 15px 0px;
    letter-spacing: 0.5px;
}

.pieChart-activity .content-filter-filt {
    color: #2a46ff;
    cursor: pointer;
    font-size: 12px;
}

.piechart-selectContainer .pie-content-title {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Inter';
}

.am-table .totalUsageChartContainer .content-filter-pie-chart-div {
    justify-content: flex-start;
}

.usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text {
    width: fit-content;
    gap: 0px;
}

.usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
    width: 214px;
}

.appcategoryUsed-usage .con-filt-dot {
    margin-right: 0px;
}

.Form .pie-chart-limit-div-cf {
    margin: 15px 0px 0px 0px;
}

/* line chart */

.login-history-bar-chart-comp .cf-linechart {
    height: auto;
    padding-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.line-cf-chart>div:nth-child(2) {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 11px;
}

.cf-linechart .cf-legends {
    margin-top: 5px;
}

.cf-linechart .hits-cate {
    font-size: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    color: #45464f;
    margin: 0px 0px 0px 25px;
}

.cf-linechart .chart-option-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chart-option-btn .content-filter-actions-div {
    display: flex;
}

/* Bottom table */

.usageDetails-cards .content-filter-table-limit {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    margin: 0px 25px -25px 0px;
}

.loginHistory-tableRow .notUserClick {
    color: #45464E;
    cursor: auto;
}

@media only screen and (max-width: 1300px) {
    .details>p:nth-child(2) {
        width: 180px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 210px;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 125px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 150px;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1920px) {
    .usageDetailsWithCategory .appcategoryUsed-usage .cont-filt-pie-text>p {
        width: 185px;
    }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
    .details>p:nth-child(2) {
        width: 200px;
    }
}

@media only screen and (min-width: 1401px) and (max-width: 1450px) {
    .details>p:nth-child(2) {
        width: 230px;
    }
}

@media only screen and (min-width: 1451px) and (max-width: 1500px) {
    .details>p:nth-child(2) {
        width: 245px;
    }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
    .details>p:nth-child(2) {
        width: 255px;
    }
}

.content-filter-body-content {
    height: calc(100vh - 251px);
    overflow-y: auto;
    max-height: 574px;
}

.overflow-hidden {
    overflow: hidden;
}