.vessel_summary_page{
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.vessel_summary_page .topology {
    display: flex;
    margin-bottom: 20px;
    background-color: #FFFFFF;
}
.vessel_summary_topology_diagram{
    background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 10px;
    min-height: 400px;
}
.vessel_summary_product_details{
   padding-left: 20px;
}
.vessel_summary_product_details .vessel_summary_product {
    /* background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 10px; */
    padding: 10px 0px 10px 15px;
    /* margin-bottom: 15px; */

    background: #FFFFFF;
    border: 1px solid #F4F7F8;
    border-radius: 10px 10px 0px 0px;
}
.vessel_summary_product_details .vessel_summary_product_device{
    /* background: #FFFFFF;
    border: 1px solid rgba(20, 99, 172, 0.24);
    border-radius: 10px; */
    padding: 0px 0px 10px 0px;
    /* margin-bottom: 15px; */
}
.vessel_summary_product_name{
    font-family: inter !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.05em !important;
    color: #45464e !important;
}
.vessel_summary_product_name.productName{
     float: left;
}
.vessel_summary_product .product_table .MuiTableCell-head, .vessel_summary_product .product_table .MuiTableCell-body {
    border: 0px !important;
}
.product_table{
   width: 50% !important;
}
.vessel_summary_product .product_table .MuiTableCell-head, .vessel_summary_product .product_table .MuiTableCell-body,
.vessel_summary_product_device .device_table .MuiTableCell-head, .vessel_summary_product_device .device_table .MuiTableCell-body{
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 0.035em !important;
}
.vessel_table_cell{
    text-align: center !important;
}
.vessel_summary_product_device .device_table{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
}
.vessel_summary_product .product_table .MuiTableCell-head, .vessel_summary_product .product_table .MuiTableCell-body{
    color: #45464e !important;
    padding: 10px 10px 0px 0px !important;
    width: 30%;
}
.vessel_summary_product_device .device_table .MuiTableCell-head, .vessel_summary_product_device .device_table .MuiTableCell-body{
    color: #676984 !important;
    padding: 10px 10px 10px 15px !important;
    width: 18%;
}

.VesselMMSI {
    font-size: 14px;
    font-weight: 500;
    color:#3c7bd3;
}

.mmsi-streetAdrdess {
    margin-top: -20px;
    font-size: 13px;
    color: #676a8e;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    width: fit-content;
    border-radius: 5px;
    padding: 10px;
}

.MMSIDiv {
    border: 1px solid #c3d6e7;
    margin-top: 8px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    width: 450px;
}

.addressDiv {
    border: 1px solid #c3d6e7;
    padding: 10px;
    border-radius: 5px;
    width: 450px;
    margin-bottom: 5px;
}

.addressLineDiv {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.scrollVesselDetail {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}

/* Update SITE pop up CSS */

.updateSiteNameBox {
    max-width: 500px;
    margin-bottom: 5px;
}

.desc:hover {
    background-color: #f1f1f1;
}

.errorspan {
    font-size: 15px;
    font-style: italic;
    padding: 5px 10px;
    color: red;
}

.noteDialog {
    max-width: 675px;
    margin-left: 33%;
}

.siteUpdate>.marBtm20 {
    margin-bottom: 0px;
    text-transform: none;
}
.siteUpdate{
    display: flex;
    align-items: center;
}
.topology-ou-name {
    color: #676984;
    padding: 0px 0px 8px 8px;
}
.fontWt {
    font-weight: 500;
}
.topolgoy_update_device_info.dialog_content {
    padding: 24px;
}
.parentSetting .label{
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
}
.parent-enable-disable-icon {
    height: 20px;
    width: 30px;
}
.parent-enable-disable-icon.enable {
    cursor: default;
}
.parent-enable-disable-icon.disable{
    cursor: not-allowed;
}
.dialog_title {
    border-radius: 0px;
}

.deviceUpdate {
    width: 17px;
    height: 17px;
    margin-bottom: -5px;
    padding-left: 5px;
}

.margAuto {
    margin-left: auto;
}

.closeConfirmBorder {
    border: 4px solid #264C86;
}

.closeConfirm {
    display: flex;
    justify-content: space-around;
}

.updateSiteNameBtn {
    text-align: right;
    align-self: center;
}

.updateSiteAdressbtn {
    margin-right: 60px;
}
.siteNameEditContainer .editName .MuiInputBase-input {
    text-overflow: ellipsis;
}

.vessel_table_cell--portal_link {
    cursor: pointer;
}

.topologySitePopUp {
    width: 100%;
}

.vessel_summary_product_device .topology-tab-actions > span {
    margin-right: 10px;
}
.vessel_summary_product_device .topology-tab-actions span:last-child {
    margin-right: 0;
}
.topology-tab-actions .modify-config-icon,
.topology-tab-actions .backup-config-now-icon {
    cursor: pointer;
}
.topology-tab-actions .modify-config-icon svg,
.topology-tab-actions .backup-config-now-icon svg{
    color:#1B51A7 ;
    width: 25px;
    height: 32px;
}
.topology-tab-actions .modify-config-icon.disable,
.topology-tab-actions .backup-config-now-icon.disable {
    cursor: not-allowed;
    opacity: 0.5;
}
.topology-tab-actions .modify-config-icon svg path,
.topology-tab-actions .backup-config-now-icon svg path {
    stroke: #ffffff;
    stroke-width: 0.6;
}

.border-right-topology {
    background-color: #F5F5F5;
}

.width-20-percent {
    width: 20%;
}

.width-12-percent {
    width: 12%;
}

.width-8-percent {
    width: 8%
}

.topology-device-name-with-warning-icon, .topology-device-name-with-warning-icon .warningIcon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.topology-device-name-with-warning-icon .warningIcon img {
    height: 20px;
    width: 20px;
}

.justifyContenetCenter {
    justify-content: center;
}