.starlinkGrid .termsTypo{
font-family: Inter;
font-size: 24px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 24px;

}

.starlinkGrid .starlink-title-typo{
font-family: Inter;
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 10px;

}

.stepperStarlink .MuiStepLabel-label{
    color:  #ffffff;
    font-family: Inter;
    font-size: 16px;
}

.stepperStarlink .MuiStepLabel-label.Mui-active{
    color: #ffffff !important;
    font-weight: 700;
    font-family: Inter;
    font-size: 16px;
}

.stepperStarlink .MuiStepLabel-label.Mui-completed{
    color: #ffffff !important;
    font-weight: 700;
    font-family: Inter;
    font-size: 16px;
}

.starlinkStepperGrid{
    /* display: 'flex', justifyContent: 'center'  */
    display: flex;
    justify-content: center;
}

.MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color:green !important;
}

.starlinkGrid .starlink-title-desc{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 16px;
}

.starlinkGrid .agreeBtnDiv{
    background-color: #244C84;
    border-radius: 4px;
    width: 150px;
    text-align: center;
    margin:20px 0px 10px auto;
}

.starlinkGrid .acceptBtn{
    margin-left: auto;
    background-color: #244C84;
    border-radius: 4px;
    width: 200px;
    text-align: center;
}

.starlinkGrid .acceptBtnfinal{
    margin-left: 10px;
    background-color: #244C84;
    border-radius: 4px;
    width: 200px;
    text-align: center;
}

.starlinkGrid .agreeBtn{
    text-transform: none;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.lastPage .btnNextGrid{
    display: flex;
    align-items: flex-end;
    margin: 10px 0px 10px auto;
}

.starlinkPadding{
    padding: 10px 20px;
    height: 35vw;
    overflow: scroll;
}

.starlinkPadding .welcomePageGrid{
    background-color: #FAFAFA;
     padding: 24px;
     height: 310px;
      overflow: scroll
}

.lastPage .welcomePageGrid{
    background-color: #FAFAFA;
     padding: 24px;
     height: 310px;
      overflow: scroll
}

.starlinkPadding .agreementPageGrid{
    background-color: #FAFAFA;
     padding: 24px;
     height: 310px;
      overflow: scroll
}

.starlinkGridMain .starlinkBackgroundImage{
    height: 225px;
    background: linear-gradient(91deg, rgb(15, 41, 78) 2.84%, rgb(10 39 110) 51.78%, rgba(14, 45, 90, 0.94) 92%);
}

.starlinkGridMain .starImageGrid{
    /* display: "flex", alignItems: "center", justifyContent: "center"  */
    display: flex;
    align-items: center;
    justify-content: center;
}

.starlinkGridMain .starImageBackground{
/*  height: "60px", width: "250px", filter: "invert(100%)" */
    height: 60px;
    width: 250px;
    filter:invert(100%)
}

.welcomePageGrid .StarlinkMainLayoutGrid{
    display: flex;
    justify-content: space-between;
}

.agreementBtn{
        display: flex;
        /* align-items: flex-end; */
        margin: 10px 0px 10px auto;
    }

.agreementBtn .agreementInnerDiv{
    /* flex: 1, display: "flex", alignItems: "baseline" */
    display: flex;
    align-items: baseline;
    flex: 1;
}

.starlinkTextAlign{
    text-align: right;
}

.starlinkPageLayout{
    /* style={{ position: "absolute", top: "250px", width: "100%" }} */
    position: absolute;
    top: 250px;
    width: 100%;
}

.starlink-title-desc .starlinkInfoTable{
    /* style={{ width: '50%', border: '1px solid #000' }} */
    width: 50%;
    border: 1px solid #000;
}

.starlinkTableBorder{
    border: 1px solid #000;
}

.starlinkTextAlign .starlinkTextField{
    /* width: "200px", marginRight: "10px" */
    width: 200px;
    margin-right: 10px;
}

.starlinkCircularDiv .starlinkCircularProgress{
    width:28px;
    height: 28px;
    color:#264C86;
    margin: auto;
}

.starlinkPadding .starlinkCircularDiv{
    display: flex;
    height: 100px;
}