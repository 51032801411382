.alertsInterval {
  padding-left: 20px;
}

.alertsIntervalSelectorPadding {
  padding-left: 16px;
}

.labelDetails .MuiButtonBase-root  {
  height: 12px;
}

.alertsParentDashboard .alertsTable-FleetDashboard {
  border:1px solid #EBEBEB;
  width: 100%;
}

.alertsMainHyperLink {
  text-decoration: none;
  color: blue;
}

.alertsParentDashboard .alertsTable-FleetDashboard table {
  overflow-y: hidden;
}

.alertsTable-FleetDashboard::-webkit-scrollbar {
  width: 6px;
}

.alertsTable-FleetDashboard .alertsTable-tableHead {
  box-shadow: -10px -10px 0px 0px #ffffff;
}

.alertsTable-tableHead .alertReports-tableRow {
  background: #f5f5f5 ;
  height: 32px;
  border: 1px solid #EBEBEB ;
  align-self: stretch;
  box-sizing: border-box;
  box-shadow: -10px -10px 0px 0px #ffffff;
}

.alertsTable-action-deleteIcon {
  cursor: pointer;
}

.alertReports-tableRow .alertsTable-fleetDataTable {
  color: #8B8D97;
  text-align: center;
  letter-spacing: 1px;
  height: 35px;
  font-variant: small-caps;
  text-transform: uppercase;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* width: 16%; */
  padding: 0px;
}

.alertsData-tabelRow .alertsTable-tableCell{
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
}

.alertsData-tabelRow .alertsTable-tableCell .alertStatusWithIcon {
  display: flex;
  justify-content: center;
}

.userDataRightContainer .UsageLineChartComponent {
  margin: 24px 0px 0px 16px;
}



.alertsParentDashboard {
  height: calc(100vh - 165px);
  overflow-y: auto;
}

.alerts-pagination-position {
  margin-left: 17px;
  margin-top: 5px;
  margin-right: 30px;
}

.alerts-pagination-position .pagination-toolbar  {
  position: fixed;
  bottom: 15px;
  width: 86.9%;
}

.filtersButton .filtersImage {
  width: "16px";
  height: "16px";
  padding: "5px";
}

.AlertsPageFilters .filtersButtonContainer {
  display: flex;
  align-items: center;
}
.filtersButtonContainer .filtersButton.tkt_moreFilters_button {
  top: 0px;
  border: 1px solid grey !important;
}

.filtersButtonContainer button {
  border: "1px solid #ebebeb !important";
  margin-top: "-4px";
  display: "flex";
  justify-content: "center";
}

.alertFilterIconContainer .alertFilterIcon {
  height: 26px;
  width: 26px;
}

.alertFilterIconContainer .alertFilterIcon img {
  height: 20px;
  width: 18px;
}

.alertFilterIconContainer .MuiButton-startIcon {
  margin: 0px;
}

.filtersButtonContainer .alertFilterIconContainer {
  color: black;
}

.AlertsPageFilters .alertPage-search {
  max-width: 180px;
}


.noDataAvailableCss {
  color: #273050 !important;
  background-color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;

}

.tableCssCell {
  font-size: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.alertParentPage .AlertsPageFilters {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tooltipNew {
  position: relative;
  display: inline-block;
}

.tooltipNew .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: gray;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: -30px;
  left: 30px;
}

.tooltipNew:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-height: 400px){
  .alertPage-table {
    max-height: 150px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .alertPage-table {
    max-height: 150px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .alertPage-table {
    max-height: 200px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .alertPage-table {
    max-height: 250px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .alertPage-table {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .alertPage-table {
    max-height: 350px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 650px) and  (max-height: 690px){
  .alertPage-table {
    max-height: 400px;
    overflow-y: auto;
  }
}

 @media only screen and (min-height: 690px) and  (max-height: 740px){
  .alertPage-table {
    max-height: 440px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 740px) and  (max-height: 790px){
  .alertPage-table {
    max-height: 490px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 790px) and  (max-height: 849px){
  .alertPage-table {
    max-height: 540px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 849px) and  (max-height: 899px){
  .alertPage-table {
    max-height: 600px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:899px) and (max-height:950px){
  .alertPage-table {
    max-height: 650px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:950px) and (max-height:999px){
  .alertPage-table {
    max-height: 700px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:999px) and (max-height:1050px){
  .alertPage-table {
    max-height: 750px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1050px) and (max-height:1080px){
  .alertPage-table {
    max-height: 790px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1080px) and (max-height:1140px){
  .alertPage-table {
    max-height: 820px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1140px) and (max-height:1200px){
  .alertPage-table {
    max-height: 890px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1200px) and (max-height:1300px){
  .alertPage-table {
    max-height: 940px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1300px) and (max-height:1400px){
  .alertPage-table {
    max-height: 1040px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1400px) and (max-height:1920px){
  .alertPage-table {
    max-height: 1640px;
    overflow-y: auto;
  }
}

.alertParentPage .margin-left-30 {
  margin-left: 30px;
}

.checkboxDetails {
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.inline-checkbox {
  display: inherit;
}

.margin-left-right {
  margin: 0px 20px;
}

.AlertsPageFilters .marg-left-auto {
  margin-left: auto;
}

.disabled-button-csv-download {
  margin-left: auto;
  display: flex;
  cursor: not-allowed;
}

.disp-flex {
  display: flex;
}

.Form .topology-alerts-conatiner {
  height: calc(100vh - 190px);
  overflow-y: auto;
  margin-right: 10px;
  padding-right: 10px;
}

.alertsData-tabelRow .alerts-interface-width {
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flex-dispaly {
  display: flex;
  align-items: center;
}

.alertsData-tabelRow .alerts-site-ellipse, .alertsData-tabelRow .alerts-devicename-ellipse {
    white-space: nowrap;
    max-width: 215px;
    text-overflow: ellipsis;
    overflow: hidden;
}