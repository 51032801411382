/* .FleetDashboard  */

/* .FleetDashboard .css-1q1u3t4-MuiTableRow-root{
    background-color: #264C86;
    color: #fff !important;
} */

.FleetDashboard.MuiTableContainer-root{
    color: #fff !important;
    margin-top: 10px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
}

/* .FleetDashboard .css-1ygcj2i-MuiTableCell-root {
    color: #fff !important;
} */

/* .FleetDashboard .css-1ex1afd-MuiTableCell-root{
    color:#273050 !important;
} 

.FleetDashboard .css-1yhpg23-MuiTableCell-root{
    color:#273050 !important;
} */

/* .FleetDashboard .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #F5F9FF !important;
} */

.FleetDashboard .MuiTableCell-root{
    padding-left: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 10px !important;
}

.FleetDashboard .MuiTableHead-root{
    height: 42px;
}

.FleetDashboard .MuiTableRow-root{
    height: 35.69px !important;
    background-color: #264C86;
    color: #fff !important;
}