.pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: 'Inter';
    margin-top: auto;
}

.pagination-toolbar .records-select {
    font-size: 14px;
    font-family: 'Inter';
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
    color: #6E7079;
}

.pagination-toolbar .page-dropdown {
    background: #f5f5f5;
    max-width: 80px;
    height: 23px;
    color: #6E7079;
    padding-top: 3px;
    border-radius: 8px;
}

.pagination-toolbar .row-dropdown {
    background: #f5f5f5;
    width: 72px;
    height: 23px;
    color: #6E7079;
    padding-top: 3px;
    border-radius: 8px;
    cursor: pointer;
}


.pagination-toolbar .MuiBox-root .page-arrow {
    background-color: #F5F5F5;
    border-radius: 8px;
    width: 19px;
    height: 24px;
    margin-right: 5px;
    color: #6E7079;
}

.MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: 'Inter';
}

.records-select.Mui-focused > .MuiSelect-select:focus {
    background-color: unset;
}

.pagination-toolbar .pagination-actions {
    flex-shrink: 0;
    margin-left: 20;
}

.pagination-spacer {
    flex: 1 1 100%;
}

.pagination-displayPages {
    flex-shrink: 0;
    color: #6E7079;
}

.pagination-selectLabel {
    flex-shrink: 0;
    color: #8B8D97;
}

.page-dropdown .MuiSelect-select.MuiSelect-filled  {
    text-overflow: unset;
}

.records-select .page-drop {
    pointer-events: none;
}