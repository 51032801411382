
.rc--generate_link_button.MuiButton-outlined {
    background: #FFFFFF;
    border: 1px solid #1B51A7;
    color: #1B51A7;
    border-radius: 4px;
    padding: 0.25em 2em;
    text-transform: none;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
}

.rc--generate_link_button.MuiButton-outlined:hover {
    border: 1px solid #1B51A7;
}

.rc--generate_link_dialog--title_container {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
    border-bottom: 1px solid #EBEBEB;

    font-weight: 500;
    line-height: 3.25rem;
    color: #45464E;
}

.rc--generate_link_dialog--content {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1rem;

}

.rc--generate_link_dialog--actions {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    justify-content: end;
    align-items: center;
}

.rc--generate_link_dialog--content--row {
    display: flex;
    align-items: center;
}

.rc--generate_link_dialog--content--row--title {
    display: flex;
    flex-grow: 0.4;
    flex-shrink: 0.4;
    flex-basis: 0;
}

.rc--generate_link_dialog--content--row--data {
    display: flex;
    flex-grow: 0.6;
    flex-shrink: 0.6;
    flex-basis: 0;
}

.rc--submit.MuiButton-root {
    background: #2F4B82;
}

.rc--submit.MuiButton-root:hover {
    background: #2F4B82;
}

.pad-top-10 {
    padding-top: 10px;
}