.notAuthorizedPage{
    width: 100% !important;
}
.notAuthorizedPage .title_div{
    background-color: #FAF9FC !important;
    
}
.notAuthorizedPage .page_title{
    font-family: Roboto;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 25px !important;
    display: flex !important;
    align-items: center !important;
    color: #676984 !important;
}
.notAuthorizedPage .message{
    height: 100px !important;
    font-family: Roboto;
    font-style: normal !important;
    font-weight: bold !important;
    color: #676984 !important;
    font-size: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}