.operationalTopbar {
    display: flex;
    align-items: center;
    height: 64px;
}

.perSiteperWanUsageTableMargin {
    margin: 0px 20px;
  }

.perSiteperWanUsageClose {
    margin-left: 16px;
    cursor: pointer;
    color: #45464E;
}

.perSiteperWanUsageCloseIcon {
  height: 20px;
  width: 20px;
}

.TopUsersConatiner.perSiteperWanUsageTableMargin {
  margin: 0px 20px 20px 20px;
}

.timeseriesDwn.MuiGrid-grid-md-4 {
  margin-left: auto;
  max-width: 83%;
  flex-basis: 83%;
}

.operationalFilterPopup .MuiPaper-root {
  position: absolute;
  width: 274px;
  left: 185px;
  top: 180px;
  height: 323px;
  max-height: 323px;
}

.operationFilterpadding {
  padding: 18px 16px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}

.operationalClearApplyFilters {
  display: flex;
  padding: 20px 16px;
}

.tableBody .operationalTableData {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #45464E;
}

.greyDwnLink {
  color: #45464E;
}

.operationalTableClickableColumns {
  cursor: pointer;
  color: blue;
}