/* Create Alert Page */

.create_alert--background {
    height: 100vh;
    max-height: calc(100vh - 80px);
    background: #F9F9F9;
    overflow-y: auto;
}

.create_alert--container {
    margin-top: 80px;
}

.create-alert--alert_meta {
    padding: 1.75% 11%;
    margin-top: 80px;
}

.create_alert--header {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.2rem;
    color: #45464E;
}

.create_alert--sub_header {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.2rem;
    color: #6E7079;
}

.create_alert--form_group {
    display: flex;
    gap: 0.65rem;
    margin: 1rem;
    flex-wrap: wrap;
}

.create_alert--form_group--column {
    flex-direction: column;
}

.create_alert--form_group--row {
    flex-direction: row;
}

.create_alert--form_group--input {
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 0.65rem 0.75rem;
}

.create_alert--form_group--input:focus {
    outline: 2px solid #E7E7E7;
    ;
}

.create_alert--form_group--input_text {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #45464E;
}

.create_alert--form_group--label {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #6E7079;
}

.create_alert--form_group--radio_group {
    display: flex;
}

.create_alert--form_group--radio input,
.create_alert--form_group--radio label {
    cursor: pointer;
}

.create_alert--form_group--radio {
    margin-right: 1rem;
}

.justify_content--end {
    justify-content: end;
}

/* Create Dialogue CSS */

.create_alert--alert_meta--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.create_alert--alert_meta--title {
    border-right: 1px solid #EBEBEB;
    padding-top: 5.5%;
    padding-left: 7.75%;
    flex-grow: 0.4;
    flex-shrink: 0.4;
    flex-basis: 0;
}

.create_alert--alert_meta--info {
    padding: 2.75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 0.6;
    flex-shrink: 0.6;
    flex-basis: 0;
}

/* Card Select */

.card_select--container {
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 1rem;
    gap: 2rem;
}

.card_select--container:hover {
    cursor: pointer;
}

.card_select--name {
    font-weight: 500;
}

.card_select--icon_x_name {
    display: flex;
    flex-direction: column;
}

.card_select--checkcircle {
    display: flex;
    justify-content: end;
}

.card_select--group--row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
}

.create_alert--alert_header {
    display: flex;
    margin: 1rem 4rem;
    align-items: center;
    justify-content: space-between;
}

.create_alert--alert_header--alert_name {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #45464E;
}

.create_alert--workflow_line_container {
    display: flex;
    justify-content: center;
    position: relative;
}

.create_alert--workflow_line_icon {
    font-size: 48px;
}

.create_alert--workflow_line_root_condition {
    display: flex;
    background: #8B8D97;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    position: absolute;
    padding: 0.25em 1em;
    top: 1.5em;
}

.create_alert--add_alert_rule_menu {
    display: flex;
    justify-content: center;
}

.create_alert--create_button--container { 
    display: flex;
    justify-content: center;
}

.create_alert--create_button--button.MuiButton-root {
    margin: 1em;
    background: #8B8D97;
    border-radius: 19px
}

.create_alert--create_button--button.valid.MuiButton-root {
    background: #2F4B82;
}

.create_alert--create_button--button.MuiButton-root:hover { 
    background: #8B8D97
}

.create_alert--create_button--button.valid.MuiButton-root:hover { 
    background: #2F4B82
}