#softwareListTabs {
    width: 100%;
}
#softwareListTabs > div {
    margin-left: 5px;
}
#softwareListTabs .activeTab {
    background: #2860B4; 
    color: #ffffff;
}
#softwareListTabs .inActiveTab {
    background: #ffffff;
    color: #676984;
}
#softwareListTabs > div {
    display: flex;
}
#softwareListTabs > div button {
    width: 10%;
    border: 1px solid #C7DAEB;
    border-radius: 0px;
}
#softwareListTabs > div button:first-child {
    border-radius: 8px  0px 0px 8px;
    margin-left: 25px;
}
#softwareListTabs > div button:nth-last-child(1) {
    border-radius: 0px 8px 8px 0px;
}

.software-main-container {
    width: 100%;
    background: white;
}

.software-main-container-kon {
    width: 100%;
    padding-top: 90px;
    background: rgb(244, 247, 248);
    height: calc(100vh - 10px);
    overflow-y: auto;
}

.zIndexNav {
    z-index: 1000;
}

.marginTop40 {
    margin-top: 40px;
}

.soft-scroll-container {
    margin-left: 15px;
    padding-right: 25px;
    height: calc(100vh - 160px);
    overflow-y: auto;
}