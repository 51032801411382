.agent-width-20 {
    width: 20%;
}

.agent-width-10 {
    width: 10%;
}

.agent-width-5 {
    width: 5%;
}

.agent-padding-left-5 {
    padding-left: 5px;
}
