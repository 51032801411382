.ac--dialog-container .ac--edit-actions-title {
    border-bottom: 1px solid #efefef;
    background: white;
    padding: 18px 10px 18px 15px;
}

.ac--dialog-container .ac--edit-actions-content {
    padding: 0px;
    overflow: hidden;
}

.ac--dialog-container .ac--edit-change-group-btn {
    position: absolute;
    bottom: 20px;
    right: 25px;
    border: 1px solid #264c86;
    background: #264c86;
    color: #fff;
    height: 35px;
    border-radius: 4px;
    width: 125px;
}

.ac--dialog-container .ac--edit-change-group-btn:hover {
    background: #264c86
}


.ac--dialog-container.menuScroll.device-eos-history-popup .ac--status,
.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--status {
    display: flex;
    align-items: center;
    margin-left: 0px;
}

.ac--dialog-container.menuScroll.device-eos-history-popup .ac--status .ac--text,
.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--status .ac--text {
    max-width: 100px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ac--dialog-container.menuScroll.device-eos-history-popup .ac--status .ac--text,
.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--status .ac--text,
.ac--dialog-container.menuScroll.device-eos-history-popup .ac--noData {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    background: #FFFFFF;
    text-transform: none;
}

.ac--dialog-container.menuScroll.device-eos-history-popup .ac--noData {
    text-align: center;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content {
    margin-top: 0px;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--tab-content {
    padding: 15px 0 0 0;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--tabsHeader button {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #8b8d97;
    border-bottom: 2px solid transparent;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--tabsHeader button span {
    width: 100%;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--tabsHeader button,
.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--tabsHeader button:hover {
    padding: 12px 0 10px;
    margin-right: 15px;
    text-transform: None;
    cursor: pointer;
    border-radius: 0;
    /* min-width: unset; */
    min-height: 42px;
    height: 42px;
    background-color: transparent;
    outline: none;
    transition: none;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--tabsHeader .Mui-selected {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #2f4b82 !important;
    border-bottom: 2px solid #2f4b82;
}

.ac--dialog-container.menuScroll.device-history-popup .ac--warehouse-device-history-content .ac--noDataAvailableCss {
    font-family: "Inter";
    text-align: center;
}


.ac--dialog-paper-container .MuiPaper-root {
    width: 80% !important;
    max-height: calc(90% - 70px);
    top: 60px;
    position: fixed;
    max-width: 80%;
}

.ac--popup-fixed-position .MuiDialog-paperFullWidth {
    position: fixed;
    top: 82px;
    left: 200px;
    width: 836px;
    max-height: calc(100vh - 130px);
    height: 70%;
}


.ac--edit-actions-container .ac--service-hub-list-menu-container {
    width: 18%;
}

.ac--edit-actions-container .ac--service-hub-list-menu-content {
    width: 82%;
    padding: 10px;
}

.ac--service-hub-data-body {
    max-height: fit-content;
    min-height: 375px;
}

.ac--edit-actions-container .ac--service-hub-list-menu-content .MuiDialogContent-root:first-child {
    padding-top: 0px;
}


.ac--edit-actions-container1 .ac--service-hub-list-menu-container {
    width: 25%;
}

.ac--edit-actions-container1 .ac--service-hub-list-menu-content {
    width: 75%;
    padding: 10px;
}

.ac--edit-actions-container1 .ac--service-hub-list-menu-content .MuiDialogContent-root:first-child {
    padding-top: 0px;
}

.ac--create-default-config-title.edit-actions-title .ac--device-configuration-popup .ac--selected-site-device.device-configuration-popup-devicename {
    margin-left: 44px;
}

.ac--create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5;
}

.ac--edit-actions-title {
    border-bottom: 1px solid #efefef;
    background: white;
    padding: 18px 10px 18px 15px;
}

.ac--device-border-right {
    margin-top: 5px;
    border-right: 1px solid lightgray;
    height: auto;
}

.ac--config-list-menu-container {
    overflow-y: auto;
    margin-top: 5px;
}

.ac--service-hub-actions-data-body {
    max-height: auto;
}

.ac--status-lists .ac--selectedAction {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    color: #0E0548;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.ac--status-lists .ac--font-status-pop-up {
    font-family: 'Inter';
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #0E0548;
    padding: 5px 0px;
    transition: 0.1s;
}

.ac--status-lists .ac--NotselectedAction {
    color: rgba(69, 70, 78, 1);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.ac--service-hub-list-menu-content {
    width: 80%;
    padding: 10px;
}


.ac--edit-actions-data-body {
    margin-top: 5px;
    margin-left: 5px;
}

.ac--sub-tab-parent .ac--sub-tab-width {
    width: 148.5px;
    height: 26px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
}