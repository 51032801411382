.container {
    border: 1px solid black;
    padding: 0.5rem;
    margin: 0.5rem;
}

.event_alert_configuration--container {
    background: #FFFFFF;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.create_alert--container .event_alert_configuration--container {
    margin: 1rem 4rem;
    border-radius: 12px;
}

.event_alert_configuration--header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #EBEBEB;
}

.event_alert_configuration--header:hover {
    cursor: pointer;
}

.event_alert_configuration--header--meta {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.event_alert_configuration--header--meta--icon {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    color: #2F4B82;
    background: #F4F5FA;
    border-radius: 8px;
}

.event_alert_configuration--header--meta--title_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.event_alert_configuration--header--meta--title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
}

.event_alert_configuration--header--meta--subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}

.event_alert_configuration--header--action_dots {
    display: flex;
    align-items: center;
}

.event_alert_configuration--body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Footer */

.event_alert_configuration--footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-top: 1px solid #EBEBEB;
}

.save_config_button.MuiButton-contained, .save_config_button.MuiButton-contained:hover {
    background: #2F4B82;
}

/* Event Condition */

.event_alert_configuration--body--condition_container {
    margin: 1em 0em;
}

.event_alert_configuration--body--rule_container {
    display: flex;
    flex-direction: column;
}

.event_alert_configuration--body--rule {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0.5em;
    

    background: rgba(244, 245, 250, 0.7);
    border-radius: 8px;
}

.event_alert_configuration--body--rule--inputs {
    display: flex;
    gap: 2rem;
    align-items: center;
}

/* More Action */

#event-configuration-more-options .MuiMenuItem-root:hover {
    background: #F4F5FA;
    border-radius: 8px;
}

.event-configuration-more-options-paper.MuiMenu-paper {
    padding: 0rem 0.5rem;
    width: 10rem;
}

/* Common */

.d-none {
    display: none;
}