.content_filter--base--container {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #2f4b82;
  border-radius: 0.5rem;
}

.content_filter--base--margin_1x {
  margin: 1rem;
}

.content_filter--base--margin_05x {
  margin: 0.5rem;
}

.content_filter--base--mtb_07x {
  margin-top: 0.1rem;
  margin-bottom: 0.9rem;
  padding-top: 15px;
}

.content_filter--selectall--mtb_07x {
  margin-top: 0.01rem;
  margin-bottom: 0.5rem;
}
.content_filter--base--mt_1x {
  margin-top: 1rem;
}

.content_filter--base--mb_1x {
  margin-bottom: 1rem;
}

.content_filter--base--ml_1x {
  margin-left: 1rem;
}

.content_filter--base--mr_1x {
  margin-right: 1rem;
}

.content_filter--base--mr_05x {
  margin-right: 0.5rem;
}

.content_filter--base--padding_1x {
  padding: 1rem;
}

.content_filter--base--padding_05x {
  padding: 0.5rem;
}

.content_filter--base--pb_1x {
  padding-bottom: 1rem;
}

.content_filter--container .MuiTypography-root {
  font-family: "Inter";
  font-weight: 500;
  color: #45464e;
}

.content_filter--base--font--base_wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTypography-root.content_filter--base--font_07x {
  font-size: 0.7rem;
  font-weight: 600;
}

.MuiTypography-root.content_filter--base--font_08x {
  font-family: "Inter";
  font-size: 0.8rem;
}

.MuiTypography-root.content_filter--base--font_09x {
  font-size: 0.9rem;
  margin-top: "2px";
}

.MuiTypography-root.content_filter--base--font_1x {
  font-size: 1rem;
}

.MuiTypography-root.content_filter--base--font_15x {
  font-size: 1.25rem;
}

.MuiTypography-root.content_filter--base--font_bold {
  font-weight: 100;
}

.MuiTypography-root.content_filter--base--font_2x {
  font-size: 1.5rem;
  font-family: inter;
  font-style: normal;
}

.MuiTypography-root.content_filter--base--text_center {
  text-align: center;
}

.MuiTypography-root.content_filter--base--text_start {
  text-align: start;
}

.MuiTypography-root.content_filter--base--color_danger {
  color: #ed524a;
}

.MuiTypography-root.content_filter--base--color_success {
  color: #6cce76;
}

.MuiTypography-root.content_filter--base--color_primary {
  color: #2860b4;
}

.content_filter--base--flex {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.content_filter--base--flex--gap--05x {
  display: flex;
  gap: 0.5rem;
}

.content_filter--base--flex--column {
  display: flex;
  flex-direction: column;
}

.content_filter--base--flex--row {
  display: flex;
  flex-direction: row;
}

.content_filter--base--flex--justify--start {
  display: flex;
  justify-content: flex-start;
}

.content_filter--base--flex--justify--end {
  display: flex;
  justify-content: flex-end;
}

.content_filter--base--flex--justify--center {
  display: flex;
  justify-content: center;
}

.content_filter--base--flex--justify--space_between {
  display: flex;
  justify-content: space-between;
}

.content_filter--base--flex--justify--space_around {
  display: flex;
  justify-content: space-around;
}

.content_filter--base--flex--align--start {
  display: flex;
  align-items: flex-start;
}

.content_filter--base--flex--align--center {
  display: flex;
  align-items: center;
}

.content_filter--base--cursor--pointer {
  cursor: pointer;
}

.MuiButtonBase-root.content_filter--button--cancel {
  border: 1px solid #ebebeb;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button--cancel:hover {
  border: 1px solid #ebebeb;
}

.MuiButtonBase-root.content_filter--button--cancel:disabled {
  background-color: #ebebeb;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button_outlined_filter {
  border-radius: 4px;
  border-color: #244C84;
  padding: 7px 8px;
  justify-content: center;
  align-items: center;
  color: #244C84;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.MuiButtonBase-root.content_filter--button_contained--primary {
  background-color: #244C84;
  color: #ffffff;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.MuiButtonBase-root.content_filter--button_contained--primary:hover {
  background-color: #244C84;
  color: #ffffff;
}

.MuiButtonBase-root.content_filter--button_contained--primary:disabled {
  background-color: #ebebeb;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button_outlined--primary {
  border-color: #264c86;
  color: #264c86;
}

.MuiButtonBase-root.content_filter--button_outlined--primary:hover {
  border-color: #264c86;
}

/* table style */

.content_filter--table_container .MuiTableCell-root {
  background: #ffffff;
  border-collapse: collapse;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: #45464e;
}

.content_filter--table_container .MuiTableCell-root.MuiTableCell-head {
  background-color: #f5f5f5;
  color: #8b8d97;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 145%;
  letter-spacing: 1px;
  cursor: pointer;
}

/* Pagination Unset */

.content_filter--container .pagination-root .pagination-toolbar {
  min-height: 40px;
  height: 40px;
  padding-right: 2px;
  font-size: 14px;
  margin-top: auto;
  padding-left: unset;
}

.content_filter--container .pagination-toolbar .records-select {
  font-size: 14px;
  border-radius: 8px;
  padding: 0px 8px 0px 7px;
  margin-right: 5px;
}

.content_filter--container .MuiMenuItem-root.records-select-item {
  font-size: 10px;
}

.content_filter--container .pagination-toolbar .row-dropdown {
  width: unset;
  margin-left: 0.5rem;
}

.content_filter--container .pagination-toolbar .page-dropdown {
  width: unset;
}

/* tooltip */

.MuiTooltip-tooltip.content_filter--tooltip {
  background-color: black;
  color: white;
  padding: unset;
  font-family: "Inter";
  font-size: 0.75rem;
  font-weight: 500;
}

.MuiTooltip-tooltip.content_filter--tooltip .MuiTableCell-root {
  color: white;
  font-family: "Inter";
  font-size: 0.75rem;
  font-weight: 500;
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.content_filter--tooltip
  .content_filter--tooltip--value_title {
  opacity: 0.5;
}

/* comp level */

.MuiGrid-root.content_filter--container {
  background-color: #f9f9f9;
  padding: 109px 1rem 1rem 1rem;
  overflow: auto;
  height: calc(100vh - 140px);
}

.content_filter--loader--container {
  position: absolute;
}

.content_filter--loader--container .loader {
  position: absolute;
  left: -1rem;
}

.content_filter--container .MuiChip-filled {
  margin: unset;
}

.content_filter--container .content_filter--dns_tag {
  max-width: 10rem;
}

.config-content-filter-popup {
  color: #9a9a9a;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.config-deploy-inventory-popup span {
  width: 40%;
  color: #45464e;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
}

.content-filter-title-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color:rgba(69, 70, 78, 1)
}

.content-filter-tooltip-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}


.content_filter-div-flex-end{
  display: flex;
  justify-content: flex-end;
}

.content_filter-div-flex-start{
  display: flex;
  justify-content: flex-start;
}

.MuiTextField-root.content_filter-search_field {
  width: 300px;
  margin-right: 10px;
}

.MuiButtonBase-root.content_filter--text-button {
  border-radius: 4px;
  border-color: #244C84;
  justify-content: center;
  align-items: center;
  color: #244C84;
  margin-right: 10px;
  padding: 0px;
}