.ProductAutoComplete .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1yyslfo-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 0px;
}

.lastestVersion{
  background: #abd9fbba !important;
}

.oldVersion{
  background: #ffffff !important;
}

.LatestCheck{
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.textLatestBuildOn{
  margin-left: 5px !important;
  color: #264C86;
  font-size: 12px;
}

select-product{
  margin-top: 20px;
  margin-bottom: 10px;
}


.margin45{
  margin-top: 45px;
}

.softwareListing-table .parent-element {
  margin-top: 10px;
}

.softwareListing-table .table-title {
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}

.softwareListing-table thead th.usage-fleetDataTable {
  text-align: center;

}

.softwareListing-table thead th.fleetDataTable span {
  padding-left: 0px;
  display: block;
}

.softwareListing-table tbody td {
  text-align: center;
}

.softwareListing-table tbody td.noDataAvailableCss {
  text-align: center;
}

.softwareListing-table tbody td a {
  text-decoration: none;
}

.softwareListing-table tbody td .arrow-down-icon,
.softwareListing-table tbody td .arrow-right-icon {
  cursor: pointer;
  opacity: 0.75;
}

.softwareListing-table .virtual-eos-quick-start-guide {
  float: right;
  text-decoration: none;
  color: #4c3aff;
  font-size: 12px;
}