.am-table .userDataRightContainer {
    width: 100%;
    height: calc(100vh - 225px);
    border-radius: 8px;
    overflow-y: auto;
    margin-top: 24px;
    overflow-x: hidden;
}

.overview_card {
  display: flex;
}

.Reports-overView {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0;
    color: #45464E;
}

.Reports-Usage {
    position: relative;
    height: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 24px 0px 16px 0px;
    color: #45464E;
}

.UsageContainer-overTime {
    display: flex;
}

.Reports-overView-topUsers {
    margin: 0 0 0 18px;
    margin: 24px 0px 16px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #45464E;
}

.userDataRightContainer .Usage-TableComponent {
    margin-left: 4px;
    margin-right: 5px;
    /* height: 560px; */
}

.TopUsersList-Reports {
    height: auto;
    width: 1072px;
    margin: 0px 0px 0px 9px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.TopUsersList-Reports .TopUsersConatiner {
    width: 100%;
    height: auto;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    background: white;
}

.TopUsersConatiner .Reports-graph_table{
    display: flex;
    justify-content: space-between;
}

.IPAddressList>div:nth-child(1) {
    margin: 31px 0px 59px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
}

.exportBtnClick {
    border: 1px solid #2F4B82;
    border-radius: 4px;
    width: 113px;
    height: 30px;
    height: fit-content;
    margin-top: 24px;
    margin: 16px;
}

.exportBtnClick .exportBtnClick-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 11px ;
    line-height: 15px;
    color: #2F4B82;
    margin-left: 12px;
}

.Reports-graph_table>span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #45464E;
    margin: 24px 16px 16px 16px;
}

.usageReports-FleetDashboard {
    width: 100%;
    height: auto;
    overflow-y: auto;
    border: 1px solid #EBEBEB;
}

.usageReports-FleetDashboard::-webkit-scrollbar {
    width: 6px;
}

.usageReports-tableHead .UsageReports-tableRow {
    background: #f5f5f5 ;
    height: 35px;
    border: 1px solid #EBEBEB ;
    box-sizing: border-box;
    margin: 0px;
    position: sticky;
    top: -0.5px;
    z-index: 11;
}

.UsageReports-tableRow .usage-fleetDataTable {
    color: #8B8D97;
    /* border: 1px solid #EBEBEB; */
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    width: 11%;
    padding: 0;
}

.UsageReports-tableRow .user-fleetDataTable {
    width: 16%;
    height: 35px;
    border: 1px solid #EBEBEB;
    text-align: center;
    color: #8B8D97;
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 1px;
}

.UsageReports-tableRow .userName-fleetDataTable {
    width: 15%;
}

.UsageReports-tableRow .total-fleetDataTable {
    width: 6%;
}

.UsageReports-tableRow .ip_port-fleetDataTable {
    width: 8%;
}

.UsageReports-tableRow .vlan-fleetDataTable {
    width: 10%;
}

.UsageReports-tableRow .topApp-sitestable {
    width: 6%;
}

.UsageReports-tableRow .topApp-sitestable-user {
    width: 8%;
}

.usageData-tabelRow .usagedata-tableCell{
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 5px;
}

.usage-tableBody .usageData-tabelRow td {
    padding: 5px ;
}

.userDataRightContainer .UsageLineChartComponent {
    margin: 24px 10px 0px 0px;
    width: 98%;
    padding: 0;
}

.userDataRightContainer .Usage-AppUsageComponent {
    margin: 24px 0px 0px 16px;
}

.Usage-AppUsageComponent .appUsageParent {
    width: 100%;
    margin-left: -2px;
    height: 407px;
}

.TopUsersConatiner .usage-pagination {
    padding-bottom: 15px ;
    border-top: 1px solid #EBEBEB;
}

.usageDetails-cards {
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 24px 10px 10px 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.usage-selectedTabs {
    margin: 13px 20px;
    display: flex;
    justify-content: space-between;
}

.usage-selectedTabs .usageActiveAppClass {
    width: 135px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveAppClass {
    width: 135px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-selectedTabs .usageActiveDomainClass {
    width: 115px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveDomainClass {
    width: 115px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-selectedTabs .usageActiveIpClass {
    width: 135px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveIpClass {
    width: 135px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-selectedTabs .usageActiveCFClass {
    width: 135px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-selectedTabs .usageInactiveCFClass {
    width: 135px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.cardsParentComp {
    margin: 24px 20px;
}

.appUsageParent p {
    margin-top: -1px;
    margin-bottom: 3px;
    width: 332.33px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #8B8D97;
}

.selectUserTag-bottomTable .options-bottomTable {
    min-width: 70px;
}

.usageData-tabelRow .selected-site {
    cursor: pointer;
    color: #0000ff;
}

.usageData-tabelRow .siteName-tableCell {
    width: 16%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.UsageReports-tableRow .topApp-icons-sitestable {
    width: 5%;
}

.UsageReports-tableRow .site-fleetDataTable {
    width: 15%;
}

.usageData-tabelRow .tableSites-topIcons {
    display: flex;
    gap: 5px;
    width: 85%;
    margin: auto;
}

.usageData-tabelRow  .css-1ex1afd-MuiTableCell-root {
    padding: 5px;
}

.pieChart-entryLimit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.TopUsersConatiner .activity-usage {
    margin-bottom: 0px;
}

.tableSites-topIcons .activity-logos {
    width: 18px;
}

.Usage-TableComponent .topSites-download {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.align-text-center {
    align-self: center;
}

.marg-left-auto {
    margin-left: auto;
}

.risk-score-container .MuiDialog-paperWidthMd {
    width: 350px;
}

.risk-score-container .risk-score-title {
    width: 300px;
}

.risk-score-container .risk-score-content {
    margin-top: 15px;
    line-height: 17px;
}

.risk-score-container .remove-margin-top {
    margin-top: 20px;
}

.risk-score-container .table-risk-list {
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
}

.risk-score-content .risk-list-loading {
    margin-top: 20px;
}

.usageData-tabelRow .risk-score {
    cursor: pointer;
    color: #0000ff;
}

.usage-cardmain .widget-risk-tooltip {
    display: flex;
    align-items: center;
    gap: 5px;
}

.widget-risk-tooltip>div {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
}

.UsageReports-tableRow .add-cursor-threat {
    cursor: pointer;
}

.topBlockedUsage .widget-risk-score-number:hover {
    color: #0f09ff;
    cursor: pointer;
}
 
.risk-score-card .reports-totalDetails .netify-cardFilter {
    color: #0f09ff;
    cursor: pointer;
}

.topBlockedUsage .riskScore-riskLevel {
    font-weight: 700;
    font-size: 14px;
}

.activity-can-user-info {
    font-size: 10px;
    margin: 5px 0px 0px 5px;
    color: #45464E;
}

.Usage-TableComponent .usageData-tabelRow .usagedata-tableCell {
    text-align: left;
    padding-left: 16px;
}

.Usage-TableComponent .font-wt-900 {
    text-align: left;
    padding-left: 16px;
}

.Usage-TableComponent .usageReports-tableHead .font-10px {
    font-size: 10px;
    font-weight: 600;
}

.TopUsersList-Reports .width-13-per {
    width: 13%;
}

.users-activity-table .new-summary-left {
    width: 7%;
}

.usageReports-tableHead .user-device {
    width: 23%;
}