.charts {
    width: 100%;
}

.tab-container {
    background: #FFFFFF;
}

.topologyConatiner {
    background: #FFFFFF;
}

.topology--tabs {
    margin-top: 15px;
}
.buttonCssFleet_width.help{
    float: right;
    margin-right: 40px;
    margin-top: 3px;
    padding: 0px;
    cursor: pointer;
}
.buttonCssFleet_width.help:hover{
    background-color: transparent;
}
.helpIcon{
    transform: scale(1.2);
    color:#2860B4
}

.mr-15{
    margin: 15px;
}

.back-btn-topology .margin-left15 {
    margin-left: 19px;
}

.dis-flex {
    display: flex;
}

.topology-back {
    color: #fff;
    cursor: pointer;
}

.align-back {
    margin-left: 15px;
    vertical-align: middle;
    align-self: center;
    cursor: pointer;
}

.ha-scroll {
    height: calc(100vh - 185px);
    overflow-y: scroll;
}