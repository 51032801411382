.user-entered-config-name input {
    padding: 8px;
    width: 170px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #676984;
}

.inventory-deploy-backup-config-popup .actions .config-create-btn {
    width: auto;
    background-color: #264C86;
}

.inventory-deploy-backup-config-popup .actions .config-cancel-btn {
    border: 1px solid #264c86;
    color: #264c86;
}

.inventory-deploy-backup-config-popup .actions .config-create-btn,
.inventory-deploy-backup-config-popup .actions .config-cancel-btn {
    height: 35px;
    text-transform: uppercase;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

.inventory-deploy-backup-config-popup .actions .config-create-btn.disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}