.forgotText {
  font-size: 12px !important;
  line-height: 14px;
  /* margin-left: 30px !important; */
  display: flex;
  align-items: center;
  margin-top: 30px !important;
  color: #676984;
}

@media only screen and (max-width: 550px) {
  .forgotText {
    margin-left: 25px !important;
  }
}

@media only screen and (min-width: 600px) {
  .forgotText {
    margin-left: 30px !important;
  }
}

.ResetButtonCss.MuiButton-root:hover {
  background: #264c86 !important;
}

@media only screen and (max-width: 550px) {
  .ResetButtonCss {
    width: 92%;
    max-width: 350px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 600px) {
  .ResetButtonCss {
    width: 160px;
  }
}

.ResetButtonCss {
  /* width: 160px; */
  font-size: 11px;
  text-transform: uppercase;
  background-color: #264c86 !important;
  border: 1.5px solid #264c86;
  color: #ffffff !important;
  cursor: pointer;
}

.SuccessMessagePopUp .MuiPaper-rounded {
  border-radius: 20px !important;
}

.successIcon {
  width: 60px;
  height: 60px;
}

.ImageCss {
  justify-content: center;
  display: flex;
  align-items: center;
}

.dialogTitleForgetPassword {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #273050;
}

.dialogTextForgetPassword {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #273050 !important;
  opacity: 0.75 !important;
  margin-top: 10px !important;
}

.dialogButtonForgetPassword {
  background: #264c86 !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 12px !important;
  text-align: center !important;
  letter-spacing: 0.035em !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  width: 95px !important;
  height: 36px;
}

.MuiTextField-root.input-box-fgt-pass {
  margin: 8px;
  width: 100%;
  max-width: 592px;
  margin-top: 40px;
}