.starlink--reports--usage_chart--container .highcharts-container, .starlink--reports--usage_chart--container .highcharts-root {
    width: 100% !important;
}

.plan-starlink--reports--usage_chart--container .highcharts-container, .plan-starlink--reports--usage_chart--container .highcharts-root {
    height: calc(100vh - 440px);
}

.MuiTextField-root.input-box-starlink-rp {
    width: 200px;
}

.starlink--base--flex--row .font-20 {
    font-size: 20px;
}

.Usage-ServiceLines .sl-cardtitle {
    color: #2e1a70;
    font-size: 9px;
    font-weight: 700;
    line-height: 11px;
    padding-top: 5px;
    letter-spacing: .175em;
    padding-left: 5px;
}

.cardHeader-serviceLines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 5px;
}

.cardHeader-serviceLines>span:nth-child(2) {
    margin-right: 5px;
}

#selectedClick-column {
    cursor: pointer;
    color: #4c3aff;
}


.star-usage-Dashboard-Container {
    margin: 15px 15px 15px 25px;
}

.star-usage-sub-tabs-container {
    display: flex;
    gap: 5px;
    padding-left: 16px;
}

.star-usage-sub-tabs-container .str-usage-sub-tabs {
    text-transform: none;
    border: 1px solid rgba(36, 76, 132, 1);
    height: 32px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    width: 120px;
}

#selectedTab-background {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #eff1f0;
}

.download-expand-icons-container {
    display: flex;
}

.download-expand-icons-container img {
    cursor: pointer;
}

.download-expand-icons-container .margin-left-6 {
    margin-left: 6px;
}

.star-usage-Dashboard-Container .star-usage-cards-container {
    padding-top: 5px;
}

.card-box-parent .star-usage-card-contents {
    justify-content: space-between;
}

.star-usage-card-contents .content_bottom_ns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.star-usage-card-contents .no-padding-margin {
    margin: 0;
    padding: 0;
}

.content_bottom_ns .right-content-value {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: rgba(69, 70, 78, 1);
}

.star-usage-filters-popup-conatiner .MuiPaper-root {
    width: 276px;
    padding: 10px;
}

.star-usage-bar-chart-icons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.hide-axis-line .highcharts-axis-line {
    display: none;
}

.star-usage-bar-chart-icons img {
    cursor: pointer;
}

.filter-dateRange-container{
    width: 1500px;
}

/* Filters */

.l-shape-container {
    display: grid;
    grid-template-columns: 25vw 25vw auto;
    grid-template-rows: auto auto;
    max-width: 305px !important;
}

.l-shape.upper {
    border-top: 1px solid rgba(36, 76, 132, 1);
    border-left: 1px solid rgba(36, 76, 132, 1);
    border-right: 1px solid rgba(36, 76, 132, 1);
    border-radius: 5px 5px 0 0;
    margin-top: 8px;
}

.analytics-top-filters-container .other {
    padding: 8px;
}

.Starlink-Usage-Container .l-shaped-filters {
    position: fixed;
    top: 194px;
    left: 231px;
    width: 263px;
    background: white;
    z-index: 1000;
    padding: 12px;
    border: 1px solid rgba(36, 76, 132, 1);
    border-radius: 0 4px 4px 4px;
    padding-bottom: 10px;
}

.l-shaped-filters::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0px;
    width: calc(100% - 212px);
    height: 2px;
    background: white;
}

.l-shape-container #filterButton {
    width: unset;
    border: none;
    padding: 11px;
}

.l-shape-container .searchbarBox .MuiOutlinedInput-notchedOutline {
    border-radius: 5px !important;
    border: 1px solid rgba(0, 0, 0, 1) !important;
}

.Starlink-Usage-Container .download-expand-icons-container {
    display: flex;
    position: fixed;
    right: 27px;
    top: 150px;
}

.download-expand-icons-container img {
    cursor: pointer;
}

.download-expand-icons-container .margin-left-6 {
    margin-left: 6px;
}

.starlink--tab_panel--container .Starlink-Usage-Container .analytics-sub-tabs-container {
    display: flex;
    align-items: center;
    gap: 7px;
    padding-left: 76px;
    max-width: calc(100vw - 617px) !important;
}

.analytics-sub-tabs-container .analytics-sub-tabs {
    text-transform: none;
    border: 1px solid rgba(36, 76, 132, 1);
    height: 32px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}

#selectedTab-background {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #eff1f0;
}

.new-summary-control-limit .starlink-usage-pie {
    margin-left: 5px;
}

.hide-axis-line .highcharts-axis-line {
    display: none;
}

.Starlink-Usage-Container .usage-details-pie-perSite {
    max-height: 95px;
    /* max-height: calc(100vh - 655px); */
    overflow: auto;
}

.Starlink-Usage-Container .starlink-usage-pie-chart {
    margin: 0px;
}

.Starlink-Usage-Container .iconColor-select {
    cursor: auto;
}

.Starlink-Usage-Container .iconColor-unselect {
    cursor: pointer;
}

.Starlink-Usage-Container .iconColor-select {
    filter: invert(10%) sepia(100%) saturate(5000%) hue-rotate(220deg) brightness(150%) contrast(390%);
}

.Starlink-Usage-Container .iconColor-unselect {
    filter: grayscale(100%);
}

.Starlink-Usage-Container .usage-details-pie-perSite .MuiSvgIcon-root {
    font-size: 9px;
}

.Starlink-Usage-Container .cards>div {
    padding-top: 5px;
}

.Starlink-Usage-Container .cards {
    padding-bottom: 2px;
}

.Starlink-Usage-LineChart .highcharts-container {
    height: calc(100vh - 453px) !important;
    min-height: 230px;
}

#Starlink-Usage-PieChart .highcharts-container {
    height: calc(100vh - 565px) !important;
}

#Starlink-Usage-ColumnChart .highcharts-container {
    height: calc(100vh - 471px) !important;
}

#Starlink-Usage-ColumnChart .highcharts-root {
    height: calc(100vh - 466px) !important;
}

#Starlink-Usage-PieChart+.usage-details-pie-perSite {
    margin-top: 0;
    margin-bottom: 0;
}