.inventoryListContainer {
    background-color: #fff;
}

.inventoryListDataContainer {
    width: 100%;
    /* padding-left: 80px; */
}
  
#inventoryListTabs {
    width: 100%;
}

#inventoryListTabs > div {
    margin-left: 5px;
}

#inventoryListTabs button:first-child {
    border-radius: 8px  0px 0px 8px;
    border: 1px solid #C7DAEB;
    margin-left: 25px;
}

#inventoryListTabs button:last-child {
    border-radius: 0px  8px 8px 0px;
}

#inventoryListTabs .activeTab {
    background: #2860B4; 
    color: #ffffff;
    border: 1px solid #C7DAEB;
}

#inventoryListTabs .inActiveTab {
    background: #ffffff;
    color: #676984;
}
#inventoryListTabs button {
    border: 1px solid #C7DAEB;
    width: 10%;
    border-radius: 0px;
}
.inventory-top-widgets {
    display: flex;
    grid-column-gap: 50px ;
}
.inventory-top-widgets > div {
    margin-top: 15px;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    overflow: hidden;
    text-align: center;
}
#inventoryListTabs .inventoryContailer {
    margin-left: 32px;
}
.inventory-top-widgets .key {
    color: #2e1a70;
    font-size: 13px;
    font-weight: 700;
    line-height: 11px;
    letter-spacing: .175em;
    padding: 15px 0px 10px 0px;
}
.inventory-top-widgets .value {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    padding: 0px 0px 15px 0px;
}
.inventory-content .inventoryContailer .FleetDashboard {
    max-height: 70vh;
}
.inventory-content .quota_management--dashboard {
    margin: 15px 20px 0px 20px;
    max-height: 70vh;
    padding: 0px;
}
.inventory-content .quota_management--dashboard .config-status-table-fix {
    height: calc(100vh - 335px);
    overflow-y: auto;
}
.inventory-content .quota_management--base--flex--justify--end{
    justify-content: left;
    background-color: #FFFFFF;
    padding: 0 0 15px 0;
}
.inventory-content .quota_management--base--flex--justify--end > div {
    border-radius: 10px;
}
.inventory-content .quota_management--dashboard table thead th{
    color: #8b8d97;
    letter-spacing: 1px;
    font-family: roboto mono;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    height: 35px;
}
.inventory-content .quota_management--dashboard table tbody td {
   padding: 5px 15px;
   font-size: 12px;
   color: #45464E;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 400;
   letter-spacing: 1px;
   height: 40px;
}
.inventory-content .quota_management--dashboard table tbody td button {
    padding: 0;
    margin-top: -2px;
}
.inventoryListContainer {
    background-color: white;
    height: 100vh;
}

.inventory-config-tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0px 20px 0px;
}

.inventory-config-tabs .backupConfig-active-tab {
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 1px;
    color: #0E0548;
    background-color: #fff;
    font-weight: 600;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.inventory-config-tabs .backupConfig-inactive-tab { 
    font-size: 12px;
    width: 100%;
    margin: auto;
    text-align: center;
    height: 26px;
    border-radius: 1px;
    color: #6E7079;
    background-color: #fff;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}
.default-manual-config-table-data {
    height: calc(100vh - 235px);
    overflow: auto;
    margin-bottom: 20px;
}
@media only screen and (max-height: 600px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 48vh;
    }
}
@media only screen and (min-height: 600px) and (max-height: 625px) {
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 49vh;
    }
}
@media only screen and (min-height: 625px) and (max-height: 700px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 55vh;
    }
}
@media only screen and (min-height: 700px) and (max-height: 850px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 56vh;
    }
}
@media only screen and (min-height: 850px) and (max-height: 900px){
    .inventory-content .inventoryContailer .FleetDashboard{
        max-height: 64vh;
    }
}

.inv-index {
    z-index: 1000;
}