.clock {
    margin-top: 6px;
    font-size: 11px;
    font-family: 'Inter';
    cursor: pointer;
    font-weight: 500;
    color: rgba(110, 112, 121, 1);
    position: fixed;
    right: 186px;
    display: flex;
    align-items: center;
}

.clock>span:nth-child(1) {
    margin-right: 14px;
    text-decoration: underline;
}

.current-date-time {
    width: 115px;
    font-family: monospace;
}