.charts-filters-topbar {
    display: flex;
    width: 100%;
    margin-right: 15px;
    justify-content: space-between;
    align-items: center;
}

.charts-filters-topbar>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.charts-events-btns button {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    padding-top: 13px;
}

.charts-events-btns .selectedTab {
    color: #1B51A7;
    border-bottom: 2px solid #1B51A7;
    border-radius: 0px;
    padding-bottom: 9px;
    text-transform: none;
}

.charts-events-btns .unSelectedTab {
    color: #8B8D97;
    padding-bottom: 9px;
    text-transform: none;
}

.maps-charts-filters-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 18px 0px 5px 10px;
}

.maps-charts-filters-container button:nth-child(1) {
    height: 36px;
    border-radius: 4px;
    padding: 8px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.maps-charts-filters-container .MuiButton-outlinedPrimary {
    color: #1B51A7;
    border: 1px solid #244C84;
    width: 85px;
    margin-bottom: -5px;
}

.mapCharts__filters-reset-zoom .MuiFormControlLabel-label {
    color: #45464E;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;    
}

.maps-charts-dialogue .css-ypiqx9-MuiDialogContent-root {
    padding: 0px !important;
}

.maps-charts-dialogue .MuiDialog-paperScrollPaper {
    /* width: 675px; */
    max-height: calc(100vh - 190px);
    width: 650px;
    position: fixed;
    top: 140px;
    right: 35px;
}

.maps-charts-dialogue .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    position: relative;
}

.maps-charts-dialogue .MuiBackdrop-root, .maps-charts-dialogue .MuiModal-backdrop, .maps-charts-dialogue .css-919eu4 {
    position: relative;
}

.maps-charts-filters-container .padding-left-25px {
    padding-left: 27px;
}

.maps-charts-filters-container .padding-right-22px {
    margin-left: -16px;
}

.maps-charts-filters-container .MuiOutlinedInput-root {
    height: 36px;
}

.map-charts-highchart .highcharts-root {
    margin-top: -15px;
}

.maps-charts-dialogue {
    margin-top: 220px;
    margin-right: 35px;
    width: 45%;
    margin-left: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.maps-charts-dialogue .css-ypiqx9-MuiDialogContent-root {
    overflow: hidden;
}

.maps-charts-dialogue .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5 ;
}

.chartEvent-FleetDashboard .maps-charts-fleetDataTableRow th {
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid #EBEBEB;
}

#maps-sdwan-charts-btns .New-UX-NavigationTabs-Conatiner {
    margin-top: 0px;
    padding-left: 20px;
    border: 1px solid #ebebeb;
}

.chartsEventTableContainer .chartsEventTable {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}