/* .buttonCssFleet {
    width: 33%;
    height: 38.56px;
    margin-top: 8px !important;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-sizing: border-box;
    text-transform: capitalize !important;
} */

.account-create-button {
  max-width: 159.95px;
  width: 100%;
  height: 37px;

  background-color: #264c86 !important;
  border-radius: 5px!important;
  color: #ffffff!important;
  text-transform: uppercase!important;

  font-style: normal!important;
  font-weight: bold!important;
  font-size: 10px!important;
  line-height: 12px!important;

  text-align: right!important;
  letter-spacing: 0.035em!important;
  margin-right: 10px !important;
}

.account-create-button-disable {
  max-width: 159.95px;
  width: 100%;
  height: 37px;
  border: 1px solid #264c86 !important;
  background-color: #e8f0fd !important;
  border-radius: 5px !important;
  color: #264c86 !important;
  text-transform: uppercase !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 12px !important;

  text-align: right !important;
  letter-spacing: 0.035em !important;
  margin-right: 10px !important;
}

.DPvesselDropdown .create-DPVesselName {
    box-shadow: none;
}

.Form .create-DPvesselDropdown {
    margin-top: 5px;
    margin-left: 5px;
}

.childDP-create p {
    color: #6c6b85;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.SearchbarTextField .createDP-serachBarWarehouse {
    width: 340px;
    padding-bottom: 3px;
    margin-left: -7px;
}

.SearchbarTextField .dp-assignSearchIcon {
  margin-top: 6px;
}

.clear-create-subDp-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 35px;
}

.enable-disable-two2fa-div {
  width: 100%;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 15px;
}

.clear-create-subDp-div .clearSubDP-button ,.enable-disable-two2fa-div .clearSubDP-button{
    width: 100px;
    background: #ffff; 
    color: #264c86;
    cursor: pointer;
    height: 35px;
    margin-left: 35px;
    border: 1px solid #264c86;
    font-size: 12px;
    font-weight: bold;
}

.clear-create-subDp-div .createSubDP-button {
    width: 100px;
    background: #2860B4; 
    color: #ffffff;
    cursor: pointer;
    height: 35px;
    margin-left: 5px;
    border: 1px solid #264c86;
    font-size: 12px;
    font-weight: bold;
}

.enable-disable-two2fa-div .twoFaaSubDP-button {
  background: #2860B4; 
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  margin-left: 5px;
  border: 1px solid #264c86;
  font-size: 12px;
  font-weight: bold;
}

.childDP-create .SearchbarTextFieldBox-create {
    margin-top: 0px;
}

.subdp-create-assignDialog {
  max-width: 500px;
  margin: auto;
}

.subdp-create-assignDialog>div:nth-child(1) {
  width: 100%;
}

.mainAssignActiontitle>div:nth-child(2) {
  margin-left: auto;
}

.tkt_new_ticket_dialogContent {
  align-items: center;
}

.cretesubDP .cursorPointer {
  position: absolute;
  right: 12px;
  font-size: 12px;
}

.OuMenuChild .createDp-checkIcon {
  color: #fff;
  background: #2860B4;
  font-size: 12px;
}

.dp-dropdown-dp-create {
  font-size: 14px;
}

.childDP-create-name p {
  margin-left: 0px;
}

.SearchbarTextFieldBox .Mui-error.MuiFormHelperText-sizeSmall{
  width: 300px;
}

.Form .Login-History-Page-iam {
  padding-left: 30px;
  padding-bottom: 112px;
  background: #FFFFFF;
  height: 100vh;
}

.notAuthorized .notAuthorizedPage {
  margin-top: 80px;
}

.pagination-main {
  margin: 10px 25px 0px 25px;
}

.Accounts-NavigationBtns {
  width: 100%;
  height: 48px;
  display: flex;
  margin-top: 100px;
  /* margin-left: 90px; */
  z-index: 1000;
  background: white;
  justify-content: space-between;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.Accounts-navbarBtns {
  margin-left: 21px;
  /* padding-top: 5px; */
}

.Accounts-NavigationBtns .usage-buttonCssFleet {
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom:  2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .simAnalysis-buttonCssFleet {
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom: 2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .dataUsage-buttonCssFleet {

  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom: 2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .graphicalDataUsage-buttonCssFleet {
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
  border-bottom: 2px solid #2860B4;
  color: #2F4B82;
}

.Accounts-NavigationBtns .inActiveUsage-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-NavigationBtns .inActiveSimAna-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-NavigationBtns .inActiveDataUsage-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  width: 100px;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-NavigationBtns .inActiveGrapDataUsage-buttonCssFleet {
  border-bottom: #ffffff;
  color: #8B8D97;
  padding-top: 16px;
  border-radius: 0px;
  text-transform: none;
}

.Accounts-navbarBtns .Accounts-tab{
  padding-bottom: 5px;
}

.MuiTextField-root.input-box-accnt-mgnt {
  margin: 8px;
  width: 100%;
  max-width: 320px;
  margin-left: 22px;
  margin-top: 20px;
}

.MuiTextField-root.input-box-accnt-mgmts {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}

.SearchbarTextFieldBox-create {
  margin-left: 10px;
}

.align-item-center {
  align-items: center;
}

.align-item-center .marg-l-auto {
  margin-left: auto;
}

.align-item-center .marg-r-20 {
  margin-right: 25px;
}

.subdp-create-assignDialog .edit-org-btns-div {
  padding-left: 45px;
  display: flex;
  justify-content: center;
}

.edit-org-btns-div button {
  font-size: 12px;
  text-align: center;
  height: 26px;
  box-shadow: 0 0 13px hsla(0, 0%, 79.6%, .35);
  border-radius: 1px;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
}
  
.active-cteare-org-accounts {
  border: none;
  background: #fff;
  color: #0e0548;
  border-bottom: 2px solid #2860b4;
  width: 85px;
}

.inactive-create-org-accounts {
  border: none;
  color: #8b8d97;
  background: #f8f8f854;
  width: 85px;
}

.alignTopbarItemsCenter .ac-searchbar-padding-width {
  padding-left: 25px;
  max-width: 355px;
}