.usageOverTimeMainContainer {
    width: 100%;
    height: auto;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    font-family: 'Inter';
    font-style: normal;
}

.usageOverTimeContainer {
    width: 100%;
    height: fit-content;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.usageOverTimeContainer>div:nth-child(1) {
    margin: 19px 0px 15px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #45464E;
    width: fit-content;
}

.usageDetails-color{
    width: 20%;
    display: flex;
    gap: 3%;
    margin: auto;
    align-items: center;
}

.usageDetails-color>div {
    display: flex;
    width: 33%;
    height: 35px;
    font-size: 11px;
    text-align: center;
}

.totalUsageColor {
    background: #3C7DD9;
    width: 8px;
    height: 8px;
    margin: 7px 4px 0px 0px;
    border-radius: 20px;
}

.name-color .lineChart-legends {
    text-align: center;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 10px;
    color: #0e0548;
    padding-top: 6px;
    cursor: pointer;
}

.totalincomingColor {
    background: #C1D5F6;
    width: 9px;
    height: 9px;
    margin: 7px 4px 0px -9px;
    border-radius: 20px;
}

.totalOutgoingColor {
    background: #2039B5;
    width: 9px;
    height: 9px;
    margin: 7px 4px 0px 0px;
    border-radius: 20px;
}

.usage-lineChartTabs {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.usage-lineChartTabs .usageActiveClass {
    width: 105px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-lineChartTabs .usageInactiveClass {
    width: 105px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.usage-lineChartTabs .topUsageActiveClass {
    width: 150px;
    height: 27px;
    border-bottom: 2px solid #0E0548;
    border-radius: 0px;
    color: #0E0548;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    text-transform: none;
}

.usage-lineChartTabs .topUsageInactiveClass {
    width: 150px;
    height: 27px;
    border-radius: 0px;
    color: #8B8D97;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    text-transform: none;
}

.topApplicationNames {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 8px;
    margin:auto;
    padding-bottom: 15px;
}

.name-color {
    display: flex;
}

.usageOverTimeContainer .resetZoom-usage {
   width: 360px;
   margin: 10px 15px 0px 0px;
   display: flex;
   justify-content: flex-end;
}

.resetZoom-usage .resetZoomBtn-usage {
    color: #8B8D97;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #8B8D97;
    border-radius: 4px;
    height: 30px;
    width: 110px;
}

.resetZoom-usage .entryLimit-lineChart {
    margin-left: 25px;
}

.usageOverTimeContainer .capsule-lineChart {
    min-width: 110px;
    position: relative;
    top: -8px;
    left: 20px;
}

.capsule-lineChart .title-capsule-line {
    display: flex;
    justify-content: space-between;
    height: 23px;
}

.name-color .disable-lineChart-legends {
    color: #a2a2a6;
    text-align: center;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    padding-top: 6px;
    cursor: pointer;
}