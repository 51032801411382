@media only screen and (max-height: 400px){
  .FleetDashboard-softwareVersions {
    max-height: 140px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .FleetDashboard-softwareVersions {
    max-height: 185px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .FleetDashboard-softwareVersions {
    max-height: 235px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .FleetDashboard-softwareVersions {
    max-height: 285px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .FleetDashboard-softwareVersions {
    max-height: 335px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .FleetDashboard-softwareVersions {
    max-height: 385px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .FleetDashboard-softwareVersions {
      max-height: 430px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .FleetDashboard-softwareVersions {
      max-height: 470px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .FleetDashboard-softwareVersions {
      max-height: 520px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .FleetDashboard-softwareVersions {
      max-height: 570px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .FleetDashboard-softwareVersions {
      max-height: 630px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .FleetDashboard-softwareVersions {
      max-height: 680px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .FleetDashboard-softwareVersions {
      max-height: 730px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .FleetDashboard-softwareVersions {
      max-height: 780px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .FleetDashboard-softwareVersions {
      max-height: 830px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .FleetDashboard-softwareVersions {
      max-height: 860px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .FleetDashboard-softwareVersions {
      max-height: 920px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .FleetDashboard-softwareVersions {
      max-height: 980px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .FleetDashboard-softwareVersions {
      max-height: 1080px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .FleetDashboard-softwareVersions {
      max-height: 1600px;
      overflow-y: auto;
    }
  }

  .software-container {
    max-height: calc(100vh - 125px);
    margin-bottom: 10px;
  }

  .paddingRight {
    padding-left: 10px;
  }

.MuiTextField-root.input-box-sw-vt {
  margin: 8px;
  width: 100%;
  max-width: 300px;
  margin-left: 0px;
}