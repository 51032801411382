.audit-data-container .audit-logs-font,
.audit-filters-container .audit-logs-font {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 15px;
    color: #45464E;
}

.audit-data-container .audit-logs-font-header {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    color: #45464E;
}

.audit-filters-container .audit-filter{
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 500;
    line-height: 20px;
    color: #45464E;
}

.audit-filter-items .auditFilterBorderColor {
    border-color: #EBEBEB;
}

.audiFilterCloseIcon {
    position: absolute;
    left: 238px;
    cursor: pointer;
}
.audit-container {
    padding: 10px 13px;
    margin-top: 75px;
    height: calc(100vh - 150px);
    overflow-y: scroll;
}

.audit-layout {
    background-color: white;
    /* position: absolute;
    left: 85px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:40px 5px 5px 5px;
}

.audit-pop-up-height .MuiGrid-root {
    width: 274px;
    padding-top: 0px;
}

.audit-logs-font .auditFilterActions {
    height: 16px;
    padding-bottom: 8px;
}

.audit-logs-font .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

.audit-logs-font .MuiFormControlLabel-label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
}

.audit-filter-list {
    display: flex;
    align-items: center;
    padding: 10px 16px 18px 16px;
}

.audit-filter {
    font-size: 14px;
}

.audit-data-container .audit-filter-list .audit-filter {
    font-size: 12px;
    font-weight: 600;
}

.audit-filter-list .filter-clear-all {
    margin-left: auto;
    color: #2F4B82;
    text-transform: none;
    text-decoration: none;
}

.expand-accordin-root.MuiAccordion-root {
    width: 100%;
}

.expand-summary .expand-summary-content,
.expand-summary .expand-summary-content.Mui-expanded {
    margin: 5px 0;
    color: #45464E;
}

.expand-summary.Mui-expanded[aria-expanded="true"] {
    min-height: 48px;
}

.audit-logs-font .filter-accordian {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #45464E;
    padding: 16px 16px 12px 16px;
}

.no-padding {
    padding: 0px;
}

.rc--form-control .no-padding {
    padding: 0px;
}

.padding-control .no-padding-top-btm {
    padding-top: 0;
    padding-bottom: 0;
}

.filter-accordian-label {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
}

.audit-log-item .filter-meta {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Inter';
}

.audit-filter-inputs .MuiInputBase-root {
    font-family: 'Inter';
    font-weight: 400;
    height: 34px;
    font-size: 12px;
    line-height: 14.52px;
    margin-bottom: 8px;
}

.audit-filter-inputs .MuiInputLabel-root {
    font-size: 12px;
}

.audit-data-head .audit-data-header {

    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    letter-spacing: 1px;
    font-variant: small-caps;
    /* Tertiary black */
    color: #8B8D97;
}

.audit-filters-container {
    /* display: flex;
    gap: 10px;
    flex-direction: column;
    height: calc(100vh - 80px); */
}

.filter_menu_container_auditlogs{
   margin: 110px 0px 0px 210px;
}

.filter_menu_container_auditlogs > .MuiPopover-paper {
    overflow-y: hidden;
    width: 274px;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}

.audit-btn-parent {
    display: flex;
    padding: 20px 16px 12px 16px;
}

.audit-btn-parent .auditClearButton {
    text-transform: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
}

.audit-btn-parent .audit-filter-apply {
    text-transform: none;
    background-color: #1B51A7;
    border-radius: 4px;
    height: 29px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 600;
    width: 103px;
    left: 60px;
}

.audit-filter-items {
    overflow-y: hidden;
}

.audit-table-data.MuiTable-root {
    overflow-y: auto;
}

.audit-table-data.MuiTable-root .MuiTableHead-root th {
    background-color: #f5f5f5;
}

/* .audit-table-data.MuiTable-root .MuiTableHead-root th:not(:first-child):not(:last-child),
.audit-table-data.MuiTable-root .MuiTableBody-root td:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
} */

.audit-data-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.audit-data-pagination {
    margin-top: auto;
}

.audit-log-item {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
}

.syntax-items {
    background-color: #F4F5FA;
    display: flex;
    width: 100%;
    min-height: 50px;
    position: relative;
    flex-direction: column;
    counter-reset: syntax-line-number;
    border-radius: 4px;
}

.syntax-items .syntax-row {
    counter-increment: syntax-line-number;
    display: grid;
    column-gap: 5px;
    grid-template-columns: minmax(15px, 25px) 1fr;
}

.syntax-items .syntax-row > pre {
    margin: 0;
}

.syntax-items .audit-syntax-copy {
    position: absolute;
    top: 15px;
    right: 15px;
}

.audit-syntax-copy .copy-audit-metas {
    background-color: #2F4B82;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-transform: none;
}

.syntax-items .syntax-row>* {
    padding: 1px;
    color: #6E7079;
    font-size: 12px;
    font-family: 'Inter';
}

.syntax-items .syntax-row:first-child>* {
    padding-top: 8px;
}

.syntax-items .syntax-row:last-child>* {
    padding-bottom: 8px;
}

.syntax-items .syntax-line-item {
    background-color: #E3E6F3;
    color: #2F4B82;
    user-select: none;
    text-align: center;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 12px;
}

.syntax-items .syntax-line-item::before {
    content: counter(syntax-line-number);
}

.copy-audit-metas .MuiButton-startIcon .copy-icon {
    font-size: 12px;
}

.accordian-expand .accordian-arrow {
    font-size: 16px;
    color: #6E7079;
}

.pagination-position {
    height: 40px;
}

.audit-container .audit-container-data {
    width: 100%;
    overflow: hidden;
    height: auto;
    overflow-y: auto;
    border: 1px solid #EBEBEB;
    border-radius: 0px;
    box-shadow: none;
}

.audit-table-root .audit-logs-no-data {
    font-size: 12px;
    font-family: 'Inter';
}

.audit-loader {
    position: absolute;
    top: 14px;
    right: 28px;
}

.audit-filter-items .audit-pop-up-height {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 330px);
    padding: 0px;
    max-height: 444px;
}