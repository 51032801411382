.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .scroll::-webkit-scrollbar-track {
    background: #c4c4c4;
    border: 0.5px solid #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #767bae;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px; */
    /* border: 3px solid orange; */
  }
  /* @media only screen and (min-width : 1200px) {
    .MuiInputBase-root{
        width: 323px !important ;
    }
}
   */