.hub_details_container {
    margin: 0px 15px 15px 15px;
    overflow-x: hidden;
}

.hub-main-table-container .audit-container-data {
    box-shadow: none;
}

.audit-container-data .hub-table-container {
    border: 1px solid rgba(224, 224, 224, 1);
    box-shadow: none;
}

.each-row-background .padding-left-right, .hub-expanded-row .padding-left-right {
    border-left: 1px solid rgba(235, 235, 235, 1);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: rgba(69, 70, 78, 1);
    padding: 3px 5px 3px 6px;
}

#hub-expanded-row-tr .padding-left-right {
    border-top: 1px solid rgba(235, 235, 235, 1);
}

.hub-expanded-row #add-padding-top-cell, #hub-add-padding-top {
    border-top: 1px solid rgba(224, 224, 224, 1);
}

.each-row-background .no-padding {
    padding: 0px;
    text-align: center;
}

.each-row-background .remove-border-btm {
    border-bottom: none;
    padding: 0px;
    text-align: center;
}

.hub-expanded-row .no-padding-top-bottom {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;
    border-top: none;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: rgba(69, 70, 78, 1);
    padding-top: 0;
    padding-bottom: 0;
}

.audit-data-head .background-header-cell {
    background: rgba(245, 245, 245, 1);
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #EBEBEB;
    color: rgba(139, 141, 151, 1);
    font-family: 'Roboto Mono';
    font-size: 12px;
    font-weight: 500;
    line-height: 15.83px;
    letter-spacing: 1px;
    text-align: left;
    height: 25px;
    padding: 5px 8px 5px 7px;
}

.each-row-background {
    background: rgba(249, 249, 249, 1);
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: rgba(235, 235, 235, 1);
}

.each-row-background .internet-status-color-hub, .each-row-content-status .internet-status-color-hub {
    width: 7px;
}

.each-row-background .hub-row-internetStatus, .each-row-content-status .hub-row-internetStatus {
    display: flex;
    align-items: center;
    gap: 1px;
    cursor: pointer;
    margin-left: 4px;
}

.padding-left-right .MuiCollapse-wrapperInner, .no-padding-top-bottom .MuiCollapse-wrapperInner, .each-row-background .padding-left-right {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alertsFilterContainerBox .hub-search-root {
    margin-left: 0px;
    margin-bottom: 16px;
}

.hub-redirectToOtherTabs {
    text-decoration: none;
    color: #3e21ee;
    cursor: pointer;
}

.audit-data-head .hub-width-internet-status {
    width: 6%;
}

.audit-data-head .hub-width-clients {
    width: 4%;
}

.each-row-background .remove-border-bottom-hub-cell, .hub-expanded-row #expand-icon-border, .audit-logs-font .clients-no-data {
    border-bottom: none;
}

.hub-expanded-row .remove-cursor-locationClick, .each-row-background .remove-cursor-locationClick {
    cursor: auto;
    text-decoration: none;
}

.interface-profile-popup .client-data-content {
    padding: 15px 0px 0px 20px;
}

.login-tableRow .clientData-speedHeader {
    text-transform: none;
    font-variant: none;
}

.login-tableRow .last-observed-width {
    width: 15%;
}

.login-left-align .client-data-wan-status {
    align-items: center;
    display: flex;
    gap: 3px;
}

.audit-logs-font .hub-empty-table-row {
    height: 16px;
}

.audit-logs-font .hub-empty-table-cell {
    border: none;
}

.pop-head .hub-clients-header {
    color: black;
    font-size: 14px;
}

.hub-clients-header .hub-clients-header-grid {
    margin-left: auto;
}

.hub-clients-header-grid img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: black;
    border: none;
}

.hub-table-header .sort-icon-margin {
    margin-left: 3px;
}

.hub-table-header .arrow-column {
    width: 1%;
}
.hub-table-header .hub-width-portal {
    width: 3.5%;
}
.hub-table-header .hub-width-tunnel-status {
    width: 4%;
}
.hub-table-header .hub-width-throughput {
    width: 7%;
}
.hub-table-header .hub-width-mpk-status {
    width: 5%;
}
.hub-table-header .hub-width-usage {
    width: 6%
}
.hub-table-header .hub-width-users {
    width: 3%;
}

.fleetDataTable .hub-sorting-columns {
    /* cursor: pointer; */
    text-transform: none;
}

.hub-main-table-container .hub-table-search-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hub-table-search-switch .toggle-btn {
    font-size: 12px;
    font-style: normal;
    font-family: "Inter";
    font-weight: 500;
}

.hub-main-table-container .hub-container-data {
    overflow-x: hidden;
}

.audit-logs-font .hub-agent-view-table-row td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 5px 5px 5px;
    font-weight: 400;
}

.hub-container-data .hub-topology-service-table-headers th {
    padding: 5px 5px 4px 15px;
}

.hub-topology-parent-container {
    height: calc(100vh - 170px);
    overflow: auto;
}

.hub-topology-sdwan-dropdowns-div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.hub-table-container .topology-agent-table-table-row th {
    padding: 5px 15px;
}

.audit-logs-font .hub-topology-agent-view-table-row td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 15px;
    font-weight: 400;
}