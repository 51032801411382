.map__container {
    width: 100vw;
  }

.header {
    display: flex;
    text-align: center;
    background-color: white;
    color: black;
    margin-left: 74px;
  }  

  .legend-network {
      display:flex
  }

/* footer {
  display: flex;
} */

.legendCard {
  height: 100px;
  padding-left: 150px;
  z-index: 1000;
}

.myLocationIcon {
  color:black;
  margin-left: 10px;
}

.leaflet-div-icon {
  border: none !important;
  background: none !important;
}

.leaflet-div-icon  .vesselIcon {
  height: 15px;
}

.leaflet-div-icon  .flightaware {
  height: 24px;
}

.leaflet-div-icon .vesselRedIcon {
  height: 30px;
}

.leaflet-div-icon .starlinklBigIcon {

  height: 30px;
}

.leaflet-div-icon .vesselRedBigIcon {
  height: 30px;
}

/* .polyLine {
  stroke-width: 1;
} */

.innerHex {
  stroke-width: 0.1;
}

.vesselTrack {
  stroke-width: 1;
}

#MaroMuje {
  height: 100vh;
}

.popUpChart {
  position: fixed;
  z-index: 1000;
  background: white;
  right: 0;
  width: 500px;
  height: 80%;
}   

.hexPopup {
  width: 400px;
  height: 150px;
}

 .vesselBothPopUp .leaflet-popup-content-wrapper {
  width: 400px !important;
  border-radius: 4px;
  background: black;
  color: white;
  opacity: 0.7;
}

.onHoverNameHEAD {
  font-weight: bold;
}

.leaflet-popup-content-wrapper {
  width: 400px;
  border-radius: 4px;
  background: black;
  color: white;
  opacity: 0.7;
}

.popupHeader {
  display: flex;
}

.leaflet-tooltip {
  background: none;
  margin-left: 12px;
  border: none;
  box-shadow: none;
  border-right-color: none;
}

.leaflet-tooltip-right::before {
  display: none;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 75px;
}

.mapLegends {
  display:flex;
  margin-top: 10px;
}

.vesselLocPopUp .leaflet-popup-tip-container {
  display: none;
}

.leaflet-top .leaflet-control-zoom {
  margin-top: 15px;
  position: fixed;
  right: 10px;
}

.leaflet-control-zoom.leaflet-bar {
  border: 2px solid rgba(0,0,0,0.2) !important;
}

.leaflet-control-zoom + .leaflet-control.leaflet-bar {
  margin-top: 90px;
  position: fixed;
  right: 10px;
}

.vesselLocPopUp .onHoverName {
  font-size: 12px !important;
  font-weight: bold;
  display: flex;
}

.vesselLocPopUp .modemNames {
  display: flex;
  font-size: 12px;
}

.dot {
  border-radius: 50%;
  display: inline-block;
}

.modemContents {
  padding-left: 5px;
  padding-right: 5px;

}

.modName {
  font-size: 10px;
  padding-left: 1px;
  font-weight: bold;
}

.customButtons {
  margin: 10px !important;
}

.layersIcon {
  color: black !important;
}

.mapCustButtonsGroup  button{
  padding: 0px;
  border: none;
  background: white;
  min-width: 30px !important;
  margin-right: 2px;
}

.leaflet-bar {
  border: none !important;
}

.errorCard {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  margin-left: 92px;
  margin-bottom: 80px;
  z-index: 10000000;
  height: 60px;
  width: 200px;
  border-radius: 30px;
  padding: 10px;
  color: blue;
  font-size: 12px;
  box-shadow: 0px 0px 9px black;
  font-weight: 500;
}

.errorCardForTrack {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  margin-left: 180px;
  margin-top: 180px;
  z-index: 10000000;
  height: 15px;
  width: 999px;
  border-radius: 30px;
  padding: 10px;
  color: blue;
  font-size: 12px;
  box-shadow: 0px 0px 9px black;
  font-weight: 500;
}

.errorWord {
  color: red;
}

.layersIcon .vsatIc {
  margin-top: 10px !important;
}

.towerIcon {
  height: 15px !important;
}

.towerTabRowHeader .towerTabHeader {
  font-weight: 500;
}

 .leaflet-popup-content-wrapper {
  width: fit-content !important;
}
/* 
.leaflet-marker-icon {
  margin-left: -7px !important;
  margin-top: -7px !important;
}  */

.pointsTrackIcons .pointsValue {
  padding: 5px !important;
}

.pointsTrackIcons {
  position: absolute;
  left: 30px;
  top: 30px;
}

.selectedTrack {
  z-index: 10000;
}

.dotCircle {
  height: 10px;
  width: 10px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
}

.mapCustButtonsGroup #layersSel {
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 6px;
}

.vesselHoverTab {
  max-height: 400px;
  overflow-y: scroll;
}

.popupColumn {
  width: 50%;
  float: left;
}

.vesselTrackIndCont {
  display: flex;
  justify-content: left !important;
}

.vesselTrackIndSecond {
  height: 89px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  left: 739px;
  bottom: 10px;
  color: white;
  background: black;
  opacity: 0.7;
  z-index: 10000;
  padding: 1px;
}

.legendContainer {
  margin-left: 100px !important; /* Override conflicting styles */
}

.legendRow {
  margin-top: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.secondLegendRow {
  margin-top: 5px;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 5px;
}

.secondLegendRowData {
  align-items: center;
  margin-left: 5px;
  margin-bottom: 10px;
}


.legendLabel {
  font-size: 12px;
  font-weight: 500;
}

/* Adjustments for legend items */
/* .legendLabel,
.dotLegendSite,
.dotLegendStar {
  min-width: 35px;
  max-width: 35px;
  height: 15px;
  width: 15px;
  border-radius: 25%;
  display: inline-block;
  /* margin-left: 20px; */
  /* margin-right: 5px; */
  /* vertical-align: middle; */
/* } */
 

/* .dotLegendStar {
  margin-left: 26px !important;
} */

.legendLabelMainSite {
  margin-right: 10px;
  vertical-align: sub;
}

.siteLegendLabel {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 140px;
  min-width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteLegendLabelHead {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 60px;
  min-width: 60px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}

.siteLegendLabelAvail {
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 80px;
  min-width: 80px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteValueLabelAvail {
  font-size: 12px;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 80px;
  min-width: 80px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteValueLabel {
  font-size: 12px;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 140px;
  min-width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.siteValueLabelHead {
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 60px;
  min-width: 60px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}

.parentVesselTrackInd {
  display: contents;
}

.leaflet-popup-close-button {
  display: none;
}