.config-container .reports-login-history {
    width: 100%;
    padding: 24px 10px 0px 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    margin: auto;
    margin-top: 20px;
}

.config-container .trends-usage-table-container {
    padding: 0px;
    margin-top: 16px;
}

.TopUsersConatiner .loginHistory-tableContainer {
    margin-top: -8px;
    margin-bottom: 20px;
    height: auto;
}

.padding-left-5 {
    padding-left: 5px;
}

.reports-login-history>div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    align-items: flex-end;
}

.config-container .trends-usage-table-container .trends-usage-search-container {
    align-items: center;
    margin: 4px 0px -10px 0px;
}

.config-search-bar-text .login-history-search-bar {
    margin-top: 0px;
    height: 32px;
}

.login-left-align .login-history-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
}

.loginHistory-tableRow td {
    text-align: left;
    padding: 5px;
    padding-left: 15px;
}

.login-tableRow .login-tableCell {
    background: #f5f5f5;
}

.loginHistory-tableRow .login-left-align {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    background: #FFFFFF;
}

.loginHistory-tableRow .selectedClick {
    cursor: pointer;
    color: #4c3aff;
}

.reports-login-history .site-details-header {
    color: #45464E;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    margin: -10px 0px 19px 5px;
}

.reports-login-history .usage-site-details-header {
    margin: 0px 0px 0px 16px;
}

.new-summary-usage-tr .inter-font {
    font-family: 'Inter';
    font-weight: 500;
    padding: 5px 0px 5px 16px;
    font-size: 12px;
}

.reports-login-history .search-dropdown-limit-div {
    display: flex;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 10px;
    position: relative;
}

.search-dropdown-limit-div .search-list-new {
    display: flex;
    align-items: center;
}

.download-summary-btn {
    background-color: #244C84;
    border-radius: 4px;
    width: 109px;
    text-align: center;
}

.download-summary-btn button {
    color: #fff;
    text-transform: none;
    letter-spacing: unset;
    font-weight: 700;
    padding: 6px 8px;
    font-family: 'Inter';
}

.downloadBtnContainer {
    position: absolute;
    top: 10px;
    right: 0px;
}

.reports-login-history .usage-table-search-download-div {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.trendsAlertsSearchbarMargin {
    margin-right: 16px;
}

.trends-usage-search-container .usage-downloadBtnContainer {
    position: unset;
    margin-top: -5px;
}

.usage-table-search-download-div .margin-left-auto {
    width: 300px;
}

.downloadImg {
    vertical-align: middle;
}

.login-tableRow .new-summary-left {
    padding-left: 16px;
}

.TopUsersConatiner .new-usage {
    margin-bottom: 7px;
}

.TopUsersConatiner .remove-shadow {
    box-shadow: none;
    margin-bottom: 8px;
}

.marg-top-2 {
    margin-top: 2px;
}

.MuiTextField-root.input-box-uptime-tbl {
    margin: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
}

.MuiTextField-root.input-box-usage-tbl {
    margin: 8px;
    width: 300px;
    margin-left: 0px;
    height: 32px;
}

.trends-usage-search-container .usage-table-search-download-div {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.config-container .trends-usage-table-container .TopUsersConatiner {
    padding-left: 0px;
}

.remove-shadow .MuiToolbar-root {
    padding-left: 16px;
}

/* Alerts */

.new-summary-usage-tr .interface-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}