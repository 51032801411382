

.header-name {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
}

.rule-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6E7079;
}

.firewall-filters .firewall-stack-value {
    margin-bottom: 10px;
}

.rule-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.packet-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.doughnut-graph-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #45464E;
}

.margin-b-10 {
    margin-bottom: 10px;
    margin-top: 15px;
}

.margin-t-15 {
    margin-top: 15px;
}

.padding-15 {
    padding: 15px;
}

.firewall-table-data .firewall-table-cell {
    font-size: 12px;
    color: #45464E;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    /* border: 1px solid #EBEBEB; */
    padding: 10px 5px 10px 5px;
}

.firewall-tableRow .firewall-table-data {
    color: #8B8D97;
    border: 1px solid #EBEBEB;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    padding: 0;
}

.firewall-table-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.table-head-parent {
    margin: 15px 0px;
}

.table-head-parent .firewall-add-rule {
    float: right;
    height: 25px;
    width: 100px;
    background-color: #1B51A7;
    font-size: 12;
    border-radius: 4px;
    text-transform: none;
    margin: 15px 0px;
}

div.usage-doughnutChart {
    width: unset;
    height: unset;
    margin-left: 0;
}

.widgetContainer {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-family: 'Inter';
    align-items: center;
}

.padding-filters {
    padding-left: 8px;
}

.firewalLineChartContainer {
    width: 100%;
    padding: 20px 10px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
    font-family: inter;
    font-style: normal;
}

div.graph-totalUsageTitle {
    padding-top: unset;
}

.outer-datepicker {
    padding: 0px 10px;
}

.filter-reset-pos {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
    align-items: center;
}

.list-box-props {
    max-height: 200px;
    font-size: 13px;
}

.chip-margin {
    margin-left: 10px;
}

.filrewall-date-filter-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.deploy-inventory-content .fw-actions {
    margin-top: 0px;
}

.firewall-filters .rule-value, .firewalLineChartContainer .line-cardFilter, .firewall-table-data .table-cardFilter {
    color: #0f09ff;
    cursor: pointer;
}

.margin-t-15 .firewall-table-pagination .usage-pagination {
    min-height: 40px;
    margin-bottom: 0px;
}

.firewallReports-Filter .firewall-container-height {
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}

.topSites-download .firewall-table-download {
    display: flex;
    margin-top: 15px;
}

.filter-reset-pos #select-bin-remove-margin {
    margin-right: 0px;
}

.filter-reset-pos #select-bin-add-margin {
    margin-right: 10px;
}

.margin-left-60 {
    margin-left: 60px;
}

.deploy-inventory-content .fw-actions .select-action-warning {
    color: red;
    font-size: 11px;
    width: auto;
}