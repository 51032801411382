.starlink--service_accounts--valid_status.MuiSvgIcon-root {
    margin-right: 0.5rem;
}

.MuiTextField-root.input-box-service {
    width: 300px;
  }

  .starlink-account-help{
    cursor: pointer;
    color:#1976d2;
  }

  .starlink-account-infoIcon{
    color:#1976d2;
    margin-top: 5px;
  }