.verticalCenter{
    display: flex;
    align-items: 'center';
}

.usage_select{
    font-weight: 500;
    display: 'flex';
    align-items: 'center';
}

.usage_select_margin {
    margin-left: 45px;
}

.sel_padding{
    padding: 10px;
}


.exportIcon{
background-color: #2860b4;
margin: 4px;
border-radius: 4px;
padding: 6px 8px;
cursor: pointer;
color: white;
}

.exportUsage{
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #2860b4;
}

.container {
    max-height:'71vh';
    border-radius:'15px';
}

.d-flex{
    display: flex;
}

.align-center{
   align-items: center;
}

.justyfy-space-between{
    justify-content: space-between;
}

.dataUsage-justyfy-space-between {
    margin-top: 80px;
}

.justify-center{
    justify-content: center;
}

.usage_pagination{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.reportsDataHeader{
    width: 35%;
    display: flex;
    align-items: center;
}

.text-align-center{
    text-align: center;
}

.mt-2{
    margin-top: 10px;
}

.reportsTableContainer{
        max-height: 68vh;
        border-radius: 15px;  
        border-left: 1px solid rgba(20, 99, 172, 0.24);
        border-bottom: 1px solid rgba(20, 99, 172, 0.24);
}

.ml-10{
    margin-left: 10px;
}

.reportsTableContainer .reportsTableCell{
font-family:  "Roboto" ;
  font-weight: 500;
  font-size: 10px;
  color: #fff;
  background-color: #264C86;
  text-transform: uppercase;
}

.paperFont{
    font-size: 12px;
}

.reportsTableCell2{
    padding: 5px 9px;
    font-weight: 300;
    font-size: 0.9rem;
    color:black;
    /* border-left:  1px solid rgba(20, 99, 172, 0.24) !important; */
}

 .padLeft {
     padding-left: 10px;
 }

.selBackground{
    background-color: white;
}


.fleetRowRank {
    border-bottom: 0.5px solid #cfd0dd;
    font-weight: 700;
    font-size: 10px;
    color: #000000DE !important;
    height: 30px;
  }

  .fleetRowRank-odd {
    background-color: rgb(73, 132, 244, 0.05) !important;
    background-color: -moz-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -webkit-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -o-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: -ms-linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
    background-color: linear-gradient(rgb(73, 132, 244, 0.05) 0%, rgb(59, 124, 251, 0.05) 100%) !important;
  }

  .fleetRowRank-even {
    background-color: #ffffff !important;
  }

  .dataUsageHeader{
    height: 46px;
  }

  .fleetRowRank .fleetRowRank-dataUsage {
    font-size: 12px;
    color: #6c6b6b;
    height: fit-content;
    font-weight: 500;
    padding: 10px 0px 10px 0px;
    align-items: center;
    border-bottom: 1px solid #c3d6e7;
  }

  .throttledQuota {
    color: #757575;
    font-size: 12px;
    font-weight: 600;
  }

  .fleetRowRank .activePlanSites {
    color: #81aef2;
    font-size: 13px;
    cursor: pointer;
  }

  .fleetRowRank .inActivePlanSites {
    color: #caa596;
    font-size: 13px;
    cursor: pointer;
  }

  @media only screen and (max-height: 600px) {
    .dataUsageScroll {
        max-height: 390px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 600px) and (max-height: 650px) {
    .dataUsageScroll {
        max-height: 440px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 650px) and (max-height: 690px) {
    .dataUsageScroll {
        max-height: 485px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 690px) and (max-height: 710px) {
    .dataUsageScroll {
        max-height: 530px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 710px) and (max-height: 740px) {
    .dataUsageScroll {
        max-height: 550px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 740px) and (max-height: 760px) {
    .dataUsageScroll {
        max-height: 580px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 760px) and (max-height: 790px) {
    .dataUsageScroll {
        max-height: 600px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 790px) and (max-height: 820px) {
    .dataUsageScroll {
        max-height: 630px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 820px) and (max-height: 849px) {
    .dataUsageScroll {
        max-height: 660px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 849px) and (max-height: 870px) {
    .dataUsageScroll {
        max-height: 690px;
        overflow-y: auto;
    }
}

@media only screen and (min-height: 870px) and (max-height: 899px) {
    .dataUsageScroll {
        max-height: 710px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:899px) and (max-height:950px) {
    .dataUsageScroll {
        max-height: 735px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:950px) and (max-height:970px) {
    .dataUsageScroll {
        max-height: 790px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:970px) and (max-height:999px) {
    .dataUsageScroll {
        max-height: 810px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:999px) and (max-height:1020px) {
    .dataUsageScroll {
        max-height: 835px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1020px) and (max-height:1050px) {
    .dataUsageScroll {
        max-height: 855px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1050px) and (max-height:1080px) {
    .dataUsageScroll {
        max-height: 880px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1080px) and (max-height:1100px) {
    .dataUsageScroll {
        max-height: 915px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1100px) and (max-height:1140px) {
    .dataUsageScroll {
        max-height: 930px;
        overflow-y: auto;
    }
}
@media only screen and (min-height:1140px) and (max-height:1160px) {
    .dataUsageScroll {
        max-height: 965px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1160px) and (max-height:1200px) {
    .dataUsageScroll {
        max-height: 990px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1200px) and (max-height:1230px) {
    .dataUsageScroll {
        max-height: 1030px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1230px) and (max-height:1260px) {
    .dataUsageScroll {
        max-height: 1059px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1260px) and (max-height:1300px) {
    .dataUsageScroll {
        max-height: 1090px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1300px) and (max-height:1330px) {
    .dataUsageScroll {
        max-height: 1130px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1330px) and (max-height:1360px) {
    .dataUsageScroll {
        max-height: 1160px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1360px) and (max-height:1400px) {
    .dataUsageScroll {
        max-height: 1190px;
        overflow-y: auto;
    }
}

@media only screen and (min-height:1400px) and (max-height:1920px) {
    .dataUsageScroll {
        max-height: 1220px;
        overflow-y: auto;
    }
}




