

.t_border{
border: 2px solid #DCE8F3;
}

::-webkit-scrollbar{
    background: #e0dddd;
}

::-webkit-scrollbar-thumb{
    background: #264C86;
}

.flex{
    display: flex;
}

.justyfy-center{
    justify-content: center;
}

.justyfy-space-btwn{
    justify-content: space-between;
}

.align-center{
    align-items: center;
}

.hover:hover{
background:#c7cad0;
}


.subject{
    height: auto !important;
    margin: 20px !important;
    border-radius: 15px !important;
}

.border_radius_15{
border-radius: 15px !important;
}

.font10{
    font-size: 10px !important;
}

.font12{
    font-size: 12px !important;
}

.font15{
    font-size: 15px !important;
}

.bold{
    font-weight: bold !important;
}

.transformUpper{
    text-transform: uppercase;
}

.textOverflow{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.bullet{
    height: 10px;
    width: 10px;
    border-radius: 50px;
    margin-right: 10px;
}

.image_conatiner{
    border: 1px solid rgba(20, 99, 172, 0.24) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    /* width: 100%; */
    height: 100px !important;
    display: flex;
    position: relative;
    width: 110px;
    cursor: pointer;
    background: #E8EFF7    ;
    /* min-width: 50px; */
    margin-right: 10px;
    margin-top: 10px;
}

.commentor{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #676984;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #FFFFFF;
    margin: 0px 10px;
    text-transform: uppercase;
    word-break: break-word;
}

.icon-cursor2{
    cursor: pointer;
    border: 1px solid #dbdbff;
    margin-right: 5px;
}

.image-placeholder{
    margin: 20px;
    height: 60px;
}

.icon-email{
    cursor: pointer;
    margin-left: 10px;
    height: 17px;
    width: 17px;
}

.email-popup{
    border: 4px solid #498AEC;
}

.typos{
    color: #4D73E7 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.typo2{
    font-size: 12px !important;
    font-style: italic !important;
    padding: 5px !important;
    text-align: center;
}

.send-email-button{
    cursor: pointer;
    background: #264C86 !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;    
    width: 80px;
    height: 37px;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    border: none;
}

.ticket-view__contianer {
    max-height: calc(100vh - 170px);
    overflow-y: auto;
}

.ticket-view__details-container {
    padding: 20px;
}

.ticket-attachment-popup .image-filename {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #45464e;
}

.ticket-attachment-popup .close{
    cursor: pointer;
    float: right;
}

.ticket-attachment-popup .image-content {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
}

.ticket-attachment-popup .image-content img{
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.ticket-view__details-container > div > .MuiGrid-container {
    flex-wrap: nowrap;
}

.Form .tickets-view-navigation-btns {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 25px 0px 20px;
}

.ticket-view__details-container .tickets-view-btns button {
    height: 37px;
    width: 120px;
    text-transform: uppercase;
}

.tickets-view-navigation-btns .enable-prev-btn {
    box-shadow: 0 0 13px 0 hsla(0, 0%, 79.6%, .35);
    background-color: #264C86;
    color: #fff;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    height: 29px;
    text-transform: none;
    border-radius: 4px;
}

.tickets-view-navigation-btns .enable-prev-btn:hover {
    background-color: #264C86;
}

.tickets-view-navigation-btns .disable-prev-btn, .tickets-view-btns .ticketView-disable-update-btn {
    background-color: #e8f0fd;
    color: #264c86;
    height: 29px;
    border-radius: 4px;
    text-transform: none;
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    border: 1px solid #676984;
    cursor: auto;
}

/* .MuiInputBase-input{
    height: auto !important;
}  */