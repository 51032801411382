.dropDownCardItem {
    margin-left: 30px;
}

.dropDownCardItem .dropDownCardSelected {
    font-weight: bold;
}

.dropDownCard {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
    border-radius: 0px !important;
    height: 64px;
    width:190px
}

.dropDownCardCollapsePart {
    position: absolute;
    z-index: 1000;
    width: 188px;;
    background: white;
}
.MuiInputLabel-root.dropDownCardLabel {
    display: block;
    font-size: 10px;
    color: #9c9c9c;
    letter-spacing: 3px;
    font-weight: normal;
    margin-bottom: 2px;
}

.dropDownCardItem .dropDownCardSelected {
    font-size: 14px;
}

.MuiFormControl-root .headerLabel {
    font-size: 8px;
    color: #9c9c9c;
    letter-spacing: 3px;
    font-weight: 700;
    margin-bottom: 2px;
    margin-left: 8px;
}

.headerDropDownSelect div {
   font-size: 12px;
   padding: 8px;
   background: white;
}

.dropDown .drpdownLoabel {
    background: white;
    padding: 4px;
    margin-top: 4px;
    font-size: 8px;
    font-weight: bold;
}

.headerDropDownSelect {
    padding: 1px;
}
