.group-settings{
    overflow-x: auto;
    margin-left: 20px;
    margin-top: 5px; 
}
.group-settings-data{
    width: 50%;
    padding: 0px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid rgba(20, 99, 172, 0.24);
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #6E7079;
}
.group-settings-data > div{
    display: flex;
    border-bottom: 1px solid #EBEBEB;
    padding: 10px 5px 10px 5px;
}
.group-settings-data > div:last-child {
    border-bottom: 0px;
}
.group-settings-data > div .key{
    width: 50%;
    margin-right: 20px;
}
.group-settings-data > div .value{
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.key.logo{
    display: flex;
    align-items: center;
}
.value img {
    width: 100%;
}
.value.link a{
    text-decoration: none;
    cursor: pointer;
}

.MuiButtonBase-root.update-org-settings-button {
    max-width: 159.95px;
    width: 100%;
    height: 37px;
  
    background-color: #264c86 ;
    border-radius: 5px;
    color: #ffffff;
    text-transform: uppercase;
  
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
  
    text-align: right;
    letter-spacing: 0.035em;
    margin-right: 10px;
    margin-top: 20px;
}

.MuiButtonBase-root.update-org-settings-button:hover {  
    background-color: #264c86 ;
    color: #ffffff;
}

.MuiButtonBase-root.edit-org-settings-button {
    background-color: #264c86 ;
    color: #ffffff;
    max-width: 159.95px;
    width: 100%;
    height: 37px;
  
    border-radius: 5px;
    text-transform: uppercase;
  
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
  
    text-align: right;
    letter-spacing: 0.035em;
    margin-right: 10px;
    margin-top: 20px;
}

.MuiButtonBase-root.edit-org-settings-button:hover {  
    background-color: #264c86 ;
    color: #ffffff;
}

.MuiButtonBase-root.cancel-org-settings-button {
    color: #264c86 ;
    max-width: 159.95px;
    width: 100%;
    height: 37px;
  
    border-radius: 5px;
    text-transform: uppercase;
  
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
  
    text-align: right;
    letter-spacing: 0.035em;
    margin-right: 10px;
    margin-top: 20px;
}

.MuiFormControl-root.two-fa-timeout-text-field input{
    height: 12px;
    width: 45px;
    padding: 12px 0px 12px 12px;
}
.group-settings-data .notification{
    display: block;
    padding-top: 10px;
}
.group-settings-data .notification > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 5px 5px;
}
.group-settings-data .notification > div:last-child {
    padding: 10px 5px 0px 5px;
}
.group-settings-data > div:nth-last-child(2) {
   border-bottom: 1px solid black;
  }
.group-settings-data .notification .value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
}
.group-settings-data .title {
    font-size: 16px;
    color: #45464e;
    font-weight: 600;
}
.group-settings-data .emailSignature {
    width: 100%;
}

.MuiFormControl-root.text_field_height input{
    height: 26px;
    padding-left: 5px;
}

.twofaTimeoutTooltip {
    position: relative;
    left: 90px;
    cursor: pointer;
}

.timeoutValueDiv{
    position: relative;
    top: -5px;
}

.org-settings-email-address-leftdiv {
    width: 53%;
}

.org-settings-email-address-leftdiv .email-error-msg {
   color: red;
   display: flex;
   width: 95%;
   font-size: 12px;
}

.align-div-items{
    display: flex;
    align-items: center;
}