.inventory-config-status .ellipsis{
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inventory-config-status-td .bluePointer {
    cursor: pointer;
    color: #0000EE;
}

.inventory-config-status-td .Pointer {
    cursor: pointer;
}

.inventory-config-status .progress-bar-div > span{
    cursor: pointer;
    width: 125px;
    display: flex;
    align-items: center;
}

.inventory-config-status .progress-bar-div .MuiLinearProgress-root{
    width: 100px;
    margin-right: 10px;
}

.inventory-content .inventoryLogsDashboard {
    margin-top: 0px;
}