.add-quota-send-to-via-email .disbale-user-selection, .add-quota-send-to-via-email .disbale-user-selection *{
    pointer-events: none;
    cursor:not-allowed;
    opacity: 0.8;
}
.add-quota-send-to-via-email .pls-select-users{
    color: red;
    margin-top: 2px;
}

.form-parent .starlink-dual-message {
    font-family: 'Inter';
    font-size: 12px;
    color: red;
}

.MuiMenu-list .starlink-dual-message {
    font-family: 'Inter';
    font-size: 12px;
    padding-left: 15px;
    color: red;
}

.MuiMenu-list .starlink-message {
    font-family: 'Inter';
    font-size: 12px;
    padding-left: 15px;
    color: #264C86;
}

.close-btn-ht-wd {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.quota_management--base--margin_1x .device-length-render-text {
    font-size: 12px;
    color: red;
}