.new-summary-single-site-table {
    margin-top: 16px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.new-top-user-table-container .summ-table {
    margin-top: 15px;
}

.summ-table .new-summary-top-users-table {
    margin-bottom: 10px;
    overflow-x: hidden;
}

.new-top-user-table-container {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
    padding: 0px 0px 15px 0px;
}

.new-summary-single-site-table>span {
    color: #45464E;
    font-style: normal;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
}

.new-top-user-table-container .search-box--filter-chips {
    display: flex;
    gap: 10px;
    align-items: center;
}

.usageData-tabelCell .activity-top-sites {
    width: 100%;
}

.usage-tableBody .activity-new-tableRow .leftAllign {
    text-align: left;
    padding-left: 15px;
}

.summ-table .activity-users-pagination {
    margin-bottom: 5px;
    box-shadow: none;
}

.MuiTextField-root.input-box-activity-tu {
    margin: 16px 16px 0px 0px;
    width: 300px;
    max-width: 300px;
    margin-left: 0px;
}

.new-top-user-table-container .remove-shadow {
    box-shadow: none;
    margin-bottom: 7px;
}

.search-dropdown-limit-div .activity-usage-site-details-header {
    margin-left: 16px;
    margin-right: 16px;
}

.new-summary-top-users-table .user-width-7per {
    width: 7%;
}

.activity-new-tableRow .user-top-apps {
    padding-left: 10px;
}

.user-top-apps img {
    padding-left: 0px;
    padding-right: 0px;
}

.trendsUsageSearchbarMargin {
    margin-right: 16px;
  }