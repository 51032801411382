.MuiPaper-root {
    background: white;
}

.MuiToolbar-root {
    background: #f5f5f5;
    height: 49px;
}

.MuiPaper-root .MuiToolbar-root {
    min-height: 49px;
}

.logoutIcon {
    color: #000000;
    padding-top: 2px;
    margin-right: 12px;
}

.releaseNoteIcon{
    color: #2860B4;
}

.profileContainer {
    height: 35px;
    display: flex;
    margin-left: auto;
    color: black;
}

.profileContainer p {
    font-size: 15px;
}

.profileUserName {
    width: "150px";
    color: "#676984";
    overflow: "hidden";
    text-align: 'left';
    white-space: "nowrap";
    text-overflow: "ellipsis";
    cursor: "pointer";
    display: "inline-block";
    padding-bottom: "10px";
    padding-top: 2px;
    max-width: 112px;
}

.headerLogo {
    height: 35px;
    width: 100px;
    padding-top: 10px;
}

.MuiBox-root .headerContainer {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
}

.headerContainer{
    border-bottom: 1px solid #EBEBEB;
}

.menuList .MuiPaper-root{
    /* width: 140px !important; */
    padding-left: 18px !important;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 3px;
}

.toggleswitch {
    color: black;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 3px;
}

.menuList.user-settings li{
    display: flex;
    justify-content: left;
    padding: 5px;
}

.headerContainer .toolBarContainer {
    padding-left: 16px !important;
    padding-right: 7px;
}