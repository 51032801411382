.siteCharts__charts-container {
    margin-top: 5px;
    padding: 10px; 
}

.topologyConatiner .sdwan-siteCharts__charts-container {
    margin-top: 0px;
    padding-top: 0px;
}

.tab-container:has(.siteCharts__filters) {
    margin: 12px;
    margin-top: 15px !important;
}

.topologyConatiner .tab-container:has(.topology-sdwan-container) {
    margin-top: 10px !important;
}

.topologyConatiner .topology-sdwan-container {
    height: 45px;
}

.siteCharts__charts-paper {
    height: 100%;
}

.legend-table-container {
    height: auto;
}

.siteCharts__charts-container-freeFormTool .legend-table-container {
    height: auto;
    margin: 10px 0px;
}

.siteCharts__filters-date-time-picker .rdtPicker {
    left: -30px
}

.siteCharts__filters-date-time-picker .rdt:nth-of-type(2) .rdtPicker {
    left: -65px
}

.siteCharts__charts-container {
    max-height: calc(100vh - 235px);
    overflow-y: auto;
}

@media only screen and (max-width: 924px) {
    .siteCharts__charts-container {
        max-height: calc(100vh - 334px);
        overflow-y: auto;
    }
}

.coverageMapContainer .siteCharts__charts-container {
    max-height: unset;
}

.site-filter-container{
    display: flex;
    justify-content: space-between;
}

.site-filter-item{
    display: flex;
    gap: 0.05rem;
    align-items: flex-end;
    padding: 0px 10px;
}

.jc-end{
    justify-content: flex-end;
}

.site-filter-analyst-mode{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}

.sdwanTopbar {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.pieChat-selectTag .sdwan-select-bin {
    margin-top: -20px;
}