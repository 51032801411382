.tkt_ticket_details_dialog .MuiPaper-root{
    width: 800px;
    height: 570px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(103, 105, 132, 0.25);
    border-radius: 8px;
}
.tkt_ticket_details_menuIcon{
    margin-right: 25px;
    color: #ffffff;
}
.tkt_ticket_details_menu .MuiPaper-root{
    /* width: 128px;
    height: 105px;
    background: #FFFFFF; */
    box-shadow: 0px 4px 15px rgba(103, 105, 132, 0.25);
    border-radius: 4px;
}
.tkt_ticket_details_menuItem{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    color: #676984 !important;
    padding: 10px 20px !important
}
.tkt_ticket_details_dialog_content{
    padding-right: 20px !important;
    padding-left: 24px !important;
    display: flex;
    /* overflow: hidden; */
}
.tkt_ticket_details_dialog .dialog_title{
    margin-bottom: 0px !important;
}
.tkt_ticket_details_dialog_content_status{
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.tkt_ticket_details_dialog_content_subject_title{
    margin-top: 25px;
}
.tkt_ticket_details_dialog_content_description_content{
    /* margin-bottom: 20px !important; */
    padding-bottom: 15px;
    border-bottom: 0.5px solid #C7DAEB;
}
.tkt_ticket_details_dialog_content_subject_title,
.tkt_ticket_details_dialog_content_description_title,
.tkt_ticket_details_dialog_content_event_title,
.tkt_ticket_details_dialog_content_creator_title,
.tkt_ticket_details_dialog_content_assignee_title,
.tkt_ticket_details_dialog_content_status_title,
.tkt_ticket_details_dialog_content_comments_title,
.tkt_ticket_details_dialog_content_commentedBy,
.tkt_ticket_details_dialog_content_comment_commentDate{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #676984;
    text-transform: uppercase;
    display: flex;
    /* align-items: center; */
}
.tkt_ticket_details_dialog_content_subject_content,
.tkt_ticket_details_dialog_content_description_content,
.tkt_ticket_details_dialog_content_event_content,
.tkt_ticket_details_dialog_content_creator_content,
.tkt_ticket_details_dialog_content_assignee_content,
.tkt_ticket_details_dialog_content_assignee_searchBox > input,
.tkt_ticket_details_dialog_content_status_content,
.tkt_ticket_details_dialog_content_comment_text,
.multi_line_textfield_parent > div > div > textarea{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #676984 !important;
    word-break: break-word;
}
.tkt_ticket_details_dialog_content_description_content,
.tkt_ticket_details_dialog_content_comment_text{
    white-space: pre-wrap;
}
.tkt_ticket_details_dialog_content_status_content{
    display: flex;
}
.tkt_ticket_details_dialog_content_status_content span{
    justify-content: center;
    align-items: center;
    display: flex;
}
.tkt_ticket_details_dialog_content_markAsClosed{
    width: 130px;
    height: 32px;
    margin-left: 15px !important;
}
/* Button commom css */
.tkt_ticket_details_dialog_content_button{
    background: #AAABC4 !important;
    border-radius: 4px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 15px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #FFFFFF !important;
    text-transform: none !important;
}
/* End -- Button commom css */
.tkt_ticket_details_dialog_content_comment{
    width: 90%;
}
/* .tkt_ticket_details_dialog_content_comments{
    max-height: 96.5%;
    overflow: auto;
}
.tkt_comments_overflow.tkt_ticket_details_dialog_content_comments{
    min-height: 55%;
    max-height: 55%;
} */
.tkt_ticket_details_dialog_content_comments_content{
    display: flex;
    padding: 15px 15px 15px 0px;
    border-bottom: 1px solid #C7DAEB;
}
.tkt_ticket_details_dialog_content_comments_content:last-child {
    border-bottom: 0px !important ;
}
.tkt_ticket_details_dialog_content_comments_content.tkt_clicked_ticket{
     background: #FAF9FC;
}
.tkt_ticket_details_dialog_content_commenter > div{
    /* width: 10%; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #676984;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    color: #FFFFFF;
    margin: 0px 10px;
    text-transform: uppercase;
    word-break: break-word;
}
.tkt_ticket_details_dialog_content_comment_text{
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
}
.tkt_ticket_details_dialog_content_comment > div{
    display: flex;
}
.tkt_ticket_details_dialog_content_commentedBy,
.tkt_ticket_details_dialog_content_comment_commentDate{
    text-transform: none !important;
}
.tkt_ticket_details_dialog_content_comment_commentDate{
    margin-left: 20px;
}
.tkt_ticket_details_dialog_content_comment_deleteComment{
    float: right;
}
.tkt_ticket_details_dialog_content_comment_addComment{
    /* padding: 10px; */
    margin-top: 10px;
}
.tkt_ticket_details_dialog_content_description_content > div > div > textarea,
.tkt_ticket_details_dialog_content_comment_addComment > div > div > textarea {
    height: 100px !important;
}
.tkt_ticket_details_dialog_content_subject_content > div > div > textarea {
    height: 50px !important
}

/* multiline input common CSS */
.multi_line_textfield_parent,
.multi_line_textfield_parent > div, 
.multi_line_textfield_parent > div > div > textarea {
    width: 100%;
}
.multi_line_textfield_parent > div > div > fieldset{
    border: none !important;
}
.multi_line_textfield_parent > div,
.multi_line_textfield_parent > div > div{
    padding: 0px !important;
}
.multi_line_textfield_parent > div > div > textarea {
    border: 0.5px solid #C7DAEB !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    padding: 10px;
}
/* End-- multiline input common CSS */
.tkt_ticket_details_dialog_content_comment_sendButton,
.tkt_ticket_details_dialog_content .editTicketActions{
    /* width: 96px;
    height: 32px; */
    float: right;
    margin-top: 20px !important;
}
.displayNone{
    display: none;
}
.tkt_ticket_details_edit_ticket_buttons{
    display: flex;
    justify-content: flex-end;
    height: 75px;
    flex-direction: column;
}
.tkt_ticket_details_edit_ticket_buttons > div{
    justify-content: flex-end;
    display: flex;
}
.tkt_ticket_details_dialog_content_button.edit_close_button,
.tkt_ticket_details_dialog_content_button.edit_submit_button{
    width: 96px;
    height: 32px;
    margin-left: 30px;
}

.tkt_ticket_details_dialog_content_assignee_searchBox > fieldset{
    border: none !important;
}
.tkt_ticket_details_dialog_content_assignee_searchBox,
.tkt_ticket_details_dialog_content_assigneeAutocomplete {
    border: 0.5px solid #C7DAEB !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}
.tkt_ticket_details_dialog_content_assignee_searchBox,
.tkt_ticket_details_dialog_content_assigneeAutocomplete{
    width: 90%;
    height: 40px;
}
.tkt_ticket_details_assignee_clearImage{
    height: 12px;
}
.tkt_ticket_details_dialog_content_assignee_searchBox img{
    margin-left: 24px;
}
.tkt_ticket_details_dialog_content_assigneeAutocomplete  fieldset{
    border: none !important;
}

.tkt_ticket_details_dialog_content_assigneeAutocomplete  input,
.tkt_ticket_details_dialog_content_edit_tkt_assigneeAutocompleteListItems{
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #676984 !important;
}
.tkt_ticket_details_dialog_content_assigneeAutocomplete > div,
.tkt_ticket_details_dialog_content_assigneeAutocomplete > div > div,
.tkt_ticket_details_dialog_content_assigneeAutocomplete > div > div > div{
    height: 100%;
    width: 100%;
}

.status {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #676984;
    margin-left: 5px;
}
.tkt_ticket_details_dialog_content .editTicketActions .dialog_okButton {
    margin-left: 8px;
}
.tkt_ticket_details_dialog_content .eventDate, .tkt_ticket_details_dialog_content .creator,
.tkt_ticket_details_dialog_content .assignee {
    border: 1px solid #C7DAEB;
    padding: 14px 0px 14px 50px !important;
}
.tkt_ticket_details_dialog_content .editAssignee {
    border: 1px solid #C7DAEB;
}
.tkt_ticket_details_dialog_content .editAssignee .tkt_ticket_details_dialog_content_assignee_title{
    padding: 14px 0px 0px 50px !important;
}
.tkt_ticket_details_dialog_content_assignee_content > div{
    display: flex;
    justify-content: center;
}