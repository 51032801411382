.FilterLeftContainer .login-history-filter-inputs {
    height: 80%;
}

.login-rc--filter_section .login-history-input {
    width: 242px;
}

.login-rc--filter_section>p {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    margin-top: -5px;
}

.accountActivityOptionsResponse {
    overflow-y: auto;
    height: calc(100vh - 423px);
    max-height: 402px;
}

.login-history-filter-inputs ::-webkit-scrollbar{
    width: 4px;
}

.login-history-filter-inputs ::-webkit-scrollbar-thumb{
    background: #ebebeb;
}

.login-history-filter-inputs ::-webkit-scrollbar-track{
    background: white;
    box-shadow: none;
}

.login-rc--filter_section .app-names-checkbox {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #45464E;
    padding: 0px 0px 8px 0px;
}

.app-names-checkbox .MuiSvgIcon-fontSizeSmall {
    width: 16px;
    height: 16px;
}

.app-names-checkbox .accountActivityFilteroptionsbox {
    height: 16px;
    padding: 0px 8px 0px 0px;
    margin: 0px 0px 4px 0px;
}

.app-names-checkbox .accountActivityFilteroptions {
    height: 16px;
    padding: 0px 8px 0px 0px;
    margin: 0px 0px 8px 0px;
}

.login-history-filter-inputs .login-filter-clearFilter {
    margin-top: -2px;
}

.optionHeadingPadding12 {
    margin-bottom: 12px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
}