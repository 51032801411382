.MuiDialogContent-root.delete-content-filter-dialog-content {
    overflow-y: hidden;
    padding: 0px;
}

.MuiDialogTitle-root.delete-content-filter-title{
    border-bottom: 1px solid #EBEBEB;
}

.MuiDialogActions-root.delete-content-filter-dialog-actions {
    border-top: 1px solid #EBEBEB;
}

.delete-form-content-filter-body {
    padding: 30px;
}