.usage-per-site-pie .title-text {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 0px 20px 15px;
    padding-top: 10px;
}

.usage-per-site-pie .title-text-right {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 0px 20px 5px;
    padding-top: 10px;
}

.usage-per-site-pie .title-text-left {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 700;
    color: #45464E;
    margin: 5px 5px 20px 5px;
    padding-top: 10px;
}

.bucket-text{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 700;
        color: #45464E;
        margin-right: 20px;
        /* margin: 5px 0px 20px 15px;
        padding-top: 10px; */
    
}

.new-summary-chart-container .new-summary-chart-component .padding-left-22 {
    padding-left: 20px;
}

.bucket-padding {
    min-width: 40px;
    margin-left: 35px;
}

.usage-per-site-pie {
    border-radius: 4px;
    box-shadow: 0px 0px 7px 0px rgba(167, 158, 158, 0.25);
    padding: 5px;
    height: 100%;
}

.usage-per-site-pie .usage-details-pie-perSite {
    display: flex;
    justify-content: center;
    margin: 15px auto 10px 5px;
}

.usage-details-pie-perSite .summary-usage-value {
    color: #040404;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.usage-details-pie-perSite .summary-usage-unit {
    color: #6E7079;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.usage-details-pie-perSite>div {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 7px;
}

.usage-details-pie-perSite .summary-pie-usage-dot, .summary-pie-bar-legends .summary-pie-usage-dot {
    width: 9px;
    height: 9px;
    border-radius: 20px;
}

.usage-per-site-pie .wan-type-pie {
    margin-left: 30px;
    justify-content: flex-start;
}

.usage-per-site-pie .legends-pie-new-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin: 15px 15px 25px 0px;
    align-items: center;
}

.usage-per-site-pie .removeMarginTop {
    margin-top: 10px;
}

.legends-pie-new-container .summary-pie-bar-legends {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #040404;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.summary-pie-bar-legends .barChart-legends {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    color: #0e0548;
    cursor: pointer;
    font-family: 'Inter';
}

.summary-pie-bar-legends .disable-barChart-legends {
    color: #a2a2a6;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    cursor: pointer;
    font-family: 'Inter';
}

.usage-per-site-pie .title-chip-pie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.legends-pie-new-container .line-bar-view {
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
}

.legends-pie-new-container .time-series-legends-new {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.usage-per-site-pie .new-summary-control-limit {
    display: flex;
    align-items: center;
}

.bucket-outer{
    margin-top: 20px;
    display: flex;
    align-items: center; 
}

.usage-per-site-pie .chart-legends-histogram {
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 15px;
}

.chart-legends-histogram .legends-view-images {
    display: flex;
    gap: 15px;
}

.usage-details-pie-perSite .selected-filter {
    cursor: pointer;
    color: #4c3aff;
}

.usage-details-pie-perSite .sites-list {
    margin-bottom: -3px;
}

.usage-per-site-pie .title-toggle-btn-div {
    display: flex;
    justify-content: space-between;;
    align-items: center;
}

.new-summary-control-limit .title-dropdown-pie-chart {
    display: flex;
    align-items: center;
}

.title-toggle-btn-div .toggle-wan , .title-toggle-btn-div .toggle-site {
    font-size: 12px;
    font-style: normal;
    font-family: 'Inter';
    font-weight: 500;
}

.usage-details-pie-perSite .single-value {
    display: flex;
    justify-content: center;
}

.pie-chart-summary .remove-margin-left {
    margin-left: 0px;
    justify-content: center;
}

.usage-per-site-pie .time-series-legends-new-sites {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 15px;
}

.time-series-legends-new-sites .summary-pie-bar-legends-sites {
    display: flex;
    align-items: center;
    gap: 5px;
}

.legends-pie-new-container .legends-view-images-sites  {
    display: flex;
    align-items: center;
}

.site-line-chart-title-div .site-line-chart-title-text {
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 0px;
}

.summary-pie-bar-legends-sites #site-legend-filter {
    color: #4c3aff;
}

.histogram-dialog{
    min-height: 250px;
}

.histogramDialogTitle{
        font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #45464E;
      border-bottom: 1px solid #EBEBEB;
      padding: 20px 24px 0px 24px;
      
}

.title-toggle-btn-div #justify-center-sites {
    justify-content: flex-start;
}