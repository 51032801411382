.system-health-conatner {
    margin: 25px 0px 15px 25px;
}

.system-health-conatner .system-health-devices {
    width: 260px;
}

.system-health-charts-container {
    margin-top: 16px;
    height: calc(100vh - 260px);
    overflow: auto;
}

.system-health-charts-container>div {
    padding: 0px 15px 15px 0px;
}

.system-health-filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 25px;
}

.sh-bin-resetZoom {
    display: flex;
    align-items: center;
    gap: 5px;
}