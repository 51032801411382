.sh--agent-wan-balancer-container {
    padding: 10px;
}

.sh--agent-wan-balancer-grid-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}


.agent-wan-display-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.agent-wan-cancel-button {
    color: #0E0548;
    height: 30px;
    width: 100px;
}

.MuiButtonBase-root.agent-wan-balancer-action-button {
    border-color: #264C86;
    color: #264C86;
}

.wanActionremoveIcon {
    width: 20px;
    height: 20px;
    color: #A6A6A6;
    cursor: pointer;
}

.WanAddAction {
    color: #2F4B82;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    text-transform: none;
    padding: 0px;
    align-items: center;
    background-color: red;
    border: 1px solid #244C84;
    border-radius: 4px;
}

.wanAddActionIcon {
    color: #2F4B82;
    background-color: white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}