.filter-clearFilter {
    display: flex;
    /* justify-content: space-between; */
    padding: 10px 16px 18px 16px;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
}

.Filters_Title {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #45464E;
    display: flex;
    align-items: center;
}

.clearFiletrBtnDiv {
    border: none;
    display: flex;
    align-items: center;
}

.clearFiletrBtnDiv .clearFiletrBtnUsage {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    text-transform: none ;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
    min-width: 78px;
}

.clearApplyFilters {
    display: flex;
    padding: 10px 16px 12px 16px;
}

.clearApplyFilters .usage-applyFilterBtn {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: none;
    color: white;
    background-color: #1B51A7;
    width: 103px;
    height: 29px;
    left: 62px;
}

.clearApplyFilters .usage-applyFilterBtn:hover {
    background-color: #1B51A7;
}
.clearApplyFilters .clearFiltersButton {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    text-transform: none;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
    margin-left: 0px;
    justify-content: left;
}

.dash-filter-pop .firewallFilterOptions {
    border-bottom: 1px solid #EBEBEB;
    padding: 16px 16px 8px 16px;
}

.firewallFilterOptions .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

.firewallFilterOptions .MuiFormControlLabel-label {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #45464E;
}

.firewallFilterOptions .MuiFormControlLabel-labelPlacementEnd {
    height: 16px;
    padding-bottom: 8px;
}

.clearFiletrBtnDiv .margin-l-115 {
    margin-left: 115px;
}

.clearFiletrBtnDiv .margin-left-80 {
    margin-left: 0px;
}

.Usage-dateRangeSelection {
    font-size: 12px;
}

.Usage-dateRangeSelection>div {
    margin-bottom: 5px;
}

.Usage-dateRangeDiv {
    margin-top: 5px;
}

.Usage-dateRangeDiv .dateRangeInputField {
    width: 220px;
}

.Usage-dateRangeDiv .dateRangeInputTypography {
    font-size: 12px;
}

.Usage-dateRangelabel {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #6E7079;
}

.toDate-filter {
    display: flex;
    justify-content: flex-start;
}

.rc--filter_search_container .userName-usageData {
    height: 34px;
    border-radius: 4px;
}

.rc--filter_search_input-usage::placeholder {
    font-size: 13px;
    font-weight: 400;
    color: #6E7079;
}

.FilterLeftContainer>div:nth-child(1) {
    overflow-y: auto;
}

.FilterLeftContainer>div:nth-child(1)::-webkit-scrollbar {
    width: 6px;
}

.am-table .FilterLeftContainer {
    width: 95%;
    background: white;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    margin: 24px 0px 0px 15px;
    display: flex;
    gap: 10px;
    flex-direction: column ;
    height: calc(100vh - 200px);
    font-family: 'Inter';
    font-style: normal;
}

.am-table .dash-filter-pop {
    margin: 0px;
    display: flex;
    gap: 10px;
    flex-direction: column ;
    font-family: 'Inter';
    font-style: normal;
}

.FilterLeftContainer>.usage-applyFilterBtn {
    text-transform: none;
    background-color: #2F4B82;
    border-radius: 4px;
    margin: auto 15px 15px 15px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.FilterLeftContainer>.usage-applyFilterBtn:hover {
    background-color: #2F4B82;
}

.dash-filter-pop>.usage-applyFilterBtn {
    text-transform: none;
    background-color: #1B57A7;
    border-radius: 4px;
    margin-right: 0px;
    margin-left: 60px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: white;
    width: 103px;
    height: 29px;
    float: right;
}

.dash-filter-pop>.usage-applyFilterBtn:hover {
    background-color: #2F4B82;
}

.rc--content_sub_headers-select {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #45464E;
}

.audit-filter-inputs-usage {
    width: 242px;
}
