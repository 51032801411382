.quota_management--base--container {
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #2F4B82;
    border-radius: 0.5rem;
}

.quota_management--base--margin_1x {
    margin: 1rem;
}

.quota_management--base--margin_05x {
    margin: 0.5rem;
}

.quota_management--base--mt_1x {
    margin-top: 1rem;
}

.quota_management--base--mb_1x {
    margin-bottom: 1rem;
}

.quota_management--base--ml_1x {
    margin-left: 1rem;
}

.quota_mangement--base--padding_1x {
    padding: 1rem;
}

.quota_mangement--base--padding_05x {
    padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.quota_management--base--margin_1x .quota_management--base--pb_1x {
    padding-bottom: 1rem;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}

.quota_management--base--font_07x { 
    font-size: 0.7rem;
    font-weight: 600;
}

.quota_management--base--font_1x { 
    background: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    padding: 15px;
    color: #45464E;
    font-family: 'Inter';
    display: flex;
}

.margin-left-auto {
    margin-left: auto;
}

.quota_management--base--font_2x {
    font-size: 1.5rem;
    font-weight: 500;
}

.quota_management--base--flex {
    display: flex;
    gap: 1rem;
}

.quota_management--base--flex--column {
    display: flex;
    flex-direction: column;
}

.quota_management--base--flex--row {
    display: flex;
    flex-direction: row;
}

.quota_management--base--flex--justify--start {
    display: flex;
    justify-content: flex-start;
}

.quota_management--base--flex--justify--end {
    display: flex;
    justify-content: flex-end;
}

.quota_management--base--flex--justify--center {
    display: flex;
    justify-content: center;
}

.quota_management--base--flex--justify--space_between {
    display: flex;
    justify-content: space-between;
}

.quota_management--base--flex--align--start {
    display: flex;
    align-items: flex-start;
}

.quota_management--base--flex--align--center {
    display: flex;
    align-items: center;
}

.tooltip-invalid-interfaces img {
    cursor: pointer;
}

.quota_management--base--cursor--pointer {
    cursor: pointer;
}

.MuiButtonBase-root.quota_management--button--cancel {
    border: 1px solid #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button--cancel:hover {
    border: 1px solid #EBEBEB;
}

.MuiButtonBase-root.quota_management--button--cancel:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button--save {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.quota_management--button--save:hover {
    background-color: #264C86;
    color: #FFFFFF;
}

.MuiButtonBase-root.quota_management--button--save:disabled {
    background-color: #EBEBEB;
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button_outlined--primary {
    border-color: #264C86;
    color: #264C86;
}

.helpIcon-quota {
    color: #264C86;
}

.MuiButtonBase-root.quota_management--button_outlined--primary:hover {
    border-color: #264C86;
}

.MuiPaper-root.quota_management--container {
    background-color: white;
    /* position: absolute;
    left: 0px;
    top: 50px;
    right: 0;
    bottom: 0; */
    padding:10px 20px 15px 20px;
    overflow: auto;
    height: calc(100vh - 140px);
}

.quota_management--loader--container {
    position: absolute;
}

/* Pagination Unset */

.quota_management--container .pagination-root .pagination-toolbar {
    min-height: 40px;
    height: 40px;
    padding-right: 2px;
    font-size: 14px;
    font-family: unset;
    margin-top: auto;
}

.quota_management--container .pagination-toolbar .records-select {
    font-size: 14px;
    font-family: unset;
    border-radius: 8px;
    padding: 0px 8px 0px 7px;
    margin-right: 5px;
}

.quota_management--container .MuiMenuItem-root.records-select-item {
    font-size: 10px;
    font-family: unset;
}

.MuiMenuItem-root.records-select-item {
    font-size: 0.9rem;
    font-family: unset;
}

/* tooltip */

.MuiTooltip-tooltip.quota_management--tooltip {
    background-color: black;
    color: white;
    padding: unset;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
}

.MuiTooltip-tooltip.quota_management--tooltip .MuiTableCell-root {
    color: white;
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.MuiTooltip-tooltip.quota_management--tooltip .quota_management--tooltip--value_title {
    opacity: 0.5;
}

/* comp level */

.quota-kontrol-note{
    position: absolute;
    top: 75px;
    font-size: 12px;
}

.quota-help-container {
    position: fixed;
    right: -20px;
}

.quota_management--table_container .MuiTableCell-root {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-collapse: collapse;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 1px;
    color: #45464E;
}

.quota_management--table_container .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;
    letter-spacing: 1px;
    cursor: pointer;
}