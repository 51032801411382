.SideNavDrawer .New-UX-NavigationTabs-Conatiner {
    margin-top: 112px;
    height: 42px;
    padding-left: 20px;
    border: 1px solid #EBEBEB;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

#SubTabs-Container-marginTop-112 {
    margin-top: 112px;
    margin-bottom: unset;
}

#SubTabs-Container-marginTop-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.NewTabsDiv-InfoIconsDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    margin-left: -8px;
    height: 41px;
    width: 100%;
}

.tabButtons-parent-div {
    display: flex;
    align-items: center;
}

.tabs-alert-status {
    display: flex;
    align-items: center;
    margin: 0px 25px;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-activeTab, .New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-inActiveTab, .New-UX-NavigationTabs-Conatiner .MuiTab-root {
    padding: 12px 0px 10px 0px;
    margin: 0px 8px 0px 8px;
    text-transform: None;
    cursor: pointer;
    border-radius: 0px;
    min-width: unset;
    min-height: 42px;
    height: 42px;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-activeTab {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: rgba(47, 75, 130, 1) !important;
    border-bottom: 2px solid rgba(47, 75, 130, 1);
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-inActiveTab {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(139, 141, 151, 1);
    border-bottom: 2px solid transparent;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-activeTab:hover {
    background: unset;
}

.New-UX-NavigationTabs-Conatiner .New-UX-NavigationTabs-inActiveTab:hover {
    background: rgba(25, 118, 210, 0.04);
    padding: 12px 8px 10px 8px;
    margin: 0px;
}

.NewTabsDiv-InfoIconsDiv .tabs-helpIcon {
    cursor: pointer;
    color: #2860b4;
}

.New-UX-NavigationTabs-Conatiner .backButtonImg {
    margin-right: 20px;
    cursor: pointer;
}