.backup-config-container {
    padding: 0px 5px 0px 10px;
}

.TopUsersConatiner .backup-config-table-container {
    max-height: calc(100vh - 360px);
    margin-bottom: 10px;
}

.backup-config-container .golden-config-default-container {
    width: 97%;
    padding: 24px 10px 0px 5px;
    background-color: #fff;
    margin: 0px 0px 0px 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
}

.golden-config-default-container>div:nth-child(1) {
    display: flex;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
}

.golden-config-default-container .TopUsersConatiner {
    margin-top: -10px;
}

.config-search-bar-text .config-backup-search-bar {
    margin-top: -23px;
    height: 32px;
}

.config-tableRow .config-left-align {
    text-align: left;
    background: #F5F5F5;
    height: 40px;
    font-family: 'Inter';
    font-style: normal;
}

.config-left-align .default-config-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.usage-tableBody .config-backup-tabe-row td {
    text-align: left;
    padding-left: 20px;
    font-weight: 400;
}

.config-left-align .backup-config-headers {
    white-space: nowrap;
}

.config-pagination .MuiToolbar-root {
    min-height: 40px;
}

.config-backup-search-bar .config-search-icon {
    color: #8B8D97;
}

.config-backup-tabe-row .selected-config {
    cursor: pointer;
    color: #0000ee;
}
.usagedata-tableCell.config-left-align.selected-config .config-name,
.usagedata-tableCell.config-left-align.selected-config .update-config-name {
    display: flex;
    align-items: center;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name {
    margin-top: 10px;
}

.usagedata-tableCell.config-left-align.selected-config .config-name .copy-icon {
    width: auto;
    margin-left: 10px;
    color: #2860B4;
    cursor: pointer;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name > div{
    width: 75%;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name input{
    font-size: 12px;
    color: #45464E;
    font-family: 'Roboto';
    font-style: normal;
    letter-spacing: 1px;
}
.usagedata-tableCell.config-left-align.selected-config .update-config-name img {
    margin-right: 0px;
}

.quota_management--dashboard .config-status-table-fix {
    height: calc(100vh - 370px);
    overflow-y: auto;
}

.MuiTextField-root.input-box-bkp-conf {
    margin: 8px;
    width: 100%;
    max-width: 245px;
    margin-left: 0px;
    height: 32px;
}

.disp-flex {
    display: flex;
}

.auto-backup-table .auto-backup-status::first-letter {
    text-transform: uppercase;
}