.popupHeader .popUpHeaderBtn {
    margin-right: 10px;
    font-size: 10px;
    width: 10px;
    color: black;
    height: 26px;
    background: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
}

.hexPopup .leaflet-popup-content {
    margin: 4px 4px;
    width: 98% !important;
}

.hexPopup .leaflet-popup-content .popUpDivider {
    margin-top: 4px;
    height: 3px;
}

.leaflet-popup-content {
  /* Resetting margin, padding, and any other styles */
  margin: 0;
  padding: 0;
  /* Add any other reset styles as needed */
}

.isActive {
    background: #1976d2  !important;
}

.popUpContents {
    height: 100px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 10px;
    background: #b2b0b0;
    opacity: 0.0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #498AEC; 
    border-radius: 10px;
  }

  .coverageMapContainer {
        /* margin-left: 88px !important;
        position: fixed; */
        width: 100%;
        right: 0px;
  }

  .popUpChart {
      background: rgba(255, 255, 255, 0.55);
      margin:10px;
      top:136px;
      right: 48px;
      margin-right: 50px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
      transition: 0.3s im !important;
      border-radius: 5px ;
      height: 600px;
      width:900px;
  }

  .popUpTable {
      width: 100%;
  }

  .popUpTable tr {
      text-align: center;
  }

  .popUpTable th {
    text-align: center;
}

.mccMncDataCol .mccMncDivider {
    background: #D4E0F4;
    margin-left: 10px;
    margin-right: 10px;
}

.popUpHeadDivider {
    background: #EDF3FD;
}

.popUpHeadTable {
    text-align: center;
    width: 100%;
}

.mccMncDataContainer {
    display: flex;
}

.mccMncCodes {
    flex-grow: 1;
}

.mccMncNoData {
    text-align: center;
}

.layerButton {
    margin: 10px;
    margin-top: 140px;
    margin-left: 96px;
    position: fixed;
    z-index: 10000;
    width: 25px;
    height: 25px;
    background: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
    border-radius: 5px;
}

.coverageMapContainer .layersSelectection {
    margin-left: 65px;
    position: fixed;
    z-index: 10000;
    width: 300px;
    overflow: auto;
    height: fit-content;
    background: #FAF9FC;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s im !important;
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 10px;
}

.mccMncTableHeader {
    text-align: center;
    width: 50%;
}

.layerSelLabel {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
}
                
.layerSub {
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    text-align: center;
}

.layerSubHead {
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
}

.allLayerLink {
    text-align: center;
    bottom: 0;
    margin: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.coloredLegendBox {
    margin-left: 20px;
  }

.legendCard .footerContent {
    display: grid
}

.legendLabels {
    justify-content: space-around;
    width: 275px;
    display: flex;
    font-size: 12px;
}

.startLegendLabels {
    justify-content: space-around;
    width: 225px;
    display: flex;
    font-size: 12px;
}

.startLegendLabelsSpeed {
    justify-content: space-around;
    width: 240px;
    display: flex;
    font-size: 12px;
}

.mapLegends {
    /* width: 300px; */
}

.visibilityIcon {
    min-width: 40px !important;
    cursor: pointer;
}

.legendCard {
    margin-left: 88px;
}

.coverageMapContainer .popUpChart {

    background: rgba(255, 255, 255, 0.55);
}

.hichartTitle {
    letter-spacing: 0.06em;
    font-size: 15px;
    color: #676984;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
  }

  .customTMapitle {
    padding: 10px;
    padding-left: 18px;
    background: rgba(255, 255, 255, 0.55);
  }

  .coverageMapContainer .mapChartContainer {
      margin-top: 155px;
  }

  .coverageMapContainer .rightContainer {
    border-radius: 10px;
    top: 170px;
    right: 65px;
    position: fixed;
    width: 450px;
    z-index: 100000;
    /* height: 400px; */
    background: white;
      /* height: 75vh;
      overflow: scroll;
      padding-right: 10px;
      margin-top: 57px;
      border-top: 1px solid gray; */
  }

  .popUpchartContainer .maps-vessel-charts-container {
    height: calc(100vh - 256px);
    overflow: auto;
  }

  .popupChartHeader {
    border-bottom: 1px solid #D3D3D3;
    padding: 5px;
      display: flex;
      width: 100%;
      /* padding-top: 10px; */

  }

  .popupChartHeader .popUpVesselName {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    padding-left: 5px;
    padding-top: 5px;
  }


/* .chartContents {
    display: flex;
} */

.chartContents .highChartContainer {
    width: 504px;
}

.vesselHoverName {
    font-size: 12px;
    font-weight: bold;
    display: flex;
}

.geofence {
    padding-bottom: 0px;
}

.layersSelectection .customSlider {
    padding: 0px;
}

.chartLoader {
    text-align: center;
    z-index: 1000;
}    

.popUpchartContainer {
    width: 100%;
}

.chartCollection {
    margin-bottom: 20px !important;
}

.chartCollection .chartAccordions.MuiAccordion-root {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 100px;
    border-radius: 13px;
    background: #F8F8F8;
    backdrop-filter: blur(17px);
}

.chartCollection .chartAccordions.MuiAccordion-root:before {
    background: none;
}

.chartAccordions .accordionTittle {
    font-size: 14px;
    color: #273050;
 }
 .popUpchartContainer .metricsTableInfo {
     margin-left: 20px;
     margin-right: 20px;
 }

 .popUpchartContainer {
    /* height: 350px; */
    width: 470px;
    /* margin: 0.5rem;
    margin-top: 20px; */
 }

 .popUptableOnHex .MuiPaper-root {
    height: 120px !important;
 }

 .isKassNotactive {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    background: #e0dddd;
    border-radius: 30px;
    margin-bottom: 100px;
 }

 .closeIc {
    cursor: pointer;
 }

 /* .legendCard {
    display: flex;
    justify-content: center;
 } */

 .vesselTrackIndCont {
    /* display: block !important; */
 }

 /* .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  } */

  /* .dotLegend {
    width: 15px;
    border-radius: 25%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .dotLegendSite {
    min-width: 35px;
    width: 15px;
    border-radius: 25%;
    display: inline-block;
    /* margin-left: 20px;
    margin-right: 5px; */
    /* vertical-align: middle;
  } */

  /* .dotLegendStar {
    min-width: 35px;
    height: 20px;
    width: 30px;
    margin-left: 15px;
    border-radius: 25%;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }  */

  /* .vesselTrackInd {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid gray;
    border-radius: 18px;
    position: fixed;
    left: 225px;
    bottom: 10px;
    background: white;
    z-index: 10000;
  } */

  /* .vesselTrackHead {
    font-weight: 500;
  } */

  .tooltip {
    color: #264c86;
    cursor: pointer;
  }
  .tooltip .tooltipText {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltipText {
    visibility: visible;
  }

  .legendLabelMainSite {
    font-weight: bold;
    padding-left: 50px;
    padding-right: 20px;
  }

  .legendLabelMainStar {
    font-weight: bold;
    padding-left: 32px;
    padding-right: 15px;
    vertical-align: bottom;
  }

  .meterlegendLabels {
    display: flex;
    justify-content: space-between;
  }

  .colorMeterUnit {
    font-size: 12px;
    text-align: center;
  }

  .meterlegendLabel {
    font-size: 12px;
  }

.colorMeter {
    min-width: 200px;
  }   

.maps-layers-new-container {
    z-index: 1000;
    position: fixed;
    top: 170px;
    right: 65px;
    opacity: 0.8;
    background: black;
    width: 200px;
    box-shadow: 0px 0px 12.3px 0px #97979782;
    border-radius: 8px;
    padding-bottom: 5px;
    max-height: calc(100vh - 285px);
    overflow-y: auto;
}

.colorMeter {
  height: 68px;
  left: 507px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  bottom: 10px;
  background: black;
  color: white;
  opacity: 0.7;
  z-index: 100000;
  padding: 11px;
}

.colorMeterTwo {
  height: 68px;
  left: 554px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  bottom: 10px;
  background: black;
  color: white;
  opacity: 0.7;
  z-index: 100000;
  padding: 11px;
}

.vesselTrackInd {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 8px;
  position: fixed;
  left: 225px;
  bottom: 10px;
  background: black;
  opacity: 0.7;
  color: white;
  z-index: 10000;
}
.vesselVoyage {
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 18px;
  position: fixed;
  left: 225px;
  bottom: 10px;
  background: black;
  opacity: 0.7;
  color: white;
  z-index: 10000;
  padding: 26px;
  width: 265px;
}

.vesselPrompt {
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid gray;
  border-radius: 18px;
  position: fixed;
  left: 225px;
  top: 250px;
  background: black;
  opacity: 0.7;
  color: white;
  z-index: 1000;
  padding: 20px;
}

.legendRow {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.legendRow:first-child {
  justify-content: flex-start;
}

.legendLabel {
  text-align: center;
  min-width: 60px; /* Adjust as needed */
}

.dotLegendSite,
.dotLegendStar {
  width: 15px;
  height: 15px;
  min-width: 60px;
  /* Add any additional styling you need for the dots */
}

.dotLegendStar {
  padding-left: 20px;
}

.dotLegendStarone {
  padding-left: 10px;
  width: 15px;
  height: 15px;
  min-width: 60px;
}

.dotLegendStartwo {
  width: 15px;
  height: 15px;
  min-width: 60px;
}

.custom-tooltip-site {
  position: fixed;
  bottom: 60px;
  left: -20px;
  min-width: 420px;
}

.custom-tooltip-starlink {
  position: fixed;
  bottom: 80px;
  left: -20px;
  min-width: 420px;
}

.legendLast {
  padding-right: 8px;
}

.pointerCursor {
  cursor: pointer;
}

.dotLegend {
  height: 10px;
  width: 15px;
  border-radius: 25%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.mapNav {
  position: absolute;
  margin-top: 110px !important;
}

.mapNewNav {
  position: absolute;
  margin-top: 135px !important;
}

.coverageMapContainer .New-UX-NavigationTabs-Conatiner {
  width: 100%;
  position: fixed;
  z-index: 500;
}

.New-UX-NavigationTabs-Conatiner .selectedSiteTabs {
  display: flex;
  align-items: center;
}

.popUpchartContainer .chartCollection-margin-Top {
  margin-top: 45px;
}

#Maps-Hex-Charts-Container .New-UX-NavigationTabs-Conatiner {
  margin-top: 0px;
  width: 450px;
}

.flashTab {
  position: relative;
}

.flashTab::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;  /* Adjust height for the bottom border thickness */
  width: 100%;
  background: linear-gradient(90deg, transparent, #2860B4, transparent);
  background-size: 200%;  /* Makes the gradient fill twice the element’s width */
  animation: knightRider 1.5s ease-in-out infinite;  /* Slower speed for a smoother effect */
  border-radius: 50px;
}

@keyframes knightRider {
  0% { background-position: 0% 0; }
  70% { background-position: 100% 0; }  /* Moves to the right */
  100% { background-position: 0% 0; }  /* Returns to the left */
}
