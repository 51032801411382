.quota-copy-config-pop-up .config-apply-config-target,
.quota-copy-config-pop-up .select-target-type {
    margin-top: 15px;
    margin-bottom: 5px;
}

.quota-copy-config-pop-up .MuiDialogContent-root {
    min-height: 250px;
}

.quota-copy-config-pop-up .manage-config-pop-up .config-apply-config-target .value {
    display: block;
    margin-left: 0px;
}