/* INDEX PAGE CSS */

.agent-config-download-summary-btn {
    background-color: #244C84;
    border-radius: 4px;
    width: 109px;
    text-align: center;
}

.agent-config-download-summary-btn button {
    color: #fff;
    text-transform: none;
    letter-spacing: unset;
    font-weight: 700;
    padding: 6px 8px;
    font-family: 'Inter';
}

/* AGENT TABLE CSS */

.agent-table-container-hubEvent .margin-events {
    margin-bottom: 10px;
}

.agent-table-container-hubEvent .align-filters {
    display: flex;
    align-items: center;
}

.agent-table-container-hubEvent .globalEvents-FleetDashboard {
    max-height: calc(100vh - 170px);
}

.agent-table-container-hubEvent .MuiTextField-root label,
.agent-table-container-hubEvent .MuiFormControl-root label,
.siteCharts__filters .MuiTextField-root label,
.siteCharts__filters .MuiFormControl-root label {
    top: 14.67%;
    font-style: normal;
    font-weight: bold;
    font-size: 0.65em;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #676984;
}

.agent-table-container-hubEvent .MuiTextField-root legend,
.agent-table-container-hubEvent .MuiFormControl-root legend,
.siteCharts__filters .MuiTextField-root legend,
.siteCharts__filters .MuiFormControl-root legend {
    font-size: 0.65em;
}


.agent-table-tableContainer {
    border: 1px solid #EBEBEB;
    width: 100%;
}


.agent-table-tableContainer table {
    overflow-y: hidden;
}

.agent-table-tableContainer::-webkit-scrollbar {
    width: 6px;
}

.agent-table-tableContainer .agent-table-tableHead {
    box-shadow: -10px -10px 0px 0px #ffffff;
}


.agent-table-tableHead .agent-table-tableRow {
    background: #f5f5f5;
    height: 32px;
    border: 1px solid #EBEBEB;
    align-self: stretch;
    box-sizing: border-box;
    box-shadow: -10px -10px 0px 0px #ffffff;
}

.alertsTable-action-deleteIcon {
    cursor: pointer;
}

.agent-table-tableRow .agent-table-tableData {
    color: #8B8D97;
    text-align: center;
    letter-spacing: 1px;
    height: 35px;
    font-variant: small-caps;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    /* width: 16%; */
    padding: 0px;
}


.agent-table-tabelRow .agent-table-tableCell {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 10px 5px 10px 5px;
    font-weight: 500;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
}

.agent-table-edit-icon {
    color: #4873b8;
    cursor: pointer;
}

.agent-table-noDataAvailableCss {
    color: #273050 !important;
    background-color: white !important;
    font-size: 12px !important;
    font-weight: 400 !important;

}


/* FILTER */
.agent-table-filter--filter_menu_container_dashboard .MuiPaper-root {
    width: 274px;
    margin-left: 97px;
    margin-top: -5.7%;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}

.agent-table-filter--filter_menu_container_dashboard .am-table .FilterLeftContainer {
    height: auto;
}

.agent-table-filter--filter-popup {
    margin-top: 40px;
}

.agent-table-filter--sitesFilterHeaderMargin {
    display: flex;
    margin: 10px 16px 18px 16px;
}

.agent-table-filter--filter-header {
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 500;
    color: #45464E;
    line-height: 20px;
}


.agent-table-filter--activityReportsFilter-close {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: 174px;
    cursor: pointer;
}

.agent-table-filter--activityReportsFilter-close>img {
    margin-left: 173px;
    cursor: pointer;
}

.agent-table-filter--activityReportsFilter-close>div:nth-child(2) {
    font-size: 15px;
    margin-top: 3px;
    font-family: 'Inter';
    font-style: normal;
}


.agent-table-filter--filter-divider-width {
    margin-top: 18px;
}

.agent-table-filter--filter-divider-width .agent-table-filter--filter-border-color {
    border-color: #EBEBEB;
}

.agent-table-filter--usageFilterComponent {
    width: 100%;
}

.agent-table-filter--dash-filter-pop {
    margin: 0px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    font-family: 'Inter';
    font-style: normal;
}

.agent-table-filter--sitesFiltersPadding {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
}

.agent-table-filter--internetr-status-header-paddingBottom {
    padding-bottom: 12px;
}

.agent-table-filter--internet-status-header {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #45464E;
    line-height: 16.94px;
}

.agent-table-filter--filterComponents {
    color: #45464E;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
    line-height: 14.52px;
}


.agent-table-filter--filterComponents .agent-table-filter--sitesInternetStatusOptions {
    padding-bottom: 8px;
}

.agent-table-filter--sitesInternetStatusOptions .MuiFormControlLabel-label {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    color: #45464E;
    line-height: 14.52px;
}

.agent-table-filter--sitesInternetStatusOptions .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
    padding-right: 0px;
}

.agent-table-filter-filterPopup {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.5px;
    height: 16px;
}


.agent-table-filter--apply-filter-divider-width {
    margin-top: 8px;
}

.agent-table-filter--filter-divider-width .filter-border-color {
    border-color: #EBEBEB;
}

.agent-table-filter--apply-filter-divider-width .filter-border-color {
    border-color: #EBEBEB;
}

.agent-table-filter--clearFiletrBtnUsage {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    text-transform: none;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
    min-width: 78px;
}

.agent-table-filter--margin-left-filter {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 20px;
    margin-bottom: 12px;
}


.agent-table-filter--clearFiletrBtnDiv {
    border: none;
    display: flex;
    align-items: center;
}

.agent-table-filter--clearFiletrBtnDiv .clearFiletrBtnUsage {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    text-transform: none;
    line-height: 16.94px;
    color: #0E0548;
    padding: 0px;
    min-width: 78px;
}

.agent-table-filter--clearFiletrBtnDiv .margin-l-115 {
    margin-left: 115px;
}

.agent-table-filter--clearFiletrBtnDiv .margin-left-80 {
    margin-left: 0px;
}

.agent-table-filter--clearFiletrBtnDiv .usage-applyFilterBtn {
    text-transform: none;
    background-color: #1B57A7;
    border-radius: 4px;
    margin-right: 0px;
    margin-left: 60px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    color: white;
    float: right;
}

.agent-table-filter--clearFiletrBtnDiv .usage-applyFilterBtn:hover {
    background-color: #2F4B82;
}

.agent-table-elipse {
    max-width: 15ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}