.config-deploy-inventory-popup .config-apply-config-target {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.popup-fixed-position .config-apply-config-target {
    margin-top: unset;
}
.config-deploy-inventory-popup .config-apply-config-target>div {
    width: 59%;
}
.config-deploy-inventory-popup .config-apply-config-target>div>div {
    display: flex;
}

.config-deploy-inventory-popup .config-apply-config-target>div .key, .config-deploy-inventory-popup .config-note {
    color: #9A9A9A;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.config-deploy-inventory-popup .config-apply-config-target span {
    width: 40%;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.popup-fixed-position .config-apply-config-target div:nth-child(1) {
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    height: 37px;
    display: flex;
    align-items: center;
    margin: 3px 0px 3px 0px;
}

.config-deploy-inventory-popup .config-apply-config-target>div .value {
    font-family: Roboto;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    color: #45464E;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.config-deploy-inventory-popup .name-input-default-config>span {
    margin-top: 0px;
}

.config-deploy-inventory-popup .select-config-from {
    margin-bottom: 0px;
}

.config-deploy-inventory-popup .actions .config-create-btn {
    width: auto;
    background-color: #264C86;
}

.config-deploy-inventory-popup .actions .config-cancel-btn {
    border: 1px solid #264c86;
    color: #264c86;
}

.config-deploy-inventory-popup .actions .config-create-btn,
.config-deploy-inventory-popup .actions .config-cancel-btn {
    height: 35px;
    text-transform: uppercase;
}

.config-deploy-inventory-popup .actions .config-create-btn.disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.config-deploy-inventory-popup .config-note {
    color: red;
}