.MuiButtonBase-root.csv-upload--button_outlined_filter {
    border-radius: 4px;
    border-color: #244C84;
    padding: 7px 8px;
    justify-content: center;
    align-items: center;
    color: #244C84;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.MuiButtonBase-root.select-csv-button {
    border-radius: 4px;
    border-color: #244C84;
    padding: 7px 8px;
    justify-content: center;
    align-items: center;
    color: #244C84;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.MuiButtonBase-root.select-csv-button {
    width: 120px;
}