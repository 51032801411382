.tableTitle {
    letter-spacing: 0.06em;
    font-size: 8px;
    color: #676984;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    width: 100%;
}

.noData {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    font-size: 15px;
}

.tableHeaderRow .tableHeader {
    color: #2860B4;
    font-size: 12px;
    font-weight: bold;
}

.MuiTableRow-head .tableHeader {
    font-size: 12px
}
.mccMncTable {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 94% !important;
}