.inventory-logs-status-pop-up .MuiDialogContent-root.dialog-content {
    max-width: none;
    padding-top: 0px;
    padding-right: 15px;
}

.inventory-logs-status-pop-up .disp-flex {
    margin-bottom: 5px;
    margin-top: 5px;
}

.inventory-logs-status-pop-up .disp-flex .search-text {
    display: flex;
    align-items: center;
}
.inventory-logs-status-pop-up .disp-flex .search-text .config-status {
    margin-left: 10px;
    font-size: 14px;
    color: #45464E;
    font-family: 'Inter';
}

.inventory-logs-status-pop-up .ellipsis{
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Pointer {
    cursor: pointer;
}