
.totalUsageChartContainer {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    min-height: 271px;
    border-radius: 4px;
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-family: 'Inter';
}

.totalUsageChartContainer>div:nth-child(2) {
    width: 55%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-totalUsageChartContainer>div:nth-child(2) {
    display: block;
    padding-top: 50px;
}

.totalUsageChartContainer>div:nth-child(2)::-webkit-scrollbar {
    width: 6px;
}

.totalUsageChartContainer>div:nth-child(2)::-webkit-scrollbar-thumb {
    background-color: #EBEBEB !important;
}

.totalUsageChartContainer>div:nth-child(2)::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f5f5f5;
}

.totalUsageChartContainer>div:nth-child(1) {
    width: 40%;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #45464E;
    margin: 20px 0px 20px 10px;
    padding-top: 15px;
}

.usage-doughnutChart {
    width: 146px;
    height: 146px;
    margin-left: -10px;
}

.chartContainer-usage {
    display: flex;
}

.usageDetailsWithCategory {
    margin-top: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 10px;
}

.appcategoryUsed-usage {
    width: fit-content;
    height: fit-content;
    margin-bottom: 7px;
    display: flex;
}

.appcategoryUsed-usage>div:nth-child(2){
    width: 160px;
    height: 30px;
    margin-top: -12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    display: flex;
    gap: 5px;
    text-align: center;
}

.appcategoryUsed-usage>div:nth-child(2)>span {
    width: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.appcategoryUsed-usage>div:nth-child(2)>p {
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.activity-pieChart-app {
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
    cursor: pointer;
    color: #0000ff;
}
 
.usagepercentage {
    width: 55px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
}

.usageDot-1 {
    width: 10px;
    height: 10px;
    background: #00AD64;
    border-radius: 100px;
    margin-top: -2px;
    margin-right: 5px;
}

.rc--filter_search_categoryCont {
    position: relative;
}

.rc--filter_search_category {
    position: absolute;
    z-index: 1;
    width: 218px;
    height: 88px;
    background: white;
    border-radius: 4px;
    margin: 2px 0px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.usageTitleOverTime {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #45464E;
    width: 186px;
    height: 18px;
    margin: 10px 16px 0px 16px;
}

.selectTop5AppsBtn {
    width: 202px;
    height: 34px;
    background: #F4F5FA;
    border-radius: 8px;
    margin: 8px 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2F4B82;
    border: none;
    text-align: left;
    padding-left: 8px;
    cursor: pointer;
}

.rc--filter_header_appCategory {
    width: 170px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #45464E;
}

.rc--filter_header_appCategory .icon-entryLimit {
    margin-left: 13px;
}

.piechart-selectContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rc--filter_pie-usage {
    margin-top: -20px;
}

.pieChat-selectTag .selectEntry-pie {
    min-width: 40px;
    margin-left: 15px;
}

.appcategoryUsed-usage .pieChart-activity {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pieChart-activity>img {
    width: 15px;
    height: 15px;
}

.totalUsageChartContainer .usage-activity-pie {
    margin-left: -15px;
}

.activity-Form, .activity-Form .am-table, .am-table .activity-totalUsageChartContainer {
    height: 100%;
}

#activity-pie-container {
    display: block;
}

#activity-pie-container-1 {
    width: 100%;
}

#activity-pie-container-2 {
    width: 100%;
    padding-top: 0px;
}

#activity-appcategoryUsed-usage {
    width: 200px;
}

@media only screen and (max-width: 1500px) {
    .activity-totalUsageChartContainer {
        display: block;
    }
    .activity-totalUsageChartContainer>div:nth-child(1) {
        width: 100%;
    }
    .activity-totalUsageChartContainer>div:nth-child(2) {
        width: 100%;
    }
    .activity-Form .appcategoryUsed-usage>div:nth-child(2) {
        width: 200px;
    }
    .activity-totalUsageChartContainer>div:nth-child(2) {
        padding-top: 0px;
    }
}