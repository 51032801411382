.device-system-config{
    padding-bottom: 10px;
}

.device-system-config>div {
    display: flex;
    color: #45464E;
    font-family: 'Inter';
    font-size: 14px;
    height: 37px;
    margin: 2px 0px;
}

.device-system-config .MuiOutlinedInput-notchedOutline {
    height: 40px;
}

.device-system-config .content-left {
    width: 40%;
    display: flex;
    align-items: center;
}

.device-system-config .content-right {
    display: flex;
    align-items: center;
    width: 55%;
}

.device-system-config .content-right.action {
    justify-content: space-between;
}

.device-system-config .content-right.action .action-button {
    color: #fff;
    border: 1px solid #1B51A7;
    text-transform: capitalize;
    width: 100px;
    padding: 3.5px;
    margin-top: -2px;
    box-shadow: 0px 0px 13px 0px #CBCBCB59;
    background: #1B51A7;
    letter-spacing: unset;
    font-weight: 600;
    font-family: "Inter";
    background-color: #244c84;
    border-radius: 4px;
}

.device-system-config .content-right.action .action-button.disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    box-shadow: 0px 0px 13px 0px #cccccc;
}

.device-system-config .content-right.action .user-entered-config-name input{
    padding-left: 12px;
}
.device-system-config .content-right.action .user-entered-config-name,
.device-system-config .content-right.action .select-config > div,
.device-system-config .content-right .select-licence > div,
.device-system-config .content-right .select-licence-term > div {
    width: 250px;
}

.device-system-config .content-right.action .device-konnect-status .dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.device-system-config .content-right.action .device-konnect-status .online {
    background: rgb(105, 160, 62);
}

.device-system-config .content-right.action .device-konnect-status .offline {
    background: red;
}

.device-system-config .content-right.action .device-konnect-status .unknown {
    background: gray;
}
.device-system-config .change-license-info-icon{
    width: 20px;
    color: #45464E;
    margin-top: 2px;
    padding-left: 5px;
    cursor: pointer;
}
.device-system-config .config-upload-note {
    color: #ff0000;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}