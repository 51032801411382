.rc--filter_menu_container {
    width: 274px;
    max-height: 77vh;
    overflow-y: hidden;
}

.rc--filter_header {
    cursor: pointer;
}

.rc--apply_filter.MuiButton-root {
    background: #1B51A7;
    border-radius: 4px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: none;
    width: 103px;
    height: 29px;
    left: 62px;
    padding: 0px;
}

.remoteConnectionsMenu .remoteConnectionFilterShadow {
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    overflow-y: hidden;
}

.rc--apply_filter.MuiButton-root:hover { 
    background: #1B51A7
}

.rc--filter_footer {
    display: flex;
    padding: 20px 16px 12px 16px;
    align-items: center;
}

.rc--filter_menu--filter_button.MuiButton-root {
    border: 1px solid #1976d2;
    border-radius: 4px;
    color: #1976d2;
    font-family: "Inter";
}

.rc--filter_menu--filter_button.MuiButton-root:hover { 
    border: 1px solid #1976d2;
}

.pad-top-3 {
    padding-top: 3px;
}

.margin-l-115 {
    margin-left: 115px;
}

.rc-filter-header-padding {
    padding:  10px 16px 18px 16px;
    height: 20px;
}

.remoteConnectionsOptionOverflow {
    overflow-y: auto;
    height: calc(100vh - 480px);
    max-height: 297px;
}