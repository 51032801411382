.org--selection--option--pre0 {
    /* position: absolute; */
    left: 0.75rem;
    z-index: 1;
    /* width: 10px; */
    height: 1.5rem;
    content: "";
    border-left: 2px solid #e0e0e0;
}

.org--selection--option--pre1{
    /* position: absolute; */
    left: 0.75rem;
    z-index: 1;
    width: 0.75rem;
    height: 2px;
    content: "";
    border-bottom: 2px solid #e0e0e0;
}