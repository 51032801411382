.dialog-title-close-icon {
    text-align: end;
}

.dialog-box-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.dialog-body-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089 !important;
    margin-top: 0px !important;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}