.inventory-group-actions-tab-content {
    margin: 15px 20px 0px 20px;
}

.inventorySiteInfoMargin {
    margin: 0px 20px 0px 20px;
}

.inventory-group-actions-tab-content .group-buttons button {
    border-radius: 5px !important;
    margin-right: 15px;
    cursor: pointer;
}

.inventory-group-table .login-tableRow .CF-cell{
    padding: 0px;
}

.inventory-group-table .login-tableRow .CF-cell > span{
    justify-content: center;
}

.inventory-group-table  .loginHistory-tableRow .login-left-align{
    max-width: 100px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inventory-group-table-devices>div {
    padding: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    background: #fff;
    height: 25px;
    max-width: 150px;
    margin-right: 5px;
}

.login-left-align .inventory-group-table-more-devices {
   cursor: pointer;
}