.stateHistoryTableHeadCell{
    border: 1px solid #EBEBEB;
    border-radius: 4px 0px 0px 0px;
}

.stateHistoryTableHead{
    background: #F5F5F5;
}

.stateHistoryTableHeadTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    font-variant: small-caps;
    color: #8B8D97;
}

.stateHistoryTableRowCell{
    border: 1px solid #EBEBEB;
}