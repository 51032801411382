input:-webkit-autofill {
  color: brown !important;
}

.send-button {
  border: 1px solid #676984 !important;
  /* color: #fff !important; */
  width: -webkit-fill-available !important;
  height: 38px !important;
  border-radius: 4px !important;
  font-size: 13px !important;
  line-height: 12px !important;
}

.role-button {
  border: 1px solid #264c86 !important;
  background-color: #264c86 !important;
  color: #fff !important;
  width: -webkit-fill-available !important;
  height: 35px !important;
  border-radius: 4px !important;
}

.overflow-test {
  white-space: nowrap !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  text-overflow: hidden !important;
  width: 228px ;
}

.account-column {
  min-width: 70px;
}

.row-odd {
  background: "#ffff";
  color: #273050;
  height: 35.69px !important;
}

.row-even {
  background-color: -webkit-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: -moz-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: -ms-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: -o-linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  background-color: linear-gradient(180deg, #4984f4 0%, #3b7cfb 100%);
  color: #273050;
  height: 35.69px !important;
}

.icon-cursor {
  cursor: pointer;
}

.ResendInviteButton {
  background: #ffffff !important;
  border: 1px solid rgba(53, 83, 137, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em !important;
  text-transform: capitalize !important;
  color: #002a7c !important;
  white-space: nowrap;
  box-shadow: 0px 4px 10px 0px #6769841a;
}

.AccountRowUserName {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0 !important;
  opacity: 0.75;
}

.AccountRowUserName span {
  display: flex;
}

.AccountRowUserName .statusRow {
  display: grid;
}

.highlightedContInTable .AccountRowUserName {
  border-bottom: none;
}

.AccountRowUserName .MuiChip-label {
  padding-top: 3px;
}

.CancelInviteButton {
  border: 1px solid #264c86 !important;
  color: #264c86 !important;
  width: -webkit-fill-available !important;
  border-radius: 4px !important;
}

.CancelRoleEditButton {
  border: 1px solid #264c86 !important;
  color: #264c86 !important;
  height: 35px;
  width: -webkit-fill-available !important;
  border-radius: 4px !important;
}

.onHover:hover {
  text-decoration: underline;
  color: #264c86;
  cursor: pointer;
}

.newBoxRole {
  background-color: #f5f9ff;
  max-width: 630px;
  /*height: 170px;*/
  height: auto;
  padding-bottom: 10px;
  border-radius: 15px;
  margin-top: 22px;
}

.textFieldRole {
  margin-left: 20px !important;
}

.newRoleName {
  margin-top: 20px !important;
}

.newResouceText {
  /* background-color: #fff; */
}

.newRoleNameText {
  margin-top: 20px !important;
  margin-left: 60px !important;
  background-color: #fff;
}

.addPolicyButton {
  background: #264c86;
  border-radius: 5px !important;
  color: #fff;
  height: 37px;
  margin-top: 15px !important;
  border: 1px solid #676984 !important;
}

.autocompleteText {
  margin-bottom: 15px;
}

.cssForTextFliedSpace {
  margin-top: 20px !important;
  margin-left: 20px !important;
  /* background-color: #fff; */
}

.autocompleteCssPadding.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root {
  height: 48px !important;
  overflow: hidden;
  padding-right: 15px !important;
}

.searchFilterText{
  height: 55px !important;
}

.accountManagement-filter>div{
  margin-top: 15px ;
}
.accountManagement-filter+div{
  margin-top: 16px ;
}

.tableBody {
  z-index: 1;
}

.tableHead { 
  position: sticky; 
  top: 0;
  z-index: 999;
}

.RolesList>div {
  margin: 1px;
}
.FleetDashboard.account-table .account-group-column-hide {
  display: none;
}

.am-table .accounts-help-container {
  display: flex;
  justify-content: flex-end;
}

.accounts-help-container .buttonCssFleet_width {
  margin-right: 0px;
}

.accounts-help-container .buttonCssFleet_width:hover {
  background: none;
}

.Form-accountManagement>div>div:nth-child(2){
  height: calc(100vh - 330px);
}

.accounts-fleetdashboard.MuiTableContainer-root {
  width: unset;
  overflow-x: unset;
}

/* @media only screen and (max-height: 400px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 130px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 185px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 235px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 285px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 335px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 340px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 650px) and  (max-height: 690px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 410px;
    overflow-y: auto;
  }
}

 @media only screen and (min-height: 690px) and  (max-height: 740px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 385px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 740px) and  (max-height: 790px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 485px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 790px) and  (max-height: 849px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 505px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 849px) and  (max-height: 899px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 630px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:899px) and (max-height:950px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 680px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:950px) and (max-height:999px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 730px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:999px) and (max-height:1050px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 780px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1050px) and (max-height:1080px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 830px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1080px) and (max-height:1140px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 860px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1140px) and (max-height:1200px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 920px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1200px) and (max-height:1300px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 980px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1300px) and (max-height:1400px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 1080px;
    overflow-y: auto;
  }
}

@media only screen and (min-height:1400px) and (max-height:1920px){
  .Form-accountManagement>div>div:nth-child(2) {
    max-height: 1660px;
    overflow-y: auto;
  }
} */

.accountHead .createAccountHeaders {
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #676984;
  line-height: 14px;
}

.userDp .DPVesselName{
  width: 300px;
  margin-top: 10px;
}

.twofaCheckBox {
  margin-left: 5px;
}

.filterStateName_filterBy .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding: 0px !important;
}

.updatePrivilegesValue{
  font-size: 16px;
  color: #264C86;
  line-height: 14px;
}

.updatePrivilegesHeading{
  font-size: 16px; 
  color: #676984;
  line-height: 14px;
}

.paddingTop10{
  padding-top: 10px;
}

.noWrapAccounts {
  text-wrap: nowrap;
  width: auto;
}

.dp-textWrap {
  width: 20ch ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: none;
}

.accounts-tabelRow .accounts-tableCell{
  font-size: 12px;
  color: #45464E;
  font-family: 'Inter';
  font-style: normal;
  text-align: center;
  letter-spacing: 1px;
  background: #FFFFFF;
  padding: 10px 5px 10px 5px;
  font-weight: 500;
  /* width: 100px; */
  white-space: nowrap;
  /* max-width: 100px; */
  text-overflow: ellipsis;
  overflow: hidden;
}

.inter-font {
  font-family: 'Inter';
  font-size: 14px;
  color: #45464E;
}

.inter-font-parent .inter-font {
  font-family: 'Inter';
  font-size: 14px;
  color: #45464E;
}

.inter-font-parent .margin-adj {
  margin-top: -8px;
}

.menuScroll .invite-user-padding {
  padding: 10px 10px 0px 24px;
}

.set-pwd-pad-parent .set-pwd-pad {
  padding: 10px 10px 0px 20px;
}