.starlink--base--mtb_07x .starlinkInsightsMaxwidth {
    max-width: 20%;
}

.downloadHeight {
    height: 38px;
}

.alignStarlinkInsightsTopbar {
    align-items: center;
}