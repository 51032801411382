/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(207, 208, 221, 1);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(207, 208, 221, 1);
}

.sideBar {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.summary {
  border-radius: 4px;
  /* margin-left: 25px; */
  margin-right: 25px;
}

.SubHeader {
  padding-left: 14px !important;
  padding-right: 15px !important;
}

.new-summary-dash-container {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.summary-charts-comp {
  margin: 0px 17px;
}

.summary-charts-comp-avai {
  margin: 0px 18px 0px 22px;
}

.site-dashboard-NewSummary .padding-l-r {
  padding-left: 12px;
  padding-right: 7px;
}

@media only screen and (min-width: 600px) {
  .mega {
    position: fixed;
    margin-left: 45px;
    z-index: 1000;
  }
}

@media only screen and (max-width: 600px) {
  .mega {
    position: relative;
    margin-left: 45px;
    z-index: 1000;
  }
}

.site-dashboard--container.MuiGrid-root {
  /* margin-left: 88px; */
  width: 100%;
  margin-top: 80px;
  overflow-y: scroll;
  height: calc(100vh - 90px);
  background: white;
}

.summary {
  margin: 10px;
  padding: 0px 11px;
}

.SubHeader {
  background: white;
  z-index: 10;
  border-bottom: 1px solid #ccc;
}

.SubHeader_NewSummary {
  background: #ffffff;
  z-index: 10;
  padding-left: 21px ;
  padding-right: 21px ;
  /* border-bottom: 1px solid #ccc; */
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-17 {
  padding-right: 17px;
}

.padding-r-l-0 {
  padding-left: 0;
  padding-right: 0;
}

.site-dashboard-NewSummary.MuiGrid-root {
  /* margin-left: 88px; */
  width: 100%;
  overflow-y: hidden;
  max-height: calc(100vh - 79px);
}

.SubHeader.dashboardTabs {
  background-color: #ffffff;
  text-transform: none;
}

.NewSummaryDashContainer{
  height: calc(100vh - 150px);

  overflow-y: auto;
}