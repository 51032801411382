.accordTree li {
  list-style-type: none;
  position: relative;
}
.accordTree li li {
  list-style-type: none;
  position: relative;
}
.accordTree li li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -20px;
  border-left: 1px solid #ddd;
  width: 20px;
  height: 15px;
}

.accordTree li li:before {
  border-bottom: 1px solid #ddd;
}

.accordTree li li:after {
  position: absolute;
  content: "";
  top: 5px;
  left: -20px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 20px;
  height: 100%;
}
.accordTree li li:last-child:after {
  display: none;
}

.accordionContainer {
  width: 100%;
}

.accordian .Mui-expanded .chipsContainer .MuiChip-root {
  /* height: 18px; */
  border-bottom: 1px solid #ddd;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper  .MuiSvgIcon-root  {
  transform: rotate(270deg);
  height: 20px;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root  {
  transform: rotate(180deg);
  height: 20px;
}

.chipsContainer .MuiChip-root {
  margin-right: 5px;
  color: #6E7079;
  margin-left: 1px;
}

.chipsContainer {
  white-space: nowrap;
  overflow-y: hidden;
}

.accordionSummaryAlert .MuiAccordionSummary-content {
  align-items: center;
  margin: 0px;
}

.chipsContainer .chipKey {
  color: #2F4B82;
}

.chipsContainer .chipValue {
  color: #6E7079;
}

.accordian .accordionSummaryAlert {
  /* border-bottom: 1px solid #ddd; */
  min-height: auto;
}

/* .gateSection,.accordionHead {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #ddd;
} */



.accordTree ul {
  margin: 0px;
}

.accordionTableHeader {
  border: 1px solid #ddd;
  background: #F5F5F5;
  display: flex;
  border-radius: 4px 4px 0px 0px;
}

.accordionTableHeader .eachAccordionHeadItem {
  border-right: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

.eachAccordionHeadItem  .eachAccordColHead {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
}

.eventsHeading {
  padding: 2px;
}

.alertNameText input {
  padding: 5px;
  font-size: 12px;
}
.alertDescriptionText {
  width: 94%;
}
.alertDescriptionText input {
  padding: 5px;
  font-size: 12px;
}

.gateSection .alertNameHead {
  font-size: 12px;
  padding-left: 2px;
  padding-bottom: 2px;
}

/* .gateSection {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
} */

.gateBox {
  border: 1px solid #ddd;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}

.gateBox .andGateText {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #2F4B82;
}
.gateContainer {
  display: flex;
}

.radioButton {
  margin-left: 50px;
}

.GreenColor {
  color: #25C381;
}

.RedColor {
  color: red
}

.YellowColor {
  color: rgb(233, 233, 3)
}

.OrangeColor {
  color: #CF833D;
}

.GreenColor,.RedColor,.YellowColor {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  font-family: 'Inter';

}

.alertStatusAccordhead {
  display: flex;
}

.alertStatusWithIcon {
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.alertStatusAccordhead .alertName {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter';
}

.alertStatusAccordhead .gateName ,.eventsHeading .rulesHead {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.alertStatusAccordhead .gateName {
  text-transform: uppercase;
  color: #2F4B82;
  height: 15px;
}

.gateName .MuiChip-label {
  padding: 6px;
}

.gateChipContainer {
  display: flex;
  align-items: center;
}

/* .firstInnerTableCol {
  height: 40px;
  display: flex;
  align-items: center;
  border-right: 1px solid #ddd;
  min-width: 165px;
} */

.accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: 0;
}

.childAccordion .accordionSummaryAlert {
  padding: 0px;
}

/* .accordionSummaryAlert .MuiAccordionSummary-content {
  align-items: stretch;
} */
.accordionSummaryAlert .eachChildAccordCol {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
} 

.accordionSummaryAlert.childAccord .MuiAccordionSummary-content  {
  align-items: stretch;
}

.accordian .alertsAccordions {
  box-shadow:0 0 2px rgba(224, 224, 224, 1);
}

.alerts-alertsConfig-alertsAccordions {
  background-color: #F5F5F5;
}
.accordian .alertsAccordions .accordionSummaryAlert.Mui-expanded {
  border-bottom: 1px solid #ddd;
}
.statusName {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  font-family: 'Inter'
}
.cursor-auto {
  cursor: auto;
}
.chipsContainer .cursor-pointer {
  cursor: pointer;
}
.chipsContainer .chipValue{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.display-flex {
  display: flex;
}
.chipsContainer .chip {
  width: 28%;
}
.moreLabelsDialog .MuiDialog-scrollPaper {
  justify-content: end;
}
.moreLabelsDialog .moreLabelsDialogGrid {
  padding: 10px 5px 15px 5px;
  grid-gap: 15px;
  max-height: 150px;
  overflow-y: auto;
}
.moreLabelsDialogCloseIconGrid{
  height: 15px;
  display: flex;
  justify-content: end;
}
.moreLabelsDialogCloseIconGrid img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.moreLabelsDialog .MuiPaper-root{
  margin-top: -11%;
}