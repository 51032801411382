 .markdown > h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.color-white{
    color: #fff;
}

.version {
    font-weight: bold;
    font-size: 18px;
}

.MaintainenceNote {
    font-size: 12px;
}