.filterTextWrap {
    width: 170px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edge_events_more_filters_dialog .MuiPaper-root{
    width: 274px;
    height: 601px;
    background: #FFFFFF;
    box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
    border-radius: 8px;
    position: absolute;
    top: 30px;
}

.edge_events_more_filters_dialog_status{
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    border-radius: 10px;
    width:auto;
}

.marginBottom20px{
    margin-bottom: 20px;
}

.edge_events_more_filters_dialog_status .edge_events_more_filters_dialog_status_title{
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    align-items: center;
    color: #45464E;
    letter-spacing: 0.05rem;
}

.edge_events_more_filters_dialog_status .edge_events_more_filters_dialog_status_title{
    font-weight: 600 ;
    margin-bottom: 12px;
}

.events-gridflex {
    display: flex;
    margin: 0px 16px 8px 0px;
    padding-bottom: 0px;
    height: 16px;
}

.events-gridflex .MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

.events-gridflex .filterTextWrap {
    padding-bottom: 0px;
    padding-left: 8px;
    font-family: 'Inter';
    line-height: 14.52px;
    font-size: 12px;
}

.events-search .events-search-bar {
    width: 242px;
    height: 32px;
    margin-bottom: 12px;
}

.events-search .no-device-text-events {
    margin: -5px 0px 5px 5px;
    width: 150px;
    font-size: 12px;
    color: red;
    display: block;
}

 #pop-up1 {
    margin-left: 445px;
}

#pop-up2 {
    margin-left: 353px;
}

.edge-events-filter-actions {
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 274px;
    padding: 20px 16px 20px 16px;
    background-color: white;
    border-top: 1px solid #EBEBEB;
}

.filter-parent .filter-top {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 0;
}

.filter-parent .eventFilterBorderColor {
    border-color: #EBEBEB;
}

.clear-edge-filter {
    /* margin-left: auto; */
    padding-right: 0px;
    /* justify-content: end; */
}

.filter-body-container {
    height: calc(100vh - 357px);
    overflow-y: auto;
    max-height: 475px;
}

.filter-head-parent {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
    width: 242px;
}

.clearFiletrBtnDiv .margin-left-80 {
    margin-left: 16px;
}
