.Analytics-Dashboard-Container {
    margin: 15px 15px 15px 25px;
}

.Form .Analytics-Dashboard-Container .analytics-sub-tabs-container {
    display: flex;
    gap: 10px;
    padding-left: 76px;
}

.analytics-sub-tabs-container .analytics-sub-tabs {
    text-transform: none;
    border: 1px solid rgba(36, 76, 132, 1);
    height: 32px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}

#selectedTab-background {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    background: #eff1f0;
}

.download-expand-icons-container {
    display: flex;
}

.download-expand-icons-container img {
    cursor: pointer;
}

.download-expand-icons-container .margin-left-6 {
    margin-left: 6px;
}

.Analytics-Dashboard-Container .analytics-cards-container {
    padding-top: 5px;
}

.card-box-parent .analytics-card-contents {
    justify-content: space-between;
}

.analytics-card-contents .content_bottom_ns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.analytics-card-contents .no-padding-margin {
    margin: 0;
    padding: 0;
}

.content_bottom_ns .right-content-value {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: rgba(69, 70, 78, 1);
}

.analytics-filters-popup-conatiner .MuiPaper-root {
    width: 276px;
    padding: 10px;
}

.analytics-bar-chart-icons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.hide-axis-line .highcharts-axis-line {
    display: none;
}

.analytics-bar-chart-icons img {
    cursor: pointer;
}

.filter-dateRange-container{
    width: 1500px;
}

.strPlans-icon-padding{
    padding: 0px;
}

.strPlans-pln-hir-name{
    display: flex;
    align-items: center;
}

.strPlans-pd-r-10{
    padding-right: 10px;
}

.strPlan-tableRow .strPlan-background-blue{
    background-color: #53E1AE;
}

.strPlan-tableRow .strPlan-color-blue{
    color: blue;
}

.strPlan-tableRow .strPlan-background-transparent{
    background-color: transparent;
}

/* display: "flex", alignItems: "center", justifyContent: "center" */
.strPlans-arrowBtn-grid{
    display: flex;
    align-items: center;
    justify-content: center;
}

.strPlans-arrowBtn-div{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.strPlans-arrowBtn-div .strPlan-arrowBtn{
    padding: 2px;
    background: #d1d5d9;
    height: 40px;
    width: 40px;
    border-radius: 10px;
}

.strPlan-sl-table .strPlans-table-cell {
    cursor: pointer;
    background-color: transparent;
  }
  
  .strPlan-sl-table .highlighted {
    background-color: #53E1AE;
  }

  .strPlan-sl-table .highlighted-purpule{
    background-color: #8CC5F6;
  }

  .middleTables{
    padding: 15px;
    background: #f5f5f5;
    border-radius: 20px;
  }

  .strPlans-senderAssociated span {
    color: blue;
    cursor: pointer;
  }

  .strPlans-tooltip-grid{
    /* style={{ maxHeight: '300px', overflowY: 'auto' }} */
    max-height: 300px;
    overflow-y: auto;
  }

  .middleTable .strPlans-senderGrid{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 8px;
  }

  .middleTable .strPlans-senderGridTop{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }

  .strPlans-senderGridTop .strPlans-senderTypo{
    font-size: 12px;
    font-weight: 700;
    padding-left: 10px;
    /* font-weight: bold; */
  }
  
  .strPlans-senderGrid .strPlans-senderAssociated{
    font-size: 15px;
  }

  .strPlans-pb10hierarchy{
    padding: 10px;
    border-radius: 10px;
    background: #F3F2F2;
  }

  .middleTable .strPlans-pb10 {
    background-color: #F3F2F2;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .strPlans-pb10.selectedRight {
    border: 2px solid #8CC5F6;
  }

  .strPlans-pb10.selectedMiddle {
    border: 2px solid #53E1AE;
  }

  /* .strPlan-tableRow:hover {
    border: 2px solid #53E1AE;
  }
  
  .strPlan-tableRow.selected {
    border: 2px solid #53E1AE;
  } */
  
  .strPlans-pb10:hover {
    transform: scale(1.01);
  }

  .strPlans-pb10 .configTable{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .strPlans-pb10hierarchy .configTable{
    display: flex;
    align-items: flex-start;
    padding-bottom: 10px;
  }

  .configuration-form-build .strPlan-plan-dd{
    width: 200px;
    height: 42px;
    margin-top: 6px;
  }

  .strPlan-tableHeight{
    height: 390px;
  }

  .strPlans-senderGrid .strPlans-selectAll{
    border: 1px solid #264C86;
    background: #E8F0FD;
    border-radius: 5px;
  }

  .strPlans-senderGrid .strPlans-cancel{
    border: 1px solid #264C86;
    border-radius: 5px;
  }

  .strPlan-tableHierarchy-Height{
    height: 550px;
  }

  .strPlan-tableRow .starPlan-hierarchy-table-cell {
    white-space: nowrap;
    display: flex;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
  }
  
  .strPlan-tableRow .starPlan-hierarchy-table-cell.higlightRightPlan {
    background-color: #8CC5F6;
  }

  .strPlan-tableRow .starPlan-hierarchy-table-cell.higlightMiddlePlan {
    background-color: #53E1AE;
  }

  .strPlan-actionBtns{
    padding-right: 30px;
  }

  .revert-entry-grid {
    margin-bottom: 20px;
    margin-left: -23px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .strPlans-email-label {
    flex: 1;
  }
  
  .strPlans-email-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .strPlans-email-container .strPlans-email-paper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #c2d3eb;
    box-shadow: none;
    width: 500px;
  }

  .strPlans-email-paper .starPlans-email-textfield {
    width: auto;
    flex-grow: 1;
    min-width: 400px;
  }
  
  .strPlans-email-paper .starPlans-email-textfield .MuiInputBase-root {
    display: flex;
    flex-wrap: wrap;
  }
  
  .strPlans-email-paper .starPlans-email-textfield .MuiInputBase-input {
    flex: 1;
  }
  
  .strPlans-email-paper .starPlans-email-textfield-input {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    height: auto;
  }

  .strPlans-dialog {
    width: 500px;
    max-width: 500px;
    border-radius: 16px;
    padding: 16px;
  }
  
  .strPlans-title {
    font-size: 20px;
    font-weight: 600;
  }
  
  .strPlans-text {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
  }
  
  .strPlans-from {
    font-weight: 600;
  }
  
  .strPlans-to {
    font-weight: 600;
  }
  
  .strPlans-listItem {
    display: flex;
    align-items: center;
    background-color: #F6F1FE;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 12px;
  }
  
  .strPlans-number {
    background-color: #DCCFFC;
    color: #7D3AE2;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  
  .strPlans-listText {
    margin-left: 16px;
  }
  
  .strPlans-dialogActions .strPlans-backButton {
    color: #9378E8;
    font-weight: 600;
  }
  
  .strPlans-dialogActions .strPlans-moveButton {
    background-color: #C4B5FD;
    color: #fff;
  }
  
  .strPlans-dialogActions .strPlans-moveButton:hover {
    background-color: #9378E8;
  }

  .strPlans-senderGridTop .strPlans-PlanIcon-grid{
    display: flex;
    align-items: center;
  }

  .strPlans-senderGridTop .strPlans-PlanIconMiddle{
    height: 10px;
    width: 25px;
    border-radius: 4px;
    background: #53E1AE;
  }

  
  .strPlans-senderGridTop .strPlans-PlanIconRight{
    height: 10px;
    width: 25px;
    border-radius: 4px;
    background: #8CC5F6;
  }

  .strPlans-text .PlanIconRight{
    height: 10px;
    width: 25px;
    border-radius: 4px;
    background: #8CC5F6;
  }

 .strPlans-text .PlanIconMiddle{
    height: 10px;
    width: 25px;
    border-radius: 4px;
    background: #53E1AE;
  }

  .strPlan-selectPool{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-style: italic;
    font-weight: 700;
    color: #C2C2C2;
    padding: 0px 50px;
  }


  .service-lines-container {
    display: flex;
    gap: 16px;
    padding-bottom: 5px;
    background: #F3F2F2;
  }
  
  .service-lines-card {
    width: 200px;
    height: 50px;
    background-color: #E7E7E7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 4px;
  }
  
  .label {
    font-size: 14px;
    color: #555;
    text-align: left; /* Align text to the left */
  }
  
  .highlight {
    color: #007bff;
    font-size: 18px;
    text-align: right;
    font-weight: 700; /* Align number to the right */
  }
  

  .strPlans-searchbarBox .MuiTextField-root{
    width: 100%;
  }

  .strPlans-searchbarBox .strPlans-searchbarInputs{
    padding: 8px;
    height: 32px;
    border: 1px solid #244C84;
    border-radius: 8px;
  }

  .strPlan-tableHierarchy-Height .plan-hierarchy-height{
    height: 500px;
  }

  .config-apply-config-target .configuration-form-build .strPlans-quotaActions{
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 5px;
  }

  .strPlan-marginGrid .strPlans-marginTop{
    margin-top: 8px;
  }
  
  
  
