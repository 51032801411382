.usageReports-tableHead .login-tableRow .alert-left-align {
    text-align: left;
    padding-left: 15px;
}

.remove-padding .inter-font {
    padding: 0px 15px;
}

.alerts-tableHead .login-tableRow .details-width {
    width: 1%;
    text-align: center;
    padding-left: 0px;
}

.login-tableRow .alert-width {
    width: 9%;
}

.login-tableRow .wan-width {
    width: 4%;
}

.alert-left-align .alerts-name-a {
    color: #0c13ef;
}

.search-dropdown-limit-div .alerts-margin-left-20 {
    margin-left: 25px;
}

.search-dropdown-limit-div .desclaimer-text {
    margin-left: 16px;
}

.MuiTextField-root.input-box-alerts-tbl {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 16px;
    margin-right: 16px;
    width: 300px;
}