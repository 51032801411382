.device-organization-config>div {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #45464E;
    font-family: 'Inter';
    font-size: 14px;
}

.device-organization-config-no-padding>div {
    padding: 0px;
}

.device-organization-config .current-organization {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.device-organization-config .content-left {
    width: 40%;
    display: flex;
    align-items: center;
}

.device-organization-config .content-right {
    display: flex;
    align-items: center;
    width: 55%;
}

.device-organization-config .content-right.action {
    justify-content: space-between;
}

.device-organization-config .content-right.action .action-button {
    color: #fff;
    border: 1px solid #1B51A7;
    text-transform: none;
    width: 150px;
    padding: 3.5px;
    box-shadow: 0px 0px 13px 0px #CBCBCB59;
    background: #1B51A7;
    letter-spacing: unset;
    font-weight: 600;
    font-family: "Inter";
    background-color: #244c84;
    border-radius: 4px;
}

.device-organization-config .current-organization {
    width: 200px;
}

.device-organization-config .change-org-dp-dropdown .DPvesselDropdown .MuiButtonBase-root {
    width: 200px;
    height: 37px;
}

.device-organization-config .change-org-width-250px .DPvesselDropdown .MuiButtonBase-root {
    width: 250px;
    height: 37px;
}

.device-organization-config  .action-button-disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    box-shadow: 0px 0px 13px 0px #cccccc;
    text-transform: none;
}

.device-organization-config .action-button-disabled:hover {
    background-color: #cccccc;
}

.Note-Message {
    font-size: 14px;
    margin-top: 30px;
    font-family: 'Inter';
    line-height: 18px;
    color: #666666;
}

.custom-word {
    font-weight: 600;
    color: #45464e;
}

.edit-actions-subTitle .move-to-warehouse-confirm-btn {
    color: #1b51a7;
    border: 1px solid #1b51a7;
    text-transform: capitalize;
    width: 65px;
    padding: 2px;
    box-shadow: 0 0 13px 0 hsla(0, 0%, 79.6%, .34901960784313724);
    margin-right: 25px;
    cursor: pointer;
}

.content-right .de-red-reason-text input {
    width: 100%;
}

#errorspan-inventory {
    font-size: 12px;
    font-family: 'Inter';
    color: red;
    margin-left: 370px;
    margin-top: -8px;
}

.change-org-dp-dropdown .DPvesselDropdown .DPtextWrap, .change-org-dp-dropdown .DPvesselDropdown .MuiTouchRipple-root {
    width: 100%;
}

.change-org-dp-dropdown .DPdropdownArrow {
    margin-left: unset;
}

.popup-fixed-position+#DPmenu-ou .MuiPaper-root {
    width: 250px;
}

.popup-fixed-position+#DPmenu-ou .DPchildHierarch .MuiTypography-root {
    text-overflow: ellipsis;
    overflow: hidden;
}

.change-org-width-250px .DPvesselDropdown .MuiButtonBase-root {
    border-radius: 8px;
}