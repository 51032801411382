@media only screen and (max-height: 400px){
  .softwareVersionsDeviceDetails {
    max-height: 250px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 400px) and  (max-height: 450px){
  .softwareVersionsDeviceDetails {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 450px) and  (max-height: 500px){
  .softwareVersionsDeviceDetails {
    max-height: 350px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 500px) and  (max-height: 550px){
  .softwareVersionsDeviceDetails {
    max-height: 400px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 550px) and  (max-height: 600px){
  .softwareVersionsDeviceDetails {
    max-height: 450px;
    overflow-y: auto;
  }
}

@media only screen and (min-height: 600px) and  (max-height: 650px){
  .softwareVersionsDeviceDetails {
    max-height: 500px;
    overflow-y: auto;
  }
}
  
  @media only screen and (min-height: 650px) and  (max-height: 690px){
    .softwareVersionsDeviceDetails {
      max-height: 550px;
      overflow-y: auto;
    }
  }
  
   @media only screen and (min-height: 690px) and  (max-height: 740px){
    .softwareVersionsDeviceDetails {
      max-height: 590px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 740px) and  (max-height: 790px){
    .softwareVersionsDeviceDetails {
      max-height: 640px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 790px) and  (max-height: 849px){
    .softwareVersionsDeviceDetails {
      max-height: 690px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height: 849px) and  (max-height: 899px){
    .softwareVersionsDeviceDetails {
      max-height: 750px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:899px) and (max-height:950px){
    .softwareVersionsDeviceDetails {
      max-height: 800px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:950px) and (max-height:999px){
    .softwareVersionsDeviceDetails {
      max-height: 850px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:999px) and (max-height:1050px){
    .softwareVersionsDeviceDetails {
      max-height: 900px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1050px) and (max-height:1080px){
    .softwareVersionsDeviceDetails {
      max-height: 950px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1080px) and (max-height:1140px){
    .softwareVersionsDeviceDetails {
      max-height: 980px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1140px) and (max-height:1200px){
    .softwareVersionsDeviceDetails {
      max-height: 1040px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1200px) and (max-height:1300px){
    .softwareVersionsDeviceDetails {
      max-height: 1100px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1300px) and (max-height:1400px){
    .softwareVersionsDeviceDetails {
      max-height: 1190px;
      overflow-y: auto;
    }
  }
  
  @media only screen and (min-height:1400px) and (max-height:1920px){
    .softwareVersionsDeviceDetails {
      max-height: 1600px;
      overflow-y: auto;
    }
  }

    .margin-5 {
      margin-left: 5px;
    }
  
    .height40 {
      height: 40px;
    }

    .margin-left25 {
      margin-left: 25px;
    }