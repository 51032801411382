.custom-password-validator {
    padding: 1rem;
    border-radius: .375rem;
    background-color: #f9fafb;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.custom-password-validator .container {
    width: 100%
}

@media (min-width: 640px) {
    .custom-password-validator .container {
        max-width: 640px
    }
}

@media (min-width: 768px) {
    .custom-password-validator .container {
        max-width: 768px
    }
}

@media (min-width: 1024px) {
    .custom-password-validator .container {
        max-width: 1024px
    }
}

@media (min-width: 1280px) {
    .custom-password-validator .container {
        max-width: 1280px
    }
}

@media (min-width: 1536px) {
    .custom-password-validator .container {
        max-width: 1536px
    }
}

.custom-password-validator .rpv-container {
    padding: 1rem;
    border-radius: .375rem;
    width: 100%;
    background-color: #f9fafb;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f
}

.custom-password-validator .rpv-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 1rem
}

.custom-password-validator .rpv-rule {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;
    grid-column: span 1 / span 1;
    align-items: center
}

.custom-password-validator .rpv-progress-bar {
    -moz-transition-property: all;
    -moz-transition-timing-function: cubic-bezier(.435, .15, 0, 1);
    -moz-transition-duration: .8s;
    -moz-transition-delay: 0ms;
    -webkit-transition-property: all;
    -webkit-transition-timing-function: cubic-bezier(.435, .15, 0, 1);
    -webkit-transition-duration: .8s;
    -webkit-transition-delay: 0ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(.435, .15, 0, 1);
    transition-duration: .6s;
    transition-delay: 0ms;
    border-radius: .25rem;
    height: .5rem;
    position: absolute;
    bottom: 0;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f
}

.custom-password-validator .rpv-copy-password,
.custom-password-validator .rpv-copied-to-clipboard {
    position: absolute;
    right: 2rem
}

.custom-password-validator .rpv-generate-new {
    position: absolute;
    right: .5rem
}

@media (max-width: 640px) {
    .custom-password-validator .rpv-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }
}

.custom-password-validator .rpv-suggested-password {
    display: none !important;
}

.custom-password-validator .rpv-grid {
    font-weight: 500;
}