.generated-reports-container {
    margin: 10px 0px 15px 0px;
}

.generated-reports-container>div:nth-child(1) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #45464E;
    margin-bottom: 15px;
}

.generatedReportsContainer .generatedReportsCount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #45464E;
}

.generated-reports-table {
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1)
}

.reports-gene-container {
    margin-bottom: 10px;
}

.config-tableRow .generated-reports-head {
    background: #F5F5F5;
    color: #8B8D97;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    padding-left: 16px;
    letter-spacing: 1px;
}

.config-tableRow .s-no {
    width: 3%;
    text-align: center;
    padding: 0px;
}

.config-tableRow .metadata {
    width: 5%;
}

.config-tableRow .actions {
    width: 5%;
    text-align: center;
    padding: 0px;
}

.usage-tableBody .gene-repo td {
    padding: 0px 7px 0px 16px;
}

.gene-repo .download-report {
    cursor: pointer;
    color: #0000ff;
}

.gene-repo .download-report-inProg {
    color: #45464E;
}

.generated-report-status {
    display: flex;
    justify-content: left;
    align-items: center;
    text-transform: capitalize;
}

.search-report-count .input-box-generated-reports {
    margin-left: 0px;
}
.search-report-count {
    display: flex;
    align-items: center;
    gap: 20px;
}

.generatedReportsContainer .topbarWidth.generatedReportsMarginReset {
    margin: 0px;
}