
.custom_toast{
    color: #fff !important;
    display: flex !important;
    padding: 6px 16px !important;
    flex-grow: 1 !important;
    flex-wrap: wrap !important;
    font-size: 14px !important;
    align-items: center !important;
    font-family: Roboto !important;
    font-weight: 400 !important;
    line-height: 1.43 !important;
    border-radius: 4px !important;
}
.custom_toast_success{
    background: #43a047 !important;
}
.custom_toast_error{
    
}
.Toastify .Toastify__toast-container {
    width: auto;
}
.Toastify__toast{
    cursor: default;
}