.userDataContainer {
    background-color: #FFFFFF;
    width: 100%;
    height: 85vh;
    display: flex;
    height: calc(100vh - 135px);
    margin-top: -20px;
}

.userDataContainer .usageFilterComponent {
    width: 18%;
}
.userDataContainer .usageDataComponent {
  width: 100%;
}

.activityReports-Filter {
  display: flex;
  gap: 10px;
}

.rc--filter_menu_container_reports .MuiPaper-root {
  width: 274px;
  margin-left: 120px;
  margin-top: 10px;
  overflow-y: hidden;
  box-shadow: 0px 0px 13px 0px rgba(203, 203, 203, 0.35);
}

.sitesFilterHeaderMargin {
  display: flex;
  margin: 10px 16px 18px 16px;
}

.activityReportsFilter-close {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 174px;
  cursor: pointer;
}

.activityReportsFilter-close>img {
  margin-left: 173px;
  cursor: pointer;
}

.activityReportsFilter-close>div:nth-child(2) {
  font-size: 15px;
  margin-top: 3px;
  font-family: 'Inter';
  font-style: normal;
}

.outer-selectDaterangeFilter {
  height: fit-content;
  display: flex;
  margin-left: 100px;
  margin-top: -39px;
}

.filter-dateRange-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.download-reports-btn {
  width: 168px;
  height: 32px;
  background: #2860B4;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px 0px 0px;
  margin-right: 10px;
}

.download-reports-btn button {
  width: 168px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: none;
  margin-left: 5px;
}

.download-reports-btn img {
  width: 25px;
  height: 15px;
  margin-right: 5px;
}

.download-reports-container {
  display: flex;
  justify-content: flex-end;
}

/* Filters Popup */

.selectedDeviceDetails .generate-report-title>span:nth-child(1) {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #45464E;
  opacity: 0.75;
}

.selectedDeviceDetails .generate-report-title>span:nth-child(2) {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6E7079;
}

.list-filtered-filter {
  color: #8B8D97;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.deploy-inventory-content .suggested-filters {
  margin-bottom: 35px;
}

.list-filtered-filter .select-site-input {
  width: 315px;
  margin-bottom: 7px;
}

.DPvesselDropdown .dp-dropdown-activity-filters {
  width: 315px;
}

.extand-width .config-textField {
  width: 315px;
}

.activity-filters-popup-dialogue .css-ypiqx9-MuiDialogContent-root {
  padding: 20px 0px 20px 24px;
  overflow: hidden;
}

#dpDropdownMenu-ou {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  margin: 0px;
}

#dpDropdownMenu-ou .MuiPaper-root{
  width: 315px;
  max-height: 200px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: none;
  border: 1px solid #c3d6e7;
}

.deploy-inventory-content .DPI-checkbox {
  margin: 0px 0px 35px 0px;
}

.siteCharts__filters-date-time-picker .date-range-activity {
  width: 170px;
}

/* Reports */

.deploy-inventory-content .check-interval-text:nth-child(1) {
  color: #6E7079;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 20px 0px 75px 0px;
}

.deploy-inventory-content .check-interval-text>span:nth-child(1) {
  margin-bottom: 35px;
}

.check-interval-text .continue-generate {
  color: #45464E;
  opacity: 0.75;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}