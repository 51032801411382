.quota_management--dashboard .MuiTableCell-root {
    border-collapse: collapse;
}

.quota_management--dashboard .MuiTableCell-root.MuiTableCell-head {
    background-color: #F5F5F5;
    color: #8B8D97;
}

.quota_management--dashboard .MuiTableCell-body {
    padding: 0.5em;
}

.quota_management--dashboard--actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 8rem;
}

.quota_management--dashboard--usage {
    width: 17rem;
}

.quota_management--dashboard--dash_size {
margin-left: 1rem;
}

.quota_management--dashboard--sort_arrows {
    margin-left: 0.15rem;
}

.quota_management--dashboard--usage_metric_color--normal {
    color: #1B51A7
}

.quota_management--dashboard--usage_metric_color--remaining {
    color: #C2C7E3
}

.quota_management--dashboard--usage_metric_color--exceeded {
    color: #EE3939
}

.quota_management--dashboard--usage_metric_color--alerting {
    color: #ffcc00
}

.quota-container{
    display: flex;
    justify-content: space-between;
    background: #f9f9f9;
}

.wan-hover-parent .wan-hover {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.row-parent .wan-hover-parent {
    display: flex;
}