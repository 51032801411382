.rc--topbar_contianer {

    background: #FFFFFF;
    border: 1px solid #EBEBEB;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;
    align-items: center;
    padding: 1em 0;
}

.rc--topbar_site_meta, .rc--topbar_site_filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;    
    padding-right: 1em;
}

.rc--topbar_site_name {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 1.3em;
    color: #45464E;
}

.rc--topbar_site_id_link {
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 0.9em;
    letter-spacing: 0em;
    text-align: left;
    padding: 0.35em 0.4em;
    color: #6E7079;
}

.rc--topbar_filter_menu .rc--topbar_device {
    display: flex;
}

#select-device-label {
    font-family: 'Inter';
}

.rc--topbar_site_filters--search_icon.MuiInputAdornment-root {
    cursor: pointer;
}

.MuiTextField-root.input-box-rc-tb {
    width: 300px;
}

.rem-con-address {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    padding-left: 1em;;
    text-align: left;
    align-self: center;
}

.rcTopbar {
    flex-direction: row;
    gap: 16px;
    margin-left: 8px;
}