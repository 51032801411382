.fleetRowDataDetails .tableRowType {
    border: 1px solid white ;
}

.elementDataType {
    font-size: 12px;
    color: #262525 ;
    border: none;
    font-weight: 500;
}

.multipleElementDataType {
    padding: 5px;
    color: #262525;
    margin-left: 5px;
    border: 1px solid white;
    font-weight: 500;
}

.Warehouse-tableRow .warehousetableType {
  padding-left: 20px;
}

.Warehouse-tableRow .warehousetableSn {
  padding-left: 40px;
}

.Warehouse-tableRow .warehousetableAction {
    padding-left: 20px;
}

.serachBarWarehouse {
    width: 330px;
    font-size: 13px !important;
    padding: 2px;
    padding-bottom: 4px;
    padding-top: -2px;
}

.SearchbarTextFieldBox {
    margin-left: 35px;
    margin-bottom: 3px;
    width: 330px;
}

.assignSearchIcon {
    margin-left: 8px;
}

.noVesselInSearchIcon {
    margin-top: 3px;
}

 .AssigndialogueButtons {
  width: 170px;
  padding-left: -20px ;
  margin-top: 10px ;
  z-index: 5;
  position: relative;
  margin-right: 0;
}

 .AssigndialogueButtons .dialog_CancelButton {
    background-color: white;
    color: gray;
    border: 1px solid #c3d6e7;
    font-size: 11px;
    width: 40px;
    padding: 7px 35px 7px 35px;
    border-radius: 5px;
}

.AssigndialogueButtons .dialog_AssignButton {
    color: white;
    border: 1px solid #c3d6e7;
    font-size: 11px;
    width: 40px;
    padding: 7px 35px 7px 35px;
    border-radius: 5px;
}

.AssigndialogueButtons .dialog_CreateAssignButton{
    background-color: #264c86 !important;
    color: white;
    border: 1px solid #c3d6e7;
    font-size: 11px;
    width: 40px;
    padding: 7px 15px 7px 15px;
    border-radius: 5px;
    height: 36px;
}

.assignButton {
    border: 1px solid #c3d6e7;
    padding: 5px 23px 5px 23px;
    border-radius: 5px;
    background-color: white;
    color: #262525;
    font-weight: 500;
    cursor: pointer;
}

.noVesselError {
  width: 330px;
  height: 90px;
  border: 1px solid #c3d6e7;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  margin-top: -69px ;
  background-color: white;
  margin-bottom: 20px;
  margin-left: 28px;
}

 .vesselToCreate {
    height: 39%;
    width: 330px;
    padding: 8px 0px 7px 19px;
    color: #5d647c;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
}

.noVesselCreateMsg {
   height: 62%;
   font-size: 13px;
   color: #5d647c;
   padding-left: 15px;
   padding-top: 10px;
   display: flex;
   gap: 10px;
}

.errorIcon {
    color: #df8340;
    margin-right: -5px;
    cursor: pointer;
}

.warehouseAssignContentData {
   height: 290px;
}

.warehouseAssignContent {
   height: 250px;
}

.warehouseSiteListEmpty {
    border: 1px solid white;
}

.warehouseSiteListData {
    border:1px solid #c3d6e7;
    border-radius: 8px;
    color: #5d647c;
    margin-top: -8px;
    width: 380px;
    position: absolute;
    z-index: 5;
    background: white;
    max-height: 130px;
    overflow-y: auto;
    border-radius: 5px;
    margin-left: 0px;
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
    margin-left: 10px;
}

.assignVesselDetails {
    margin: 15px 0px 0px 10px;
    line-height: 20px;
    font-size: 13px;
    color: #5d647c;
    padding-left: 7px;
    margin-bottom: -10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
}

.selectedProductType {
    color: #5d647c;
    font-size: 13px;
    font-weight: 600;
}

.CreatingVesselRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c3d6e7;
    border-radius: 5px;
    height: 40px;
}

.CancelErrMessage {
    padding: 5px 10px 5px 0px;
    color: #5d647c;
}
.successDialog {
  width: 500px;
  margin: auto;
}

.DialogueContent {
    height: 135px;
    color: #5d647c;
    font-weight: 500;
}

.successdialog_title {
    height: 20px;
    background-color: #ededed;
    border-radius: 8px;
}

.SuccesGreenLogo {
    margin: 5px 0 0 155px;
    position: absolute;
    z-index: 1;
}

.SuccesGreenLogo>div:nth-child(2){
  position: absolute;
  z-index: 2;
  margin-left: 25px;
  margin-top: -58px;
}

.DialogueContent>div{
    align-items: center;
    margin-top: 20px;
    font-size: 13px;
    padding: 25px 0px 10px 30px;
}

.createdVessel {
    margin: 10px 0px 0px 115px;
}

.Close_button {
    width: 100px;
    height: 40px;
    background-color: #375fae;
    color: white;
    border: none;
    border-radius: 8px;
    margin-left: 145px;
    margin-top: 20px;
    cursor: pointer;
}

 .mainAssignActiontitle .AssignActiontitle {
    font-size: 14px;
    margin-left: 0px;
    color: #45464E;
}

#DPmenu-ou {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    margin: 0px;
}

#DPmenu-ou .MuiPaper-root{
    width: 342px;
    max-height: 200px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    border: 1px solid #c3d6e7;
}

#DPmenuSmall-ou {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    margin: 0px;
}

#DPmenuSmall-ou .MuiPaper-root{
    width: 242px;
    max-height: 200px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    border: 1px solid #c3d6e7;
}

.DPchildHierarch {
    font-family: 'Inter';
    font-size: 14px;
    color: #45464E;
}

.inv_assign_dp{
    margin-top:20px;
    margin-left:35px;
    width:330px;
}
.inv_assign_dp .DPVesselName {
    border-radius: 8px;
}
.DPVesselName {
    border: 1px solid #c3d6e7 !important;
    width: 330px;
    height: 40px;
    background-color: #ffffff !important;
    box-shadow: none !important;
}

.DPtextWrap {
    color: #45464E;
    margin-right: 10px ;
    font-family: 'Inter';
    font-size: 14px;
    width: 300px;
    text-align: left;
    width: 400px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DPdropdownArrow {
    color: #5d647c;
    margin-left: 30px ;
}


 .warehouse-inputRow {
  display: flex;
}
  
 .warehouse-inputRow>div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}
  
 .limitContainer {
  margin-left: 10px;
}
  
 .inventorypaperComponent {
 font-size: 12px;
}

.warehouse-fleetRowRank .warehouse-fleetRowDataDetails {
  width: 33%;
}

 .inventoryPagination-list {
  width: 100%;
  align-items: center;
  margin-left: 5px;
}

 .selectedDeviceDetails {
  font-size: 12px;
  line-height: 20px;
  color: #5d647c;
  padding-top: 5px;
  margin-top: 6px;
}

 .selectedProductType, .selectedDeviceId {
  font-weight: 500;
  color: #5d647c;
  font-size: 13px;
}

 .VesselsListForAssign {
  width: 100%;
  overflow-y: auto;
}

 .ParentDivForVesselList {
  width: 340px;
  display: flex;
}

 .actionButtons {
  width: 45%;
  margin-right: 0;
}

 .SitesListNames {
  height: 25px;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 15px;
  font-size: 11px;
  border: 5px;
  cursor: pointer;
}

.fleetDataTable.cellWidthForColmns .align-center {
    justify-content: center;
}
.Warehouse-FleetDashboard .inventory-group-column-hide{
    display: none;
}

.deploy-inventory-content .DPvesselDropdown {
    margin-right: 0px !important;
}

.DPvesselDropdown .DPVesselName {
    width: 342px;
}

.assign-create-actions {
    width: 230px;
    margin: 15px 0px 0px 379px;
}

.assign-create-actions .assign-cancel-btn {
    color: #1B51A7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin-right: 20px
}

.assign-create-actions .assign-create-btn {
    width: 80px;
    height: 32px;
    background: #1B51A7;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    border-radius: 4px;
    color: #fff;
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px
}

/* golden config */

.usageData-tabelRow .config-device-deploy {
    color: #1B51A7;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    cursor: pointer;
}

.config-deploy-inventory-popup {
    width: 45%;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    background-color: #0000;
}

.config-deploy-inventory-popup .create-default-config-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    height: 10px;
    color: #45464E;
    background-color: #f5f5f5 ;
}

.create-default-config-title img {
    width: 25px;
    cursor: pointer;
}

.deploy-inventory-content .config-select-site-deploy {
    display: flex;
    margin-top: 15px ;
    align-items: center;
    margin-bottom: -5px;
}

.select-site-check .input-text-char-check {
    color: red;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-right: 75px;
    margin-top: 5px;
}

.deploy-inventory-content .select-site-check>div {
    display: flex;
    align-items: center;
    margin-top: 15px
}

.deploy-inventory-content  .select-site-check>div>span,
.deploy-inventory-content .config-select-site-deploy>span,
.deploy-inventory-content .config-apply-config-deploy>span,
.deploy-inventory-content .config-configuration-form-deploy>span {
    width: 40%;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.deploy-inventory-content .config-apply-config-deploy {
    display: flex;
    align-items: center;
}

.deploy-inventory-content #removeMarginBottom {
    margin-bottom: 0px;
}

.deploy-inventory-content .config-configuration-form-deploy {
    display: flex;
    margin-top: 15px;
    align-items: center;
}

.deploy-inventory-content .config-apply-config-deploy>div:nth-child(2) {
    margin-left: -8px;
}

.configuration-form-build span {
    color: #9A9A9A;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.deploy-inventory-content .config-create-actions {
    width: 100%;
    bottom: 0;
    right: 0;
    padding-right: 12px;
    margin-top: 20px;
}

.deploy-inventory-content .config-create-actions .config-cancel-btn {
    color: #1B51A7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: none;
    margin-right: 20px;
    cursor: pointer;
    float: right;
}

.deploy-inventory-content  .config-create-actions .config-create-btn {
    width: 127px;
    height: 32px;
    background: #1B51A7;
    border-radius: 4px;
    color: #fff;
    text-transform: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;
    border: 1px solid #676984;
    cursor: pointer;
    float: right;
}

.config-create-actions .config-create-btn:hover {
    background: #1B51A7;
}

.name-input-default-config .config-radio-button {
    margin: 10px 25px 0px -4px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
}

.successDialog .inventory-success-popup {
    width: 436px;
}

.warehouse-apply-config-props {
    max-height: 150px;
    font-size: 12px;
}

.config-textField {
    width: 342px;
    font-size: 12px;
}

.config-apply-config-deploy>span {
    padding-top: 10px;
}
.deploy-inventory-content .selected-config-details>div{
    width: 59%;
}
.deploy-inventory-content .selected-config-details>div>div {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #9A9A9A;
    margin-bottom: 5px;
}
.deploy-inventory-content .selected-config-details .value {
    color: #676984;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.new-summary-usage-tr > .text-center {
    text-align: center;
}

.MuiTextField-root.input-box-warehouse {
    margin: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 0px;
}
.inventoryWarehouse.register-icon{
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding-right: 5px;
    padding-bottom: 2px;
}

/* Edit Actions Popup */

.warehouse-edit-actions-content .device-border-right {
    margin-top: 5px;
}

.assignInventory-container .MuiDialogContent-root {
    padding: 0px;
}

.assignInventory-container .DPVesselName {
    width: 250px;
}

.config-configuration-form-deploy .warehouseChangeOrdDropdwn .DPvesselDropdown .MuiButtonBase-root {
    width: 242px;
}

.warehouseOrgDropdown .DPVesselName {
    width: 242px;
}

.assignInventory-container .deploy-inventory-content {
    overflow-x: hidden;
}

.assignInventory-container .deploy-inventory-content .config-create-actions {
    margin-left: -15px;
}

.inventory-edit-actions-container .warehouse-edit-actions-content .device-data-body {
    max-height: fit-content;
    min-height: 375px;
}

.assignInventory-container .register-edit-actions-confirm-btns {
    width: 100%;
}

.register-edit-actions-confirm-btns .confirm-dialogue-new-divider {
    margin-bottom: 20px;
    margin-right: 10px;
}

.register-edit-actions-confirm-btns .confirm-dialogue-new-btns {
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-bottom: 13px;
}

.assignInventory-container .MuiDialogContent-root {
    min-height: 296px;
}

.assignInventory-container .register-edit-actions-confirm-btns .confirm-dialogue-proceed-notAllow {
    color: rgb(38, 76, 134);
}

.register-inventory-content .edit-reg-radio-btn .config-radio-button, .selected-config-details .new-selected-config-details .margin-top-10px-reg, .register-inventory-content .select-site-check .margintop-10px {
    margin-top: 10px;
}

.greySettings {
    color: grey;
}