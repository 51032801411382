.config-container .reports-login-history {
    width: 100%;
    padding: 24px 10px 0px 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    margin: auto;
    margin-top: 20px;
}

.TopUsersConatiner .loginHistory-tableContainer {
    margin-top: -8px;
    margin-bottom: 20px;
    height: auto;
}

.reports-login-history>div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.config-search-bar-text .login-history-search-bar {
    margin-top: -22px;
    height: 32px;
}

.login-left-align .login-history-dp-name {
    color: #8B8D97;
    letter-spacing: 1px;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
}

.loginHistory-tableRow td {
    text-align: left;
    padding: 5px;
    padding-left: 15px;
}

.login-tableRow .login-tableCell {
    background: #f5f5f5;
}

.loginHistory-tableRow .login-left-align {
    font-size: 12px;
    color: #45464E;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    background: #FFFFFF;
}

.loginHistory-tableRow .selectedClick {
    cursor: pointer;
    color: #4c3aff;
}

.config-login-container .login-history {
    width: 100%;
    padding: 0px 10px 0px 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(203, 203, 203, 0.35);
    margin: auto;
    margin-top: 20px;
}

.MuiTextField-root.input-box-login-hist {
    margin: 8px; 
    width: 100%; 
    max-width: 300px; 
    margin-left: 10px;
}