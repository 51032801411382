.alert-config-scroll {
    height: calc(100vh - 205px);
    overflow-y: auto;
}
.site-level-config-container {
    margin-top: 2%;
}
.site-level-alerts-accordTree {
    padding: 0.2em 2em 0.2em 2em;
    .accordionTableHeader {
        border: 1px solid #ddd;
        display: flex;
        border-radius: 4px 4px 0px 0px;
    }
    .site-alert-config-accordion {
        width: 100%;
    }
    .site-alert-config-accordion-summary {
        position: sticky;
        top: 0;
        background-color: #f5f5f5;
        z-index: 1;
    }
    .site-alert-config-accordion-summary .MuiAccordionSummary-root,
    .site-alert-config-accordion-summary .MuiAccordionSummary-root.Mui-expanded {
        min-height: 50px;
        height: 50px;
        padding-left: 5px;
    }
    .site-alert-config-accordion-summary .MuiAccordionSummary-expandIconWrapper {
        /* position: absolute; */
        /* left: 10px; */
    }
    .site-alert-config-accordion-summary .accordion-title {
        /* margin-left: 20px; */
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.15px;
        vertical-align: middle;
        color: #000000;
    }
    .site-alert-config-accordion-details {
        padding: 0 0 16px 0;
    }
    .site-alert-config-accordion-details-container {
        margin: 0;
        width: 100%;
        height: 100%;
    }
    .site-alert-config-accordion-details-container-child {
        height: 100%;
        padding: 10px 0 0 0;
    }
    .site-alert-config-accordion-details-container-child-child {
        height: 100%;
        align-items: stretch;
        padding:16px 16px 0 16px;
    }
    .site-alert-config-accordion-details .link-availability-parent {
        margin: 0px;
        padding: 0px;
    }
    .site-alert-config-accordion-details .internet-status-login-parent {
        padding-top: 0px;
        margin-bottom: 15px;
    }
    .site-alert-config-accordion-details :has(> .internet-status-alert-config) {
        padding-bottom: 4px;
    }
    .site-alert-config-accordion-details .accordian {
        padding: 0px;
        margin: 0;
        background-color: rgb(255, 255, 255);
        color: rgba(0, 0, 0, 0.87);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 4px;
    }
    .link-availability.trigger-alert-clear-alert-text .wan-type,
    .dpAlertTypeHeader .wan-type{
        padding-left: 5px;
    }

    .dpAlertTypeHeader.trigger-alert-probe-loss-and-duration-text,
    .dpAlertTypeHeader.trigger-alert-probe-pass-and-duration-text,
    .internet-status-alert-config .trigger-alert-clear-alert-duration-text {
        padding-bottom: 5px;
    }

    .dpAlertTypeInputContainer.trigger-alert-probe-loss-and-duration,
    .dpAlertTypeInputContainer.trigger-alert-probe-loss-and-duration .probe-loss-value,
    .dpAlertTypeInputContainer.trigger-alert-probe-loss-and-duration .probe-loss-duration-value,
    .dpAlertTypeHeader.trigger-alert-probe-loss-and-duration-text,
    .dpAlertTypeInputContainer.trigger-alert-probe-pass-and-duration,
    .dpAlertTypeInputContainer.trigger-alert-probe-pass-and-duration .probe-pass-value,
    .dpAlertTypeInputContainer.trigger-alert-probe-pass-and-duration .probe-pass-duration-value,
    .dpAlertTypeHeader.trigger-alert-probe-pass-and-duration-text {
        display: flex;
        align-items: center;
    }
    .dpAlertTypeHeader.trigger-alert-probe-loss-and-duration-text .probe-loss-text,
    .dpAlertTypeHeader.trigger-alert-probe-pass-and-duration-text .probe-pass-text{
        width: 80px;
    }
    .dpAlertTypeInputContainer.trigger-alert-probe-loss-and-duration .probe-loss-duration-value,
    .dpAlertTypeInputContainer.trigger-alert-probe-pass-and-duration .probe-pass-duration-value {
        margin-left: 25px;
    }
    .dpAlertTypeInputContainer.trigger-alert-probe-loss-and-duration .probe-loss-value .unit-percentage,
    .dpAlertTypeInputContainer.trigger-alert-probe-loss-and-duration .probe-loss-duration-value .unit-minute,
    .dpAlertTypeInputContainer.trigger-alert-probe-pass-and-duration .probe-pass-value .unit-percentage,
    .dpAlertTypeInputContainer.trigger-alert-probe-pass-and-duration .probe-pass-duration-value .unit-minute {
        margin-left: 5px;
        font-family: Arial;
        font-weight: 400;
        font-size: 14.4px;
        line-height: 100%;
        letter-spacing: 0%;
        vertical-align: middle;
        color: #45464E;
    }
    .create_alert--form_group--input_text::-webkit-outer-spin-button,
    .create_alert--form_group--input_text::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      display: none;
    }
    .create_alert--form_group--input_text {
      appearance: textfield;
    }
    .MuiGrid-item.dpAlertTypeHeader {
        padding-left: 16px;
    }
    .link-availability.trigger-alert-clear-alert-text {
        height: 20px;
        padding-bottom: 10px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0;
        vertical-align: middle;
        color: #000000;
    }
    .dpAlertLAContainer .internet-outage {
        padding-left: 5px;
    }
    .dpAlertLAContainer .internet-outage,
    .dpAlertTypeInputContainer .internet-status-trigger-alert-duration-value,
    .dpAlertTypeInputContainer .internet-status-clear-duration-value {
        font-family: Arial;
        font-weight: 400;
        font-size: 14.4px;
        line-height: 100%;
        letter-spacing: 0%;
        vertical-align: middle;
        color: #45464E;
    }
    .dpAlertTypeInputContainer .internet-status-trigger-alert-duration-value .unit-minute,
    .dpAlertTypeInputContainer .internet-status-clear-duration-value .unit-minute {
        margin-left: 5px;
    }
    .link-availability-delete-wan-select-want-type{
        display: flex;
    }
    .accordionTableHeader .eachAccordionHeadItem {
        border-right: 1px solid #ddd;
        padding: 10px;
        text-align: center;
        box-sizing: border-box;
    }

    .eachAccordionHeadItem .eachAccordColHead {
        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
    }

    .eventsHeading {
        padding: 2px;
    }

    .alertNameText input {
        padding: 5px;
        font-size: 12px;
    }

    .alertDescriptionText {
        width: 94%;
    }

    .alertDescriptionText input {
        padding: 5px;
        font-size: 12px;
    }

    /* .gateSection {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

    .radioButton {
        margin-left: 50px;
    }

    .GreenColor {
        color: #25C381;
    }

    .RedColor {
        color: red
    }

    .YellowColor {
        color: rgb(233, 233, 3)
    }

    .OrangeColor {
        color: #CF833D;
    }

    .GreenColor,
    .RedColor,
    .YellowColor {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter';

    }

    .alertStatusAccordhead {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
    }

    .alertStatusWithIcon {
        display: flex;
        align-items: center;
        margin-left: 2px;
    }

    .alertStatusAccordhead .alertName {
        margin-left: 20px;
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.15px;
        vertical-align: middle;
        color: #000000;
        display: flex;
        align-items: center;
    }
    .alertStatusAccordhead .alertName .alert-name-info-icon {
        margin-left: 5px;
        cursor: pointer;
    }
    .alertStatusAccordhead .alertDescription {
        color: grey;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Inter';
    }

    .alertStatusAccordhead .gateName,
    .eventsHeading .rulesHead {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
    }

    .alertStatusAccordhead .gateName {
        text-transform: uppercase;
        color: #2F4B82;
        height: 15px;
    }

    /* .firstInnerTableCol {
    height: 40px;
    display: flex;
    align-items: center;
    border-right: 1px solid #ddd;
    min-width: 165px;
  } */

    .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        left: 0;
    }

    .childAccordion .accordionSummaryAlert {
        padding: 0px;
    }

    /* .accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: stretch;
  } */
    .accordionSummaryAlert .eachChildAccordCol {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ddd;
        box-sizing: border-box;
    }

    .accordionSummaryAlert.childAccord .MuiAccordionSummary-content {
        align-items: stretch;
    }

    .accordian .alertsAccordions {
       padding: 0px;
    }

    .accordian .alertsAccordions .accordionSummaryAlert.Mui-expanded {
        border-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .statusName {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        font-family: 'Inter'
    }

    .cursor-auto {
        cursor: auto;
    }


    .display-flex {
        display: flex;
    }

    .moreLabelsDialog .MuiDialog-scrollPaper {
        justify-content: end;
    }

    .moreLabelsDialog .moreLabelsDialogGrid {
        padding: 10px 5px 15px 5px;
        grid-gap: 15px;
        max-height: 150px;
        overflow-y: auto;
    }

    .moreLabelsDialogCloseIconGrid {
        height: 15px;
        display: flex;
        justify-content: end;
    }

    .moreLabelsDialogCloseIconGrid img {
        height: 25px;
        width: 25px;
        cursor: pointer;
    }

    .moreLabelsDialog .MuiPaper-root {
        margin-top: -11%;
    }


    .create_alert--form_group {
        display: flex;
        gap: 0.65rem;
        margin: 1rem;
        flex-wrap: wrap;
    }

    .create_alert--form_group--input {
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        padding: 0.65rem 0.75rem;
    }

    .create_alert--form_group--input_text,.linkAvailabilityWanTypeSelect .MuiSelect-select {
        font-family: Arial;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0;
        vertical-align: middle;
        color: #45464E;
    }

    .dpAlertTypeHeader {
        font-family: Arial;
        font-weight: 400;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0%;
        vertical-align: middle;
        color: #45464E;
    }

    .invalid-message-parent {
        padding: 0;
        margin-top: 5px;
    }
    .invalid-message-parent .invalid-message-1 {
        padding: 0 0 0 12px;
    }
    .invalid-message-parent .invalid-message-2 {
        padding: 0 0 0 5px;
    }
    .dpLAInvalidThreshold.link-availability{
        padding-left: 5px;
    }
    .dpLAInvalidThreshold {
        font-size: 10px;
        color: red;
        margin: 0;
    }

    .dpAlertTypeInputSubContainer {
        display: flex; 
    }

    .dpAlertLAContainer {
        margin-top: 10px;
    }
    .dpAlertLAContainer:not(:first-child) {
        margin-top: 0px;
        padding-left: 16px;
        display: flex;
        align-items: center;
    }
    .dpAlertLAContainer div{
        padding-left:0px;
        padding-top: 0px;
    }

    .linkAvailabilityWanTypeSelect, .dpAlertTypeLAInputBox.wan-type{
        width: 70px;
        height: 30px;
        border: 0px;
        background-color: #ffffff;
    }

    .linkAvailabilityWanTypeSelect .MuiSelect-select {
        display: flex;
        align-items: center;
        padding: 0 0 0 5px;
    }
    .dpAlertTypeLAInputBox,  .dpAlertTypeInputBox {
        width: 25px;
        padding: 5px;
    }

    .dpAlertTypeHeaderIcon {
        color: #2F4B82;
    }
    .dpAlertTypeHeaderIcon.notification-icon {
        margin-top: 5px;
    }
    .dpAlertTypeFormIcon {
        cursor: pointer;
        color: grey;
    }

}

.site-level-alerts-accordTree li {
    list-style-type: none;
    position: relative;
}

.site-level-alerts-accordTree li li {
    list-style-type: none;
    position: relative;
}

.site-level-alerts-accordTree li li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #ddd;
    width: 20px;
    height: 15px;
}

.site-level-alerts-accordTree li li:before {
    border-bottom: 1px solid #ddd;
}

.site-level-alerts-accordTree li li:after {
    position: absolute;
    content: "";
    top: 5px;
    left: -20px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    width: 20px;
    height: 100%;
}

.site-level-alerts-accordTree li li:last-child:after {
    display: none;
}

.accordionContainer {
    width: 100%;
}



.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
    transform: rotate(270deg);
    height: 20px;
}

.accordian .accordionSummaryAlert .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiSvgIcon-root {
    transform: rotate(180deg);
    height: 20px;
}

.accordionSummaryAlert .MuiAccordionSummary-content {
    align-items: center;
    margin: 0px;
}

.accordian .accordionSummaryAlert {
    /* border-bottom: 1px solid #ddd; */
    min-height: auto;
}

/* .gateSection,.accordionHead {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  } */



.site-level-alerts-accordTree ul {
    margin: 0px;
}


.site-level-alert-logs {
    margin: 0.2em 2em 0.2em 2em;

    .audit-data-container .alerts-config-change-log-text{
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.15px;
        vertical-align: middle;
        color: #000000;
        margin-left: 5px;
    }
    .audit-data-container .audit-logs-font,
    .audit-filters-container .audit-logs-font {
        font-size: 12px;
        font-family: 'Inter';
        color: #45464E;
    }

    .audit-data-container .audit-logs-font-header {
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        color: #45464E;
    }

    .audit-filters-container .audit-filter {
        font-size: 14px;
        font-family: 'Inter';
        font-weight: 500;
        color: #45464E;
    }

    .audit-container {
        padding: 10px 13px;
        margin-top: 75px;
        height: calc(100vh - 150px);
        overflow-y: scroll;
    }

    .audit-layout {
        background-color: #f9f9f9;
        /* position: absolute;
    left: 85px;
    top: 50px;
    right: 0;
    bottom: 0; */
        padding: 40px 5px 5px 5px;
    }

    .audit-filter-list {
        display: flex;
        align-items: center;
    }

    .audit-filter {
        font-size: 14px;
    }

    .audit-data-container .audit-filter-list .audit-filter {
        font-size: 12px;
        font-weight: 600;
    }

    .audit-filter-list .filter-clear-all {
        margin-left: auto;
        color: #2F4B82;
        text-transform: none;
        text-decoration: none;
    }

    .expand-accordin-root.MuiAccordion-root {
        width: 100%;
    }

    .expand-summary .expand-summary-content,
    .expand-summary .expand-summary-content.Mui-expanded {
        margin: 5px 0;
        color: #45464E;
    }

    .expand-summary.Mui-expanded[aria-expanded="true"] {
        min-height: 48px;
    }

    .filter-accordian {
        font-size: 12px;
        font-weight: 600;
        padding-left: 17px;
    }

    .no-padding {
        padding: 0px;
    }

    .rc--form-control .no-padding {
        padding: 0px;
    }

    .padding-control .no-padding-top-btm {
        padding-top: 0;
        padding-bottom: 0;
    }

    .filter-accordian-label {
        font-size: 10px;
        font-weight: 500;
    }

    .audit-log-item .filter-meta {
        font-size: 12px;
        font-weight: 600;
        font-family: 'Inter';
    }

    .audit-filter-inputs .MuiInputBase-root {
        height: 34px;
        font-size: 12px;
    }

    .audit-filter-inputs .MuiInputLabel-root {
        font-size: 12px;
    }

    .audit-data-head .audit-data-header {

        font-family: 'Roboto Mono';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */
        letter-spacing: 1px;
        font-variant: small-caps;
        /* Tertiary black */
        color: #8B8D97;
    }

    .audit-filters-container {
        /* display: flex;
    gap: 10px;
    flex-direction: column;
    height: calc(100vh - 80px); */
    }

    .filter_menu_container_auditlogs {
        margin: 110px 0px 0px 210px;
    }

    /* .filter_menu_container_auditlogs>.MuiPopover-paper {
        overflow-y: hidden;
    } */

    .audit-btn-parent .audit-filter-apply {
        text-transform: none;
        background-color: #2F4B82;
        border-radius: 4px;
        margin: auto 15px 15px 15px;
        font-size: 12px;
        font-family: 'Inter';
        font-weight: 600;
        width: 90%;
        margin: 0 10px;
    }

    /* .audit-filter-items {
        overflow-y: hidden;
    } */

    .audit-table-data.MuiTable-root {
        overflow-y: auto;
    }

    .audit-table-data.MuiTable-root .MuiTableHead-root th {
        background-color: #f5f5f5;
    }

    /* .audit-table-data.MuiTable-root .MuiTableHead-root th:not(:first-child):not(:last-child),
.audit-table-data.MuiTable-root .MuiTableBody-root td:not(:first-child):not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
} */

    .audit-data-container {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .audit-data-pagination {
        margin-top: auto;
    }

    .audit-log-item {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-direction: column;
    }

    .syntax-items {
        background-color: #F4F5FA;
        display: flex;
        width: 100%;
        min-height: 50px;
        position: relative;
        flex-direction: column;
        counter-reset: syntax-line-number;
        border-radius: 4px;
    }

    .syntax-items .syntax-row {
        counter-increment: syntax-line-number;
        display: grid;
        column-gap: 5px;
        grid-template-columns: minmax(15px, 25px) 1fr;
    }

    .syntax-items .syntax-row>pre {
        margin: 0;
    }

    .syntax-items .audit-syntax-copy {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .audit-syntax-copy .copy-audit-metas {
        background-color: #2F4B82;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: none;
    }

    .syntax-items .syntax-row>* {
        padding: 1px;
        color: #6E7079;
        font-size: 12px;
        font-family: 'Inter';
    }

    .syntax-items .syntax-row:first-child>* {
        padding-top: 8px;
    }

    .syntax-items .syntax-row:last-child>* {
        padding-bottom: 8px;
    }

    .syntax-items .syntax-line-item {
        background-color: #E3E6F3;
        color: #2F4B82;
        user-select: none;
        text-align: center;
        font-weight: 500;
        font-family: 'Inter';
        font-size: 12px;
    }

    .syntax-items .syntax-line-item::before {
        content: counter(syntax-line-number);
    }

    .copy-audit-metas .MuiButton-startIcon .copy-icon {
        font-size: 12px;
    }

    .accordian-expand .accordian-arrow {
        font-size: 16px;
        color: #6E7079;
    }

    .pagination-position {
        height: 40px;
    }

    .audit-container .audit-container-data {
        width: 100%;
        /* overflow: hidden; */
        height: auto;
        overflow-y: auto;
        border: 1px solid #EBEBEB;
        border-radius: 0px;
        box-shadow: none;
    }

    .audit-table-root .audit-logs-no-data {
        font-size: 12px;
        font-family: 'Inter';
    }

    .audit-loader {
        position: absolute;
        top: 14px;
        right: 28px;
    }

    .audit-filter-items .audit-pop-up-height {
        overflow-y: auto;
        max-height: 50vh;
        margin-bottom: 10px;
    }
}