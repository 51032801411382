.Form .outerDiv{
    background-color: #ffffff;
    margin-top: 2rem;
    padding: 2rem;
    /* width: 0px !important; */
    margin-top: 0px;
    border: 1px solid lightgray;
}

.Form .innerDiv{
    /* border: 2px solid #E5E5E5; */
    /* border: 1px solid #C2C2C2; */
    padding: 1rem;
    background-color: #EEF0F7;
    border: 1px solid #E5E5E5;
    /* margin-top: 2rem; */
}

.Form .horizontal-line{
    /* margin: 0.8rem -1rem 1rem -1rem; */
    border-bottom: 1px solid #E5E5E5;
}

.Form-close-icon {
    text-align: end;
}

.Form-title {
    font-size: 22px !important;
    line-height: 33px;
    letter-spacing: 0.00251em;
    color: #384089 !important;
    font-style: normal;
    font-weight: 900;
}

.Form-content {
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    color: #384089;
    margin-top: 13px;
    margin-left: 26px;
    margin-right: 26px;
}

.button {
    /* width: 214px;
    margin-bottom: 40px; */
    text-align: center;
}

.MuiDialog-paperWidthSm {
    max-width: 800px !important;
    height: 500px;
}

