.ComponentHeader {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #273050 !important;
  /* margin-top: 16px !important; */
  opacity: 0.75;
}

.componentContent {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #676984 !important;
  /* margin-top: 16px !important; */
  margin-left: 5px !important;
  text-transform: capitalize;
}

.marginComponent{
    margin-left: 30px !important;
}

.alert_history_header {
  background: white;
  width: 90px;
  font-weight: bold !important;
}

.alert_history_value {
  font-weight: normal !important;
}
