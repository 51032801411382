.tab-container:has(.rc--container) {
    margin: 0 !important;
    margin-top: 10px !important;
}

.padding-l-r-15 {
    padding: 0px 20px;
}

.rc--container {
    background: #F5F5F5;
}

.rc--container {
    display: flex;
    flex-wrap: wrap ;
}

@media only screen and (max-width: 760px) {
    .rc--container {
        max-height: calc(92vh - 270px);
    }
}

.rc--content_containers {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    display: flex;
    justify-content: center;
}

.rc--links_container {
    height: 100%;
    flex-grow: 8.5;
    flex-shrink: 17;
    flex-basis: 0em;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.rc--filter_container {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;

    flex-grow: 2.5;
    flex-shrink: 1;
    flex-basis: 0em;
    align-items: stretch;

    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
}

.rc--filter_section {
    border-bottom: 1px solid #EBEBEB;
    padding: 16px;
}

.rc--filter_header {
    display: flex;
    align-items: center;
}

.rc--content_headers {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #45464E;
}

.rc--content_sub_headers {
    font-weight: 500;
    font-size: 14px;
    line-height: 0.95em;
    color: #45464E;
}

.rc--content_sub_headers_filter {
    font-weight: 600;
    font-size: 12px;
    line-height: 0.95em;
    color: #45464E;
    font-family: 'Inter';
}

.rc--links {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    color: #0E0548;
    cursor: pointer;
}

.rc--filter_search_container {
    padding-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5%
}

/* .rc--filter_search_container>div {
    margin: 2% 0%;
} */

.rc--filter_search {
    display: flex;
    position: relative;
}

.rc--filter_search_input {
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 1em 0.5em;

    width: 100%;

    font-weight: 400;
    font-size: 0.9em;
    line-height: 0.9em;
}

.rc--filter_search_input_icon {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
}

.rc--filter_search_input_icon:hover{
    cursor: pointer;
}

.rc--filter_search_chip_container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em 0em;
    gap: 0.5em;

}

.rc--filter_search_chip.MuiChip-root {
    background: #F4F5FA;
    margin: 0.2rem;
}

.rc--filter_search_chip .MuiSvgIcon-root.MuiChip-deleteIcon {
    color: #B3B9D8;
}

.rc--filter_search_chip .MuiChip-label {
    font-weight: 500;
    font-size: 1.1em;
    line-height: 0.9em;
    color: #2F4B82;
}

.rc--form-control input[type="checkbox"] {
    accent-color: #2F4B82;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    margin-right: 1em;
}

.rc--filter_result_count {
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.25em;
    color: #6E7079;
    margin-bottom: 0.5em;
}

.rc--scanned_devices_tab_container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #EBEBEB;
    font-family: 'Inter';
}

.rc--generated_links_container {
    margin: 10px;
}

.rc--links_container_header {
    padding: 1em;
}

.rc--sd_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    margin: 0.5em 0em;
    word-break: break-all;
}

.rc--sd_tab:hover, .rc--generate_link_button:hover {
    background: #F4F5FA;
    cursor: pointer;
}

.rc--sd_tab_selected {
    background: #F4F5FA;
    color: #2F4B82;
}

.rc--generated_links_topbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #EBEBEB;
}

.rc--generated_links_topbar--meta {
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    justify-content: center;
}

.rc--generated_links_topbar--meta .rc--content_sub_headers,
.rc--generated_links_topbar--meta .rc--content_headers {
    margin: 0.5em 0em;
    flex-grow: 1;
    flex-shrink: 1;
}

.rc--generated_links_topbar--meta .rc--content_sub_headers {
    font-weight: unset;
}

.rc--content_sub_headers_type {
    font-weight: 400;
    font-size: 12px;
    line-height: 0.95em;
    color: #45464E;
    font-family: 'Inter';
}

.rc--content_sub_headers_filters {
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #45464E;
    font-family: 'Inter';
}

.rm-conn-scroll .remoteConnectionsFilterGenerateLinkBotton {
    padding: 7.825px;
}